// import thunk from "redux-thunk";
// import logger from "redux-logger";
// import { compose, applyMiddleware, createStore } from "redux";
// import rootReducer from "../Redux/reducer";
// const composeEnhancers =
//   typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//         // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//       })
//     : compose;
// /**
//  * composeEnhancers redux devtools kullanırken
//  */
// const enhancer = composeEnhancers(
//   applyMiddleware(thunk),
//   applyMiddleware(logger)
// );
// const store = createStore(rootReducer, enhancer);
// export default store

import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "../Redux/reducer"

const middleware = [thunk];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;