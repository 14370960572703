import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import Select from "@material-ui/core/Select";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import InputLabel from "@material-ui/core/InputLabel";
// import CreatePreview from './CreatePreview';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MenuAppBar from "../../Components/header";
import { Box, Grid, Typography, styled, Tooltip, Fab } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { useDispatch, useSelector } from "react-redux";
import Menuaction from "../../Redux/actions/Menuaction";
import Notification from "../Notifications/Notification";
import API_BASE_URL from "../../Config/config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import config from "../../Config/config";
import { Add as AddIcon } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";

import {
  Container,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
} from "@material-ui/core";

// import Font from '@ckeditor/ckeditor5-font/src/font';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  root: {
    padding: "0px 16px",
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);
const useStylestable = makeStyles({
  table: {
    minWidth: 100,
    // width:10
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    height: 10,
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  Createcontainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "180px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "120px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },

  texttablecell: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    width: "140px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // '&:hover': {
    //     overflow: 'visible'
    // }
  },
  table: {
    // minWidth: 150,
    width: "60%",
    height: "10%",
    border: "1px black",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 220,
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  rootc: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  textField: {
    "& p": {
      color: "red",
    },
  },

  //pop up weindow

  container: {
    border: "none",
    borderRadius: 5,
    width: 600,
    height: 550,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",

    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      height: "100vh",
    },
  },
  form: {
    padding: theme.spacing(2),
  },
  item: {
    marginTop: 40,
    marginLeft: 80,
    width: 400,
    justifyContent: "center",
    justifyItems: "center",
    position: "relative",
    marginBottom: theme.spacing(6),
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateFeature(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const classes = useStyles();
  const classestable = useStylestable();
  const [edithandle, setEdithandle] = useState([]);
  const {
    details,
    createFeature,
    preview,
    editpreview,
    editPreviewdetails,
    headerValue,
    mig_id,
    ITEMlIST,
    lable,
    admin,
    project_version,
    object_path,
  } = useSelector((state) => state.dashboardReducer);
  // console.log(object_path, 'object_path')
  const [updatetable, setUpdatetable] = useState(false);

  let obj_type = props.location?.data?.obj;
  let obj_type_id = props.location?.data?.obj_id;

  // console.log(obj_type,'type')
  const history = useHistory();
  if (obj_type_id === undefined || null) {
    history.push("/dashboard");
  }
  // let treepath = props.location?.data?.prepath;

  // const handleTreeItemClick = (lable, treepath) => {
  //   let treearray = [];
  //   if (treepath) {
  //     let mod_array = treepath.split("/");
  //     for (var i = 0; i < mod_array.length; i++) {
  //       if (mod_array[i] === lable) {
  //         treearray.push(mod_array[i]);
  //         break;
  //       } else {
  //         treearray.push(mod_array[i]);
  //       }
  //     }
  //     let array_join = treearray.join("/");
  //     dispatch(Menuaction.treepath_edit(array_join));
  //   }
  // };
  // useEffect(() => {
  //   handleTreeItemClick(obj_type, treepath);
  // },[]);

  // console.log(treepath,obj_type, 'prepath')
  const [prerunval, setPrerunval] = useState([]);

  const [formValues, setformvalues] = useState({
    // Migration_Name: props.location?.state?.data?.type,
    // Object_Type: props.location?.state?.data?.Label,
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [tableinfo, setTableinfo] = useState([]);
  const [istdata, setIstdata] = useState(false);
  const [loading_tdata, setLoading_tdata] = useState(false);

  const [modalupdate, setModalupdate] = useState(false);

  useEffect(() => {
    if (obj_type_id) {
      let body = {
        Object_Id: obj_type_id,
      };
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      axios
        .post(`${config.API_BASE_URL()}/api/predecessor_list/`, body, conf)
        .then(
          (res) => {
            //   console.log(res);
            // setPrerunval([{ Feature_Name: "" }].concat(res.data));
            setPrerunval([{ Feature_Name: "No Predecessor" }].concat(res.data));

            //   setIsdata(true);
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [obj_type, headerValue?.Migration_Name, lable, project_version]);

  useEffect(() => {
    if (obj_type_id) {
      let body = {
        Object_Id: obj_type_id,
      };
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      setLoading_tdata(false);
      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });
      axios
        .post(`${config.API_BASE_URL()}/api/table_features_list/`, form, conf)
        .then(
          (res) => {
            setTableinfo(res.data);
            setIstdata(true);
            setLoading_tdata(true);
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
            setLoading_tdata(false);
          }
        );
    }
  }, [
    modalupdate,
    headerValue?.Migration_Name,
    obj_type_id,
    project_version,
    updatetable,
  ]);

  const dispatach = useDispatch();

  // console.log(props.location?.state?.data);

  const create_default_module = (obj_id, migname, fname, fvid, fid) => {
    let formData = {
      Migration_Name: migname,
      Object_Id: obj_id,
      Project_Version_Id: project_version,
      Feature_Version_Id: fvid,
      Feature_Name: fname,
      Feature_Id: fid,
    };
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios
      .post(
        `${config.API_BASE_URL()}/api/Create_default_python_module/`,
        form,
        conf
      )
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "Created Feature With Default Module",
            type: "success",
          });
          // dispatach(Menuaction.reloadAction(true));
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = {
      ...formValues,
      Migration_Name: headerValue?.Migration_Name,
      Object_Type: obj_type,
      Source_FeatureDescription: "",
      Source_Code: "",
      Conversion_Code: "",
      Target_FeatureDescription: "",
      Object_Id: obj_type_id,
      Target_Expected_Output: "",
      Target_ActualCode: "",
      Project_Version_Id: project_version,
      Feature_Version_Id: 0,
      Feature_Created_by: sessionStorage.getItem("uemail"),
      Migration_Id: mig_id,
    };
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    // console.log(formValues.Sequence);
    if (formValues.Sequence === undefined) {
      setNotify({
        isOpen: true,
        message: "Please Select Predessor Before Creating Feature",
        type: "error",
      });
    } else {
      axios
        .post(`${config.API_BASE_URL()}/api/featurecreate/`, form, conf)
        .then(
          (res) => {
            if (
              res.data ===
              "Feature already present with this version.Kindly request access for it"
            ) {
              setNotify({
                isOpen: true,
                message: res.data,
                type: "error",
              });
            } else {
              // setNotify({
              //   isOpen: true,
              //   message: "Feature Created Successfully",
              //   type: "success",
              // });
              create_default_module(
                formData.Object_Id,
                formData.Migration_Name,
                formData.Feature_Name,
                1,
                res.data.Feature_Id
              );
              setUpdatetable(true);
              // dispatach(Menuaction.EditPreviewFeature({ data: res.data }));
              // dispatach(Menuaction.reloadAction(false));
              dispatach(Menuaction.reloadAction(true));
              // let UpdateItem = {
              //   Label: ITEMlIST[0]?.Label,
              //   subMenu: ITEMlIST[0]?.subMenu.concat({ Feature_Id: res.data?.Feature_Id, Feature_Name: res.data?.Feature_Name })
              // }
              // dispatach(Menuaction.UpdateMenutlist(UpdateItem))

              // history.push("/EditFeature");
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
    setUpdatetable(false);
  };

  //Pop up window
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleChange = (e) => {
    // console.log(e.target.name)
    setformvalues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleChange_pred = (name, fn) => {
    // console.log(name, fn);
    setformvalues({
      ...formValues,
      [name]: fn,
    });
  };

  const handleEditchangetext = (e) => {
    if (e.target.name === "Sequence") {
      setEdithandle({
        ...edithandle,
        Sequence: e.target.value,
      });
    } else if (e.target.name === "Keywords") {
      if (e.target.value === null) {
        setEdithandle({
          ...edithandle,
          Keywords: 0,
        });
      } else {
        setEdithandle({
          ...edithandle,
          Keywords: e.target.value,
        });
      }
    } else if (e.target.name === "Estimations") {
      if (e.target.value === null) {
        setEdithandle({
          ...edithandle,
          Estimations: 0,
        });
      } else {
        setEdithandle({
          ...edithandle,
          Estimations: e.target.value,
        });
      }
    }
    setformvalues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handlechange_Ap = (e) => {
    setEdithandle({
      ...edithandle,
      Automation_Percentage: e.target.value,
    });
    setformvalues({
      ...formValues,
      Automation_Percentage: e.target.value,
    });
  };

  const handleEditmodal = (featuredata) => {
    let formData = {
      ...formValues,
      Migration_Name: featuredata.Migration_Name,
      // Object_Type: featuredata.Object_Type,
      Feature_Name: String(featuredata.Feature_Name),
      Sequence: featuredata.Sequence,
      Source_FeatureDescription: featuredata.Source_FeatureDescription,
      Target_FeatureDescription: featuredata.Target_FeatureDescription,
      Target_Expected_Output: featuredata.Target_Expected_Output,
      Target_ActualCode: featuredata.Target_ActualCode,
      Source_Code: featuredata.Source_Code,
      Conversion_Code: featuredata.Conversion_Code,
      Keywords: featuredata.Keywords,
      Feature_version_approval_status: "In Progress",
      Estimations: featuredata.Estimations,
      Last_Modified_by: sessionStorage.getItem("uemail"),
      Last_Modified_at: moment(new Date()).format("YYYY-MM-DD"),
      Object_Id: obj_type_id,
      Migration_Id: mig_id,
    };
    setEdithandle(formData);
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios
      .put(
        `${config.API_BASE_URL()}/api/feature_update/${
          featuredata.Feature_Id
        }/`,
        form,
        conf
      )
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "Feature Updated Successfully",
            type: "success",
          });
          setModalupdate(true);
          setOpen(false);
        },
        (error) => {
          console.log(error);
          setNotify({
            isOpen: true,
            message: "Something Went Wrong! Please try Again",
            type: "error",
          });
          setModalupdate(true);
          // setOpen(false)
        }
      );
    setModalupdate(false);
    // setOpen(false);
  };
  const handleEditchange = (Feature_Name) => {
    // console.log(Feature_Name, ' feat')
    setOpen(true);
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Object_Id: obj_type_id,
      User_Email: sessionStorage.getItem("uemail"),
      Migration_Name: headerValue?.Migration_Name,
      Object_Type_String: object_path,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(
        `${config.API_BASE_URL()}/api/feature_detail/${Feature_Name}/`,
        form,
        conf
      )
      .then(
        (res) => {
          // console.log(res.data);
          // Object.keys(res.data).forEach((val) => {
          // if (res.data[val]?.Max_Flag === 1) {
          // setDetaildata(res.data[val].serializer);
          // setFnname(res.data[val]?.serializer?.Feature_Name)
          // setObjtype(res.data[val]?.serializer?.Object_Type)
          // setIsdata(true);
          // setCheckIsEdit(res.data[val]?.edit)
          // setLatest_flag(res.data[val].Latest_Flag)
          // console.log(res.data, 'data')
          if (res.data.length > 0) {
            let featdata = 0;
            let feature_version =0
            res.data.map((ind, feat) => {
              // console.log(ind,feat)
              if (ind?.Feature_Version_Id > featdata) {
                featdata = ind?.Feature_Version_Id;
                feature_version = feat
                // console.log(res.data[feat])
              }
            });
            // console.log(res.data[feature_version])
            setEdithandle(res.data[feature_version]);
            setOpen(true);
          }

          // }
          // })
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  // console.log(edithandle,"============edithandle")
  return (
    <Box
      style={{ width: "97%", marginLeft: 13 }}
      className={classes.Createcontainer}
    >
      <Box py={2}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">{obj_type} - Create Feature</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <TextField
              id="outlined-multiline-static"
              // label="Migration Type"
              multiline
              rows={1}
              onChange={(e) => handleChange(e)}
              label="Migration Type"
              defaultValue="Default Value"
              value={headerValue?.Migration_Name}
              style={{ width: 280 }}
              variant="outlined"
              required
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="Feature Name"
              multiline
              rows={1}
              onChange={(e) => handleChange(e)}
              style={{ width: 280 }}
              name="Feature_Name"
              className={classes.textField}
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <StyledAutocomplete
              size="medium"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={prerunval}
              groupBy={""}
              // defaultValue={{ title: "Procedure" }}
              getOptionLabel={(option) => option.Feature_Name}
              style={{ width: 280 }}
              onChange={(e, v) =>
                handleChange_pred("Sequence", v?.Feature_Name)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Predecessor"
                  name="Sequence"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="Keywords"
              multiline
              rows={1}
              style={{ width: 280 }}
              onChange={(e) => handleChange(e)}
              name="Keywords"
              // defaultValue="Default Value"
              // helperText={featurenamemsg}
              className={classes.textField}
              // helperText="Some important text"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              // multiline
            />
          </Grid>

          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="Estimation"
              multiline
              rows={1}
              onChange={(e) => handleChange(e)}
              name="Estimations"
              style={{ width: 280 }}
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              // multiline
              fullWidth
            />
          </Grid>

          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="% of Automation"
              multiline
              rows={1}
              onChange={(e) => handleChange(e)}
              name="Automation_Percentage"
              style={{ width: 280 }}
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              // multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center">
          <Button
            size="small"
            type="submit"
            // fullWidth
            variant="contained"
            color="primary"
            // align='center'
            // className={classes.submit}
            onClick={handleSubmit}
            startIcon={<SaveIcon />}
            // justifyContent='center'
            style={{ marginBottom: 20 }}
          >
            Create Feature
          </Button>
        </Grid>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />

      <Box py={2} px={2}>
        <Grid container xl={12} justifyContent="space-between" spacing={3}>
          <Grid item xl={12} xs={12} sm={12} md={12}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
            >
              Features List
            </Typography>
            {loading_tdata ? (
              <>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      <StyledTableCell align="center">
                        Feature Name
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Predecessor
                      </StyledTableCell>
                      <StyledTableCell align="center">Keywords</StyledTableCell>
                      <StyledTableCell align="center">
                        Estimation
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        % of Automation
                      </StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {istdata && tableinfo.length > 0 ? (
                      <>
                        {tableinfo.map((row) => (
                          <StyledTableRow container>
                            <StyledTableCell item xl={10} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {row?.Feature_Name}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell item xl={10} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {row?.Sequence === "No Predecessor"
                                ? row?.Sequence
                                : row?.Sequence}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell item xl={10} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {row?.Keywords}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell item xl={10} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {row?.Estimations}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell item xl={10} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {row?.Automation_Percentage}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell item xl={10} align="center">
                              {/* <div className={classes.texttablecell}>
                                                    <IconButton onClick={(e) => { handleEditmodal() }}>
                                                        <EditSharpIcon style={{ color: 'blue' }} />
                                                    </IconButton>
                                                </div> */}

                              <Tooltip
                                title="Edit"
                                aria-label="Edit"
                                onClick={() =>
                                  handleEditchange(row?.Feature_Name)
                                }
                              >
                                <EditSharpIcon style={{ color: "blue" }} />
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <StyledTableRow container>
                          <StyledTableCell
                            item
                            xl={10}
                            align="center"
                          ></StyledTableCell>
                          <StyledTableCell
                            item
                            xl={10}
                            align="center"
                          ></StyledTableCell>
                          <StyledTableCell
                            item
                            xl={10}
                            align="center"
                          ></StyledTableCell>
                          <StyledTableCell item xl={10} align="left">
                            No Data Found
                          </StyledTableCell>
                          <StyledTableCell
                            item
                            xl={10}
                            align="center"
                          ></StyledTableCell>
                          <StyledTableCell
                            item
                            xl={10}
                            align="center"
                          ></StyledTableCell>
                        </StyledTableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </>
            ) : (
              <>
                {" "}
                <center>
                  <CircularProgress color="primary" style={{ marginTop: 15 }} />
                </center>
              </>
            )}
          </Grid>

          <Snackbar
            open={openAlert}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            {/* <Alert onClose={handleClose} severity="success">
            Data is updated successfully!
          </Alert> */}
          </Snackbar>
          <Modal open={open}>
            <Container className={classes.container}>
              <Typography
                gutterBottom
                align="center"
                variant="h6"
                component="h2"
                className={classes.Object_Type}
              >
                Edit Feature
              </Typography>
              {/* <form className={classes.form} autoComplete="off"> */}
              <div className={classes.item}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel>Predecessor</InputLabel>
                  <Select
                    native
                    value={edithandle.Sequence || ""}
                    onChange={(e) => handleEditchangetext(e)}
                    label="Predecessor"
                    name="Sequence"
                    // defaultValue={edithandle.Sequence}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {" "}
                    {/* <option value="Select Predecessor">Select Predecessor</option> */}
                    {/* <option value="No Predecessor">No Predecessor</option> */}
                    {prerunval.map((item, ind) => {
                      return (
                        <option value={item.Feature_Name}>
                          {item.Feature_Name}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className={classes.item}>
                <TextField
                  id="outlined-multiline-static"
                  label="Keywords"
                  style={{ width: 400 }}
                  multiline
                  rows={1}
                  // value ={row.Keywords}
                  onChange={(e) => handleEditchangetext(e)}
                  name="Keywords"
                  // defaultValue={edithandle.Keywords}
                  // helperText={featurenamemsg}
                  value={edithandle.Keywords}
                  className={classes.textField}
                  // helperText="Some important text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.item}>
                <TextField
                  id="outlined-multiline-static"
                  label="Estimation"
                  rows={1}
                  // value = {row.Estimations}
                  onChange={(e) => handleEditchangetext(e)}
                  name="Estimations"
                  // defaultValue={edithandle.Estimations}
                  value={edithandle.Estimations}
                  // helperText={featurenamemsg}
                  className={classes.textField}
                  // helperText="Some important text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  fullWidth
                />
              </div>

              <div className={classes.item}>
                <TextField
                  id="outlined-multiline-static"
                  label="% of Automation"
                  style={{ width: 400 }}
                  multiline
                  rows={1}
                  // value ={row.Keywords}
                  onChange={(e) => handlechange_Ap(e)}
                  name="Automation_Percentage"
                  // defaultValue={edithandle.Keywords}
                  // helperText={featurenamemsg}
                  value={edithandle.Automation_Percentage}
                  className={classes.textField}
                  // helperText="Some important text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className={classes.item}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 20, marginLeft: 100 }}
                  onClick={() => handleEditmodal(edithandle)}
                >
                  Update
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </div>
              {/* </form> */}
            </Container>
          </Modal>
        </Grid>
      </Box>
    </Box>
  );
}
