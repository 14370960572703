import { Box, Grid, TextField, Typography, styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";

// import Notification from "../Notifications/Notification";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../Features/Notifications/ConfirmDialog";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import GetAppIcon from "@material-ui/icons/GetApp";
import fileSaver from "file-saver";
import { Container, Modal, Snackbar } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import Notification from "../Notifications/Notification";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import axios from "axios";
import config from "../../Config/config";
import { TableContainer } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { TvRounded } from "@material-ui/icons";

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container1: {
    border: "none",
    borderRadius: 15,
    width: 380,
    height: 250,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  Accesslistcontainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "120px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },

  texttablecell: {
    // overflowX: "hidden",
    whiteSpace: "nowrap",
    // width: "190px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // "&:hover": {
    //   overflow: "visible",
    // },
  },

  table: {
    // minWidth: 150,
    width: "90%",
    height: "10%",
    border: "1px black",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  rootc: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  root: {
    padding: "0px 16px",
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // height: 10,
  },
}))(TableRow);

const useStylestable = makeStyles((theme) => ({
  table: {
    // minWidth: 650
    width: "86%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 300,
  },
}));

export default function Rules(props) {
  const classes = useStyles();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [inputrules, setinputRules] = useState();
  const [ruleid, setruleid] = useState();
  const [outputrules, setoutputRules] = useState();
  const [inputrulesenter, setinputrulesenter] = useState();
  const [textrulesenter, settextrulesrulesenter] = useState();
  const [actualoutputrulesenter, setactualoutputrulesenter] = useState();
  const [selectruletype, setselectruletype] = useState();
  const [prepathdata, setPrepathdata] = useState();
  const [list_childparent, setList_childparent] = useState([]);
  const [ruleslist, setRuleslist] = useState([]);
  const [isData, setIsData] = useState(false);
  const [issattdata, setIssattdata] = useState(false);
  const [drop, setDrop] = useState();
  const [droptitle, setDroptitle] = useState();
  const [fupdate, setFupdate] = useState(false);
  const [fupdatedel, setFupdatedel] = useState(false);
  const [fupdatedeltag, setFupdatedeltag] = useState(false);
  const [source_att, setSource_att] = useState([]);
  const [source_codeatt, setSource_codeatt] = useState([]);
  const [target_codeatt, settarget_codeatt] = useState([]);
  const [isscattdata, setIsscattdata] = useState(false);
  const [istcattdata, setIstcattdata] = useState(false);
  const [updatesource, setupdatesource] = useState(false);
  const [updatetarget, setupdatetarget] = useState(false);
  const [updatepythoncode, setupdatepythoncode] = useState(false);
  const [updaterulescode, setupdaterulescode] = useState(false);
  const [ispythondata, setIspythondata] = useState();
  const [isapplyata, setIsapplydata] = useState();
  const [DropDownList, setDropdownList] = useState([]);
  const {
    details,
    createFeature,
    preview,
    editpreview,
    editPreviewdetails,
    headerValue,
    project_version,
    DropDownValues,
    lable,
    mig_id
  } = useSelector((state) => state.dashboardReducer);

  let obj_type_id = props.location?.data?.obj_id;

  const [editdata, seteditdata] = useState({
    detaildata: editPreviewdetails?.data,
  });

  const history = useHistory();
  const classestable = useStylestable();
  let obj = props.location.data?.obj;
  let obj_parent = props.location.data?.obj_id;
  // console.log(obj_parent, 'parent')
  useEffect(() => {
    if (obj === undefined) {
      history.push("/dashboard");
    }
  }, [obj]);

  const handleAttachment_delete = (id) => {
    let formData = {
      Attachment_Id: id,
    };

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/rulesattachment_delete/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "File Deleted",
            type: "success",
          });
          setFupdatedel(true);
          setFupdatedeltag(true);
        },
        (error) => {
          console.log(error);
          setNotify({
            isOpen: true,
            message: "Something Went Wrong! Please try Again",
            type: "error",
          });
        }
      );
    setFupdatedel(false);
    setFupdatedeltag(false);
  };

  const onDownload1 = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body={}

    axios
      .get(`${config.API_BASE_URL()}/api/downloadpythontemplate/`,conf, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileSaver.saveAs(blob, "template.py");
      })
      .catch((err) => {});
  };

  const handleSubmit = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let body = {
      Object_Id: obj_type_id,
      Migration_Name: headerValue?.Migration_Name,
      // Rules_to_Apply: inputrulesenter,
      Input_Field: inputrules,
      Expected_Output_Field: outputrules,
      // Actual_Output_Field: actualoutputrulesenter,
      Migration_Id : mig_id
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/dynamic_rules_creation/`, form, conf)
      .then(
        (res) => {
          setSource_att(res.data);
          if (res.data.length > 0) {
            setIssattdata(true);
          }
          setNotify({
            isOpen: true,
            message: "Data Saved Successfully",
            type: "success",
          });
          setupdatepythoncode(true);
          // setupdaterulescode(true)
        },

        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setupdatepythoncode(false);
    // setupdaterulescode(false)
  };

  const handleAddrules = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let body = {
      Object_Id: obj_type_id,
      Migration_Name: headerValue?.Migration_Name,
      Rules_to_Apply: textrulesenter,
      RuleType: selectruletype,
      Migration_Id: mig_id
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios.post(`${config.API_BASE_URL()}/api/add_rules/`, form, conf).then(
      (res) => {
        setNotify({
          isOpen: true,
          message: "Rule Applied",
          type: "success",
        });
        setupdaterulescode(true);
        settextrulesrulesenter("");
        setupdatepythoncode(true);
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
      }
    );
    setupdaterulescode(false);
    setupdatepythoncode(false);
  };

  const handleApply = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let body = {
      Object_Id: obj_type_id,
      Migration_Name: headerValue?.Migration_Name,
      Actual_Output_Field: actualoutputrulesenter,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/apply_dynamicrules/`, form, conf)
      .then(
        (res) => {
          setactualoutputrulesenter(res.data);
          setNotify({
            isOpen: true,
            message: "Rule Applied",
            type: "success",
          });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handlecreatefile = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let body = {
      body: ispythondata,
      // body: 'jjwjwd999999999',
      Migration_Name: headerValue?.Migration_Name,
      Object_Id: obj_type_id,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios.post(`${config.API_BASE_URL()}/api/create_file/`, form, conf).then(
      (res) => {
        setNotify({
          isOpen: true,
          message: "Updated Python File",
          type: "success",
        });
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
      }
    );
  };

  const handleApplyrules = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let body = {
      Object_Id: obj_type_id,
      Migration_Name: headerValue?.Migration_Name,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/apply_rules_files/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "Rule Applied Successfully",
            type: "success",
          });
          setIsapplydata(res.data);
          setupdatetarget(true);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setupdatetarget(false);
  };

  useEffect(() => {
    if (obj) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };

      let body = {
        Migration_Name: headerValue?.Migration_Name,
        Object_Id: obj_type_id,
      };

      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(`${config.API_BASE_URL()}/api/get_pythoncode/`, form, conf)
        .then(
          (res) => {
            console.log(res.data);
            if (res.data.length > 0) {
              setIspythondata(res.data);
            } else {
              setIspythondata(res.data);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [updatepythoncode, obj_type_id]);

  const handlepyDownload = () => {
    let body = {
      Migration_Name: headerValue?.Migration_Name,
      Object_Id: obj_type_id,
      Object_type: lable,
    };

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    axios
      .post(`${config.API_BASE_URL()}/api/downloadpythonfile/`, body,conf, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        let obj_type = JSON.parse(res["config"].data).Object_type;
        // let chiled_obj_type = JSON.parse(res['config'].data).Child_Object_Type
        let att_name = props.location.data?.obj + "_rules" + ".py";
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileSaver.saveAs(blob, att_name);
      })
      .catch((err) => {});
  };

  const handlesourcetargetDownload = (c_obj, att_name) => {
    let body = {
      Migration_Name: headerValue?.Migration_Name,
      Object_Id: obj_type_id,
      Filename: att_name,
    };

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    axios
      .post(`${config.API_BASE_URL()}/api/attachmentdownload/`, body,conf, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileSaver.saveAs(blob, att_name);
      })
      .catch((err) => {});
  };

  const handleChangedrop = (v) => {
    setDrop(v.code);
    setDroptitle(v.title);
  };

  const handleSubmitdrpm = (e) => {
    var filesub = [];
    if (drop === "Sourceinputfile") {
      const { files } = e.target;
      if (files.length > 0) {
        const fileatt = e.target.files[0];
        filesub.push(fileatt);
        setFupdate(true);
      }
    }

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let formData = {
      Attachment_Type: drop,
      Attachment: filesub[0],
      File_Name: filesub[0].name,
      Migration_Name: headerValue?.Migration_Name,
      Object_Id: obj_type_id,
      Migration_Id:mig_id
    };

    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/attachmentupload/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: droptitle + " Attachment Upload Successfully",
            type: "success",
          });
          setFupdate(false);
          setupdatesource(true);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Please add Attachment " + droptitle,
            type: "error",
          });
          // setFupdate(false)
        }
      );
    setupdatesource(false);
  };

  useEffect(() => {
    if (obj_type_id) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };

      let body = {
        Object_Id: obj_type_id,
      };

      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(`${config.API_BASE_URL()}/api/get_sourcefilenames/`, form, conf)
        .then(
          (res) => {
            setSource_codeatt(res.data);
            console.log(res.data);
            if (res.data.length > 0) {
              setIsscattdata(true);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [updatesource, fupdatedel]);

  useEffect(() => {
    if (obj_type_id) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };

      let body = {
        Object_Id: obj_type_id,
      };

      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(`${config.API_BASE_URL()}/api/get_targetfilenames/`, form, conf)
        .then(
          (res) => {
            settarget_codeatt(res.data);
            console.log(res.data);
            if (res.data.length > 0) {
              setIstcattdata(true);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [updatetarget, fupdatedeltag]);

  useEffect(() => {
    if (obj_type_id) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };

      let body = {
        Object_Id: obj_type_id,
      };

      // console.log(body, 'data')
      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(`${config.API_BASE_URL()}/api/dyanmic_rules_list/`, form, conf)
        .then(
          (res) => {
            if (res.data.length > 0) {
              setList_childparent(res.data[0]);
              setactualoutputrulesenter(res.data[0]?.Actual_Output_Field);
              if(res.data[0]?.Input_Field !=='null'){
                setinputRules(res.data[0]?.Input_Field);
              }else{
                setinputRules('');
              }
              if(res.data[0]?.Expected_Output_Field !=='null'){
                setoutputRules(res.data[0]?.Expected_Output_Field);
              }else{
                setoutputRules('');
              }
              
              setinputrulesenter(res.data[0]?.Rules_to_Apply);
              // setruleid(res.data[0]?.Rules_Id);
            } else {
              setList_childparent([]);
              setinputRules("");
              setoutputRules("");
              setinputrulesenter("");
              setactualoutputrulesenter("");
              // setruleid("");
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something went wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [obj]);

  useEffect(() => {
    if (obj_type_id) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };

      let body = {
        Migration_Name: headerValue?.Migration_Name,
        Object_Type: lable,
      };

      // console.log(body, 'data')
      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios.post(`${config.API_BASE_URL()}/api/objects_list/`, form, conf).then(
        (res) => {
          setRuleslist(res.data);
          setIsData(true);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
    }
  }, [updaterulescode]);

  React.useEffect(() => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    const form = new FormData();
    axios.post(`${config.API_BASE_URL()}/api/ruletypes/`,form, conf).then(
      (res) => {
        setDropdownList(res.data);
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
      }
    );
  }, []);

  return (
    <Box
      style={{ width: "97%", marginLeft: 10 }}
      className={classes.Accesslistcontainer}
    >
      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">
              Rules For {props.location.data?.obj}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container xl={12} justifyContent="space-between" spacing={2}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
            >
              Object Hireracy and Identification rules
            </Typography>
            <TableContainer className={classestable.table}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={classes.primary}>
                  <TableRow>
                    <StyledTableCell align="Left">Object Path</StyledTableCell>
                    <StyledTableCell align="Left">Rule Type</StyledTableCell>
                    <StyledTableCell align="Left">Rule</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isData ? (
                    <>
                      {ruleslist.map((row) => (
                        <>
                          {row?.objectrules === "" ? null : (
                            <>
                              <StyledTableRow container>
                                <StyledTableCell item xl={10}>
                                  <div className={classes.texttablecell}>
                                    <pre>{row.objectpath}</pre>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell item xl={10}>
                                  <div className={classes.texttablecell}>
                                    <pre>{row.ruletype}</pre>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell item xl={6}>
                                  <div className={classes.texttablecell}>
                                    <pre>{row.objectrules}</pre>
                                  </div>
                                </StyledTableCell>
                              </StyledTableRow>
                            </>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <StyledTableCell align="right">No Data</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>

      {/* <Grid container direction="row" justifyContent="center" spacing={2}>
        <Box py={4}>
          <TextField
            id="outlined-basic1"
            label="Migration Objects & Rules"
            multiline
            rows={9}
            value={inputrulesenter}
            onChange={(e) => setinputrulesenter(e.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 850 }}
          />
        </Box>
      </Grid> */}

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">
              {/* {prepathdata} */}
              Add Rules To {props.location.data?.obj}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={DropDownList}
              groupBy={""}
              getOptionLabel={(option) => option.ruletype}
              style={{ width: 300, marginTop: 8 }}
              onChange={(e, v) => setselectruletype(v?.ruletype)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Rule Type"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="Rules Syntax"
              size="small"
              style={{ width: 300, marginTop: 8 }}
              // multiline
              rows={1}
              value={textrulesenter || ""}
              onChange={(e) => settextrulesrulesenter(e.target.value)}
              name="Rules Syntax"
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              component="span"
              size="small"
              style={{ marginTop: 10 }}
              onClick={() => handleAddrules()}
            >
              {" "}
              Add Rule
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Grid container direction="row" justifyContent="center" spacing={2}>
        <Box py={2}>
          <TextField
            id="outlined-basic1"
            label="Python Code"
            multiline
            rows={9}
            value={ispythondata}
            // value={ispythondata?.rules_toApply || updatepythoncode}
            onChange={(e) => setIspythondata(e.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 850 }}
          />
        </Box>
        <Box>
          <Grid container direction="row " justifyContent="center" spacing={2}>
            <h4 style={{ color: "red" }}>
              <pre>
                {/* Note1: Please click on download button after save button{" "}
                <br></br> */}
                Note: When you click on create files button, it will override
                existing "{obj + "_rules.py"}" file.
              </pre>
            </h4>
          </Grid>
        </Box>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              component="span"
              style={{ marginLeft: 20 }}
              onClick={() => handlecreatefile()}
            >
              Create File
            </Button>
          </Grid>
        </Grid>
      </Grid>

     

      <Box py={1} style={{marginTop:20}}>
        <Grid container xl={12} justifyContent="center" spacing={2}>
          <Grid item xl={16} xs={18} sm={18} md={9}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
            >
              Python Rules Template
            </Typography>
            <Table className={classestable.table} aria-label="customized table">
              <TableHead className={classes.primary}>
                <TableRow>
                  {/* <StyledTableCell align="center">Type</StyledTableCell> */}
                  <StyledTableCell align="center">File</StyledTableCell>
                  <StyledTableCell align="center">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow container>
                  <StyledTableCell item xl={10} align="center">
                    <div className={classes.texttablecell}>
                      Standard_Templete.py
                    </div>
                  </StyledTableCell>
                  <StyledTableCell item xl={2} align="center">
                    <Box flexDirection="row">
                      <IconButton
                        onClick={(e) => onDownload1("Standerd_Templete.py")}
                      >
                        <GetAppIcon style={{ color: "blue" }} />
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow container>
                  <StyledTableCell item xl={10} align="center">
                    <div className={classes.texttablecell}>
                      {obj + "_rules.py"}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell item xl={2} align="center">
                    <Box flexDirection="row">
                      <IconButton onClick={(e) => handlepyDownload()}>
                        <GetAppIcon style={{ color: "blue" }} />
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>

        

        
      </Box>

      <Box py={4}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Autocomplete
              style={{ width: 270, marginRight: 400 }}
              fullWidth
              id="grouped-demo"
              options={[
                { title: "Source Input File", code: "Sourceinputfile" },
                { title: "Target Output File", code: "Targetoutputfile" },
                { title: "Python Rules Template", code: "Pythonrulestemplate" },
              ]}
              groupBy={""}
              // defaultValue={{ title: "Source Description" }}
              getOptionLabel={(option) => option.title}
              name="Attachemnets"
              onChange={(e, v) => handleChangedrop(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Attachments"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <div className={classes.rootc}>
              <input
                accept="file"
                className={classes.input}
                id="contained-button-file3"
                onChange={(e) => handleSubmitdrpm(e)}
                multiple={false}
                onClick={(event) => {
                  event.target.value = null;
                }}
                type="file"
              />

              <label htmlFor="contained-button-file3">
                <Button
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  color="primary"
                  size="small"
                  component="span"
                  style={{ marginTop: -78, marginLeft: 70 }}
                >
                  Upload
                </Button>
              </label>
            </div>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              component="span"
              size="small"
              style={{ marginTop: -119, marginLeft: 390 }}
              value={isapplyata}
              onClick={() => handleApplyrules()}
            >
              Apply Rules file
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Grid container xl={12} justifyContent="space-between" spacing={1}>
        <Grid item xl={19} xs={22} sm={22} md={6}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            component="h2"
            className={classes.Object_Type}
          >
            Source Input File
          </Typography>
          <Table className={classestable.table} aria-label="customized table">
            <TableHead className={classes.primary}>
              <TableRow>
                <StyledTableCell align="center">File</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isscattdata ? (
                <>
                  {source_codeatt.map((row) => (
                    <StyledTableRow container>
                      <StyledTableCell item xl={10}>
                        <div className={classes.texttablecell}>
                          {row.filename}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell item xl={2}>
                        <div className={classes.texttablecell}>
                          <Box flexDirection="row">
                            <IconButton
                              onClick={(e) =>
                                handlesourcetargetDownload(
                                  "Sourceinputfile",
                                  row.filename
                                )
                              }
                            >
                              <GetAppIcon
                                style={{
                                  color: "blue",
                                  marginLeft: 50,
                                  marginRight: 40,
                                }}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => handleAttachment_delete(row.id)}
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </Box>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              ) : (
                <>
                  <StyledTableCell align="right">No Data check</StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                </>
              )}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xl={19} xs={22} sm={22} md={6}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            component="h5"
            className={classes.Object_Type}
          >
            Target Output File
          </Typography>
          <Table className={classestable.table} aria-label="customized table">
            <TableHead className={classes.primary}>
              <TableRow>
                {/* <StyledTableCell align="center">Type</StyledTableCell> */}
                <StyledTableCell align="center">File</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {istcattdata ? (
                <>
                  {target_codeatt.map((row1) => (
                    <StyledTableRow container>
                      <StyledTableCell item xl={10}>
                        <div className={classes.texttablecell}>
                          {row1.filename}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell item xl={2}>
                        <div className={classes.texttablecell}>
                          <Box flexDirection="row">
                            <IconButton
                              onClick={(e) =>
                                handlesourcetargetDownload(
                                  "Targetoutputfile",
                                  row1.filename
                                )
                              }
                            >
                              <GetAppIcon
                                style={{
                                  color: "blue",
                                  marginLeft: 50,
                                  marginRight: 40,
                                }}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => handleAttachment_delete(row1.id)}
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </Box>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              ) : (
                <>
                  <StyledTableCell align="right">No Data check</StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                </>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        spacing={1}
        style={{ marginTop: 30 }}
      >
        <Grid>
          <TextField
            id="outlined-multiline-static1"
            label="Sample Input"
            size="small"
            rows={15}
            name="Input"
            multiline
            value={inputrules}
            onChange={(e) => setinputRules(e.target.value)}
            className={classes.textField}
            variant="outlined"
            style={{ width: 970 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <h4>
          Note: Please provide output code of Pre as input to sub-objects input.
        </h4>

        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={1}
          style={{ marginTop: 15 }}
        >
          <Grid>
            <TextField
              id="outlined-multiline-static2"
              label="Expected Output"
              size="small"
              style={{ width: 970 }}
              multiline
              rows={15}
              value={outputrules}
              name="Output"
              onChange={(e) => setoutputRules(e.target.value)}
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box style={{ marginTop: 5 }}>
        <Grid container direction="row " justifyContent="center" spacing={2}>
          <h4 style={{ color: "red" }}>
            Note : Please click on Save button before clicking on Apply
          </h4>
        </Grid>
      </Box>

      <Box py={2} px={2} style={{ marginTop: 5 }}>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Box className={classes.root}>
            <Grid container direction="row" justifyContent="center">
              <Grid
                item
                direction="row"
                justifyContent="center"
                spacing={3}
                style={{ marginBottom: 10 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  size="small"
                  style={{ marginLeft: 15 }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </Grid>{" "}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  component="span"
                  style={{ marginLeft: 10 }}
                  onClick={() => handleApply()}
                >
                  Apply Rules
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={1}
            style={{ marginTop: 10, marginBottom: 30 }}
          >
            <Grid>
              <TextField
                id="outlined-multiline-static2"
                label="Actual Output"
                size="small"
                // style={{ m: 1, width: "115ch", marginLeft: 80 }}
                multiline
                rows={15}
                value={actualoutputrulesenter}
                name="Output"
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 970 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}
