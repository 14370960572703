import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import DeleteIcon from "@material-ui/icons/Delete";
import Label from "@material-ui/icons/Label";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import InfoIcon from "@material-ui/icons/Info";
import ForumIcon from "@material-ui/icons/Forum";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ActionMenu from "../../src/Redux/actions/Menuaction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import ConfirmDialog from "../Features/Notifications/ConfirmDialog";
import { drop } from "lodash";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: "#0A7D7F",
      color: "",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const history = useHistory();
  const classes = useTreeItemStyles();
  const dispatch = useDispatch();
  const IsSuperAdmin = sessionStorage.getItem("isSuperAdmin");
  // const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    mainheader,
    data,
    // deleteitem,
    datavalue,
    sub,
    admin,
    createflag,
    ...other
  } = props;

  return (
    <>
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography
              variant="body2"
              className={classes.labelText}
              style={{ color: "white" }}
            >
              {labelText}
            </Typography>

            <Typography
              variant="caption"
              color="inherit"
              style={{ color: "white" }}
            >
              {labelInfo}
            </Typography>
          </div>
        }
        style={{
          "--tree-view-color": color,
          "--tree-view-bg-color": bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
    </>
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export default function GmailTreeView({
  menuList,
  dropdown,
  deleteitem,
  confirmDialog,
  setConfirmDialog,
  admin,
  createflag,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const MenuSelected = (fname, lable, objid, prj_flag) => {
    // console.log(treepath, "path");
    // let treearray = [];
    // if (treepath) {
    //   let mod_array = treepath.split("/");
    //   for (var i = 0; i < mod_array.length; i++) {
    //     if (mod_array[i] === lable) {
    //       treearray.push(mod_array[i]);
    //       break;
    //     } else {
    //       treearray.push(mod_array[i]);
    //     }
    //   }
    //   let array_join = treearray.join("/");
    //   dispatch(ActionMenu.treepath_edit(array_join));
    //   // console.log(array_join,'array join')
    // }

    dispatch(ActionMenu.ActionMenu(fname));
    // dispatch(ActionMenu.lableselect(lable));
    dispatch(ActionMenu.object_id_get(objid));
    dispatch(ActionMenu.prj_version_flag(prj_flag))

    // dispatch(ActionMenu.ActionObjectMenu(value));
  };
  // const [prepathdata, setPrepathdata] = useState();
  // let prepath;
  // const handleTreeItemClick = (obj) => {

  //   if (prepath) {
  //     let mod_str = prepath.split("/");
  //     let identified_str = mod_str.pop();
  //     // console.log(identified_str)
  //     // console.log(identified_str, '===', obj)
  //     if (identified_str === obj) {
  //       setPrepath(prepath);
  //     } else {
  //       setPrepath(prepath + "/" + obj);
  //     }
  //   } else {
  //     setPrepath(obj);
  //   }
  // };

  // const handleTreeItemClick = (obj, prepath) => {
  //   let split_path = prepath.split("/");
  //   // console.log(split_path);
  //   setPrepathdata('')
  //   split_path.forEach(function (value, i) {
  //     if (value === obj) {
  //       if(split_path.at(0)===obj){
  //         // console.log('if',split_path.at(0),obj)
  //         setPrepathdata(split_path.at(0));
  //       }else{
  //         // console.log('else')
  //         setPrepathdata(split_path.at(i - 1));
  //       }

  //     }
  //   });
  // };

  // const handleTreeItemClick = (lable) => {
  //   let treearray = [];
  //   if (treepath) {
  //     let mod_array = treepath.split("/");
  //     for (var i = 0; i < mod_array.length; i++) {
  //       if (mod_array[i] === lable) {
  //         treearray.push(mod_array[i]);
  //         break;
  //       } else {
  //         treearray.push(mod_array[i]);
  //       }
  //     }
  //     let array_join = treearray.join("/");
  //     dispatch(ActionMenu.treepath_edit(array_join));
  //   }
  // };

  const RenderTree = (nodes) => {
    const history = useHistory();

    // if (prepath) {
    //   prepath = prepath + "/" + nodes?.Object_Type;
    // } else {
    //   prepath = nodes?.Object_Type;
    // }

    let obj = nodes?.Object_Type;
    // let obj_pre = nodes?.Object_Type;
    // console.log(prepath)
    let obj_id = nodes?.Object_Id;
    

    return (
      <TreeItem
        key={nodes?.Object_Id}
        nodeId={nodes?.Object_Id}
        // onClick={() => {
        //   handleTreeItemClick(nodes?.Object_Type);
        // }}
        // onLabelClick={() => handleTreeItemClick(nodes?.Object_Type, prepath)}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "white", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_Type}
            </Typography>

            <>
              {nodes?.Admin_Flag === true ? (
                <>
                  {nodes?.Object_Type === "Pre" ||
                  nodes?.Object_Type === "Post" ? (
                    <AddIcon
                      color="inherit"
                      style={{ color: "yellow", marginRight: 10 }}
                      onClick={() => {
                        history.push({
                          pathname: `/create`,
                          data: { obj, obj_id },
                        });
                      }}
                    />
                  ) : (
                    <EditIcon
                      color="inherit"
                      style={{ color: "yellow", marginRight: 10 }}
                      onClick={() =>
                        // history.push('/rules',)
                        {
                          // handleTreeItemClick(nodes?.Object_Type, prepath);
                          history.push({
                            pathname: `/dynamicrules`,
                            data: { obj, obj_id },
                          });
                        }
                      }
                    />
                  )}

                  {/* {count == 1 && ( */}
                </>
              ) : (
                <></>
              )}

              {/* )} */}
            </>
          </div>
        }
      >
        {nodes?.Sub_Menu.map((fn, key) => {
          return (
            <StyledTreeItem
              key={fn?.Feature_Name}
              nodeId={key + fn?.Feature_Name}
              labelText={fn?.Feature_Name}
              labelIcon={ViewModuleIcon}
              style={{ color: "white" }}
              onClick={() => {
                MenuSelected(
                  fn?.Feature_Name,
                  nodes?.Object_Type,
                  nodes?.Object_Id,
                  nodes?.Max_Project_Version_Flag
                );
                history.push("/PreviewCode");
              }}
            ></StyledTreeItem>
          );
        })}

        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Pre") {
                return RenderTree(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type !== "Pre" || node?.Object_Type !== "Post") {
                return RenderTree(node, 0);
              }
            })
          : null} */}

        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
            console.log(node?.Object_Type)
              if (node?.Object_Type === "Post" || node?.Object_Type === "Pre") {
                
              }else{
                return RenderTree(node, 0);
              }
            })
          : null}
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Post") {
                return RenderTree(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              RenderTree(node, 0);
            })
          : null} */}
      </TreeItem>
    );
  };

  let count = 1;
  // console.log(prepath, "path");
  return (
    <TreeView
      className={classes.root}
      defaultExpanded={["3"]}
      // expanded={true}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {menuList?.length >= 1 && (
        <>
          {menuList[0] !== undefined && (
            // <>{RenderTree(menuList[0], count, prepath)}</>
            <>{RenderTree(menuList[0])}</>
          )}
        </>
      )}
    </TreeView>
  );
}
