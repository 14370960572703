import { Box, Grid, TextField, Typography, styled } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import config from "../../Config/config";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import TreeItem from "@material-ui/lab/TreeItem";
import Notification from "../Notifications/Notification";
import TreeView from "@material-ui/lab/TreeView";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableContainer } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { Container, Modal, Snackbar } from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const useStyles = makeStyles((theme) => ({
  requestcontainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "120px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },
  texttablecell: {
    // overflowX: "hidden",
    whiteSpace: "nowrap",
    // width: "250px",
    overflow: "visible",
    textOverflow: "ellipsis",
    // '&:hover': {
    //     overflow: 'visible'
    // }
  },

  table: {
    // minWidth: 150,
    width: "90%",
    height: "10%",
    border: "1px black",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "35ch",
    },
  },
  rootc: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },

  //pop up weindow

  container: {
    border: "none",
    borderRadius: 15,
    width: 460,
    height: 370,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 60,
    left: 0,
    right: 0,
    margin: "auto",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  root: {
    padding: "0px 30px",
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // height: 10,
  },
}))(TableRow);

const useStylestable = makeStyles((theme) => ({
  table: {
    // minWidth: 650
    width: "92%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 300,
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5",
    },
  },
});

export default function CreateRules(props) {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState({});
  const [isselectedItem, setisSelectedItem] = useState(false);
  const [path, setPath] = useState();
  const [list_objects, setList_of_objects] = useState([]);
  const [objectType, setObjectType] = useState();
  const [ruleslist, setRuleslist] = useState([]);
  const [isData, setIsData] = useState(false);
  const [open, setOpen] = useState(false);
  const [fupdatedel, setFupdatedel] = useState(false);
  const [fupdatesub, setFupdatesub] = useState(false);
  const [Dataupdate, setDataupdate] = useState(false);
  // const [obj, setobj] = useState();
  const [edithandle, setEdithandle] = useState();
  const [edithandlerule, setEdithandlerule] = useState();
  const [edithandlecomment, setEdithandlecomment] = useState();
  const [edithandleid, setEdithandleid] = useState();
  const [formValues, setformvalues] = useState({});

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [input, setInput] = useState();
  const [idval, setidval] = useState();
  const [inputComment, setInputComment] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const {
    details,
    createFeature,
    preview,
    editpreview,
    editPreviewdetails,
    headerValue,
    project_version,
  } = useSelector((state) => state.dashboardReducer);

  let obj_type_id = props.location?.data?.obj_id;

  useEffect(() => {
    if (headerValue && project_version) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      // console.log(project_version, " project versin");
      let body = {
        Project_Version_Id: project_version,
        Migration_Name: headerValue?.Migration_Name,
      };

      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(`${config.API_BASE_URL()}/api/object_types_format/`, form, conf)
        .then(
          (res) => {
            setList_of_objects(res.data);
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something went wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [headerValue?.Migration_Name, project_version]);

  const classestable = useStylestable();

  const handleTreeStructureselected = (v) => {
    setSelectedItem(v);
    setisSelectedItem(true);
  };

  const clearPath = () => {
    setPath("");
  };

  const handleTreeItemClick = (obj) => {
    if (path) {
      let mod_str = path.split("/");
      let identified_str = mod_str.pop();
      // console.log(identified_str)
      // console.log(identified_str, '===', obj)
      if (identified_str === obj) {
        setPath(path);
      } else {
        setPath(path + "/" + obj);
      }
    } else {
      setPath(obj);
    }
    // console.log(path, ' path')
  };

  const RenderTree = (nodes) => {
    // console.log("selection path ", );
    // const history = useHistory();
    return (
      <TreeItem
        key={nodes?.Object_name}
        nodeId={nodes?.Object_name}
        onLabelClick={() => {
          handleTreeItemClick(nodes?.Object_name);
        }}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "black", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_name}
            </Typography>
          </div>
        }
      >
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => RenderTree(node))
          : null}
      </TreeItem>
    );
  };

  const handleCreateRules = (input, objectType, inputComment) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Object_name: objectType,
      rule: input,
      comments: inputComment,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios.post(`${config.API_BASE_URL()}/api/samplecreate/`, form, conf).then(
      (res) => {
        setNotify({
          isOpen: true,
          message: "Rule Created",
          type: "success",
        });
        setFupdatesub(true);
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something went wrong Please try Again",
          type: "error",
        });
      }
    );
    setFupdatesub(false);
  };

  const handleeditdata = (idche) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let body = {
      id: idche,
      Object_name: edithandle,
      rule: edithandlerule,
      comments: edithandlecomment,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .put(`${config.API_BASE_URL()}/api/sampleapisupdate/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "Rule Updated",
            type: "success",
          });
          setDataupdate(true);
          setOpen(false);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
    setDataupdate(false);
  };

  const handleeditdelete = (iddel) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      id: iddel,
    };

    // console.log(body, 'bbbbbbbbbbbbbbbbbbbbbbbbbbbbb')

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/sampleapisdelete/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "Rule Deleted",
            type: "success",
          });
          setFupdatedel(true);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
    setFupdatedel(false);
  };

  const handleChange = (e) => {
    // console.log(e.target.name)
    setformvalues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  // const handleEditchangetext = (e) => {
  //   if (e.target.name === "ObjectName") {
  //     if (e.target.value === null) {
  //       setEdithandle({
  //         ...edithandle,
  //         ObjectName: 0,
  //       });
  //     } else {
  //       setEdithandle({
  //         ...edithandle,
  //         ObjectName: e.target.value,
  //       });
  //     }

  //   } else if (e.target.name === "Rule") {
  //     if (e.target.value === null) {
  //       setEdithandlerule({
  //         ...edithandlerule,
  //         Rule: 0,
  //       });
  //     } else {
  //       setEdithandlerule({
  //         ...edithandlerule,
  //         Rule: e.target.value,
  //       });
  //     }
  //   } else if (e.target.name === "Comments") {
  //     if (e.target.value === null) {
  //       setEdithandlecomment({
  //         ...edithandlecomment,
  //         Comments: 0,
  //       });
  //     } else {
  //       setEdithandlecomment({
  //         ...edithandlecomment,
  //         Comments: e.target.value,
  //       });
  //     }
  //   }
  //   setformvalues({
  //     ...formValues,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // console.log(edithandle)
  // console.log(edithandlerule)
  // console.log(edithandlecomment)
  // console.log(edithandleid)

  useEffect(() => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    axios.get(`${config.API_BASE_URL()}/api/sampleapislist/`, conf).then(
      (res) => {
        setRuleslist(res.data);
        setIsData(true);
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
      }
    );
  }, [fupdatesub, fupdatedel, Dataupdate]);

  return (
    <>
      <Box py={2} px={2}>
        <Grid container xl={12} justifyContent="space-between" spacing={2}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
            >
              Rules List
            </Typography>
            <TableContainer className={classestable.table}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={classes.primary}>
                  <TableRow>
                    <StyledTableCell align="center">Rule Type</StyledTableCell>
                    {/* <StyledTableCell align="Left">Object Path</StyledTableCell> */}
                    <StyledTableCell align="center">Rule</StyledTableCell>
                    <StyledTableCell align="center">Comments</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                    {/* <StyledTableCell align="Left">Delete Record</StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isData ? (
                    <>
                      {ruleslist.map((row) => (
                        <StyledTableRow container>
                          <StyledTableCell item xl={10} align="center">
                            <div className={classes.texttablecell}>
                              <pre>{row.Object_name}</pre>
                            </div>
                          </StyledTableCell>
                          {/* <StyledTableCell item xl={6}>
                                <div className={classes.texttablecell}>
                                  object_path
                                </div>
                              </StyledTableCell> */}
                          <StyledTableCell item xl={6} align="center">
                            <div className={classes.texttablecell}>
                              <pre>{row.rule}</pre>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell item xl={5} align="center">
                            <div className={classes.texttablecell}>
                              <pre>{row.comments}</pre>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell item xl={3} align="center">
                            <div className={classes.texttablecell}>
                              <Box flexDirection="row">
                              <Button
                              type="button"
                              size="small"
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                              style={{
                                marginTop: "9px",
                                fontSize: "9px",
                                marginBottom: "8px",
                              }}
                              onClick={() => {
                                setEdithandle(row.Object_name);
                                setEdithandlerule(row.rule);
                                setEdithandlecomment(row.comments);
                                setEdithandleid(row.id);
                                setOpen(true);
                              }}
                            >
                              Edit
                            </Button>{" "}
                            <IconButton
                              onClick={() => handleeditdelete(row.id)}
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                              </Box>
                            </div>
                            
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      <StyledTableCell align="right"></StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                      <StyledTableCell align="right">No Data</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Snackbar
          open={openAlert}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        ></Snackbar>
        <Modal open={open}>
          <Container
            className={classes.container}
            style={{ marginBottom: 100 }}
          >
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
              style={{ marginBottom: "20px" }}
            >
              Rules
            </Typography>
            <div className={classes.item}>
              <TextField
                id="outlined-multiline-static"
                label="ObjectNames"
                style={{ width: 400, marginBottom: "20px", height: "60px" }}
                multiline
                rows={1}
                onChange={(e) => setEdithandle(e.target.value)}
                name="ObjectName"
                value={edithandle}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className={classes.item}>
              <TextField
                id="outlined-multiline-static"
                label="Rules"
                style={{ width: 400, marginBottom: "20px", height: "60px" }}
                multiline
                rows={1}
                onChange={(e) => setEdithandlerule(e.target.value)}
                name="Rule"
                value={edithandlerule}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className={classes.item}>
              <TextField
                id="outlined-multiline-static"
                label="Comment"
                style={{ width: 400, marginBottom: "20px", height: "60px" }}
                multiline
                rows={1}
                onChange={(e) => setEdithandlecomment(e.target.value)}
                name="Comments"
                value={edithandlecomment}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className={classes.item}>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: 20, marginLeft: 100 }}
                onClick={() => handleeditdata(edithandleid)}
              >
                Update
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </div>
          </Container>
        </Modal><Snackbar
          open={openAlert}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        ></Snackbar>
        <Modal open={open}>
          <Container
            className={classes.container}
            style={{ marginBottom: 100 }}
          >
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
              style={{ marginBottom: "20px" }}
            >
              Rules
            </Typography>
            <div className={classes.item}>
              <TextField
                id="outlined-multiline-static"
                label="ObjectNames"
                style={{ width: 400, marginBottom: "20px", height: "60px" }}
                multiline
                rows={1}
                onChange={(e) => setEdithandle(e.target.value)}
                name="ObjectName"
                value={edithandle}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className={classes.item}>
              <TextField
                id="outlined-multiline-static"
                label="Rules"
                style={{ width: 400, marginBottom: "20px", height: "60px" }}
                multiline
                rows={1}
                onChange={(e) => setEdithandlerule(e.target.value)}
                name="Rule"
                value={edithandlerule}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className={classes.item}>
              <TextField
                id="outlined-multiline-static"
                label="Comment"
                style={{ width: 400, marginBottom: "20px", height: "60px" }}
                multiline
                rows={1}
                onChange={(e) => setEdithandlecomment(e.target.value)}
                name="Comments"
                value={edithandlecomment}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className={classes.item}>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: 20, marginLeft: 100 }}
                onClick={() => handleeditdata(edithandleid)}
              >
                Update
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </div>
          </Container>
        </Modal>
      </Box>

      <div className={classes.requestcontainer}>
        <Box py={1} px={1}>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <Typography variant="h6">Create Rules</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box py={2} px={2}>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item>
              <TextField
                id="outlined-multiline-static"
                label="Migration Name"
                name="Migration_Name"
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                disabled
                value={headerValue?.Migration_Name}
                size="small"
                style={{ width: 300 }}
              />
            </Grid>
            <Grid item>
              {/* <StyledAutocomplete
                size="small"
                id="grouped-demo"
                className={classes.inputRoottype}
                options={list_objects}
                groupBy={""}
                // defaultValue={{ title: "Procedure" }}
                getOptionLabel={(option) => option.Object_Type}
                style={{ width: 300 }}
                onChange={(e, v) => {
                  // handleTreeStructureselected(v);
                  // clearPath();
                  setObjectType(v?.Object_Type);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ObjectType"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                  />
                )}
              /> */}
              <TextField
                id="outlined-multiline-static"
                label="Object Name"
                multiline
                type="text"
                rows={1}
                name="Sample Rules"
                onChange={(e) => setObjectType(e.target.value)}
                defaultValue={objectType}
                fullWidth
                variant="outlined"
                required
                size="small"
                value={objectType}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: 300 }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* {isselectedItem ? ( */}
        <>
          {/* <Box py={2} px={2}>
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  <TreeView
                    className={classes.root}
                    defaultExpanded={["3"]}
                    style={{ marginRight: 100 }}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                    sx={{
                      height: 264,
                      flexGrow: 1,
                      maxWidth: 400,
                      overflowY: "auto",
                    }}
                  >
                    {RenderTree(selectedItem)}
                  </TreeView>
                </Grid>

                <Grid item>
                  <TextField
                    id="outlined-multiline-static"
                    label="Selected Path"
                    size="small"
                    style={{ width: 300, marginRight: 115 }}
                    multiline
                    rows={2}
                    // onChange={(e) => handlePath()}
                    name="Selected Path"
                    value={path}
                    className={classes.textField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </Grid>
                
                {"  "}
                <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    // size='small'
                    style={{ marginTop: 13 }}
                    onClick={() => clearPath()}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Box> */}

          <Box py={2} px={2}>
            <Grid container direction="row" justifyContent="center">
              <Grid item xs={11}>
                <TextField
                  id="outlined-multiline-static"
                  label="Sample Rules"
                  multiline
                  type="text"
                  rows={10}
                  name="Sample Rules"
                  onChange={(e) => setInput(e.target.value)}
                  defaultValue={input}
                  fullWidth
                  variant="outlined"
                  required
                  value={input}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box py={2} px={2}>
            <Grid container direction="row" justifyContent="center">
              <Grid item xs={11}>
                <TextField
                  id="outlined-multiline-static"
                  label="Comments"
                  multiline
                  type="text"
                  rows={10}
                  name="Comments"
                  onChange={(e) => setInputComment(e.target.value)}
                  // defaultValue={input}
                  fullWidth
                  variant="outlined"
                  required
                  value={inputComment}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box py={2} px={2}>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleCreateRules(input, objectType, inputComment)
                  }
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Notification notify={notify} setNotify={setNotify} />
        </>
      </div>
    </>
  );
}
