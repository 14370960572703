import logo from "./logo.svg";
import React from "react";
import Routing from "./Routers/routes";
import store from "./Redux/store";
import { Provider } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

function App() {
  const history = useHistory();
  // let isAuth = sessionStorage.getItem('isAuth')
  // React.useEffect(() => {
  //   if (isAuth) {
  //     history.push('/dashboard')
  //   }

  // }, [isAuth])

  const handleContextMenu = (e) => {
    e.preventDefault();
  }


  useEffect(() => {
    document.onkeydown = function (e) {
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
        return false;
      }
    };
  }, []);

  return (
    // <div onContextMenu={handleContextMenu}>
    <React.StrictMode >
      <Provider store={store}>
        <Routing />
      </Provider>
    </React.StrictMode>
    // </div>
  );
}

export default App;