import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Route, Router, Routes } from "react-router-dom";

import SignIn from "../Auth/Login";
import SignUp from "../Auth/Signup";
import ClippedDrawer from "../Components/header";
import Home from "../Features/Home";
import AdminAccesslist from "../Features/Modules/AdminAccesslist";
import Request from "../Features/Modules/AccessRequest";
import CreateFeature from "../Features/Modules/CreateFeature";
import EditFeature from "../Features/Modules/EditFeature";
import PageNotFound from "../Features/NotFound";
import ProtectedRoute from "./PrivateRoute";
import MenuAppBar from "../Components/header";
import PreviewCode from "../Features/Modules/PreviewCode";
import EmailVerify from "../Auth/EmailVerificationPage";
import AccessReview from "../Features/Modules/AccessReview";
import RequestFeatureData from "../Features/Modules/RequestFeatureData";
import ForgotPasword from "../Auth/forgotpassword";
import ResendEmail from "../Auth/resendemail";
import SuperadminFunction from "../Features/Modules/superadminpage";
import UseradminFunction from "../Features/Modules/useradminpage";
import ResetPasword from "../Auth/ResetPassword";
import { useHistory } from "react-router-dom";
import FeatureApprovals from "../Features/Modules/FeatureApprovals";
import CreateRules from "../Features/Modules/CreateRules";
import tree from "../Components/treeview3";
import Rules from "../Features/Modules/Rules";
import ConversionProcess from "../Features/Modules/ConversionProcess";
import MigrationAdmin from "../Features/Modules/MigrationAdmin";
import Dynamic_Rules from "../Features/Modules/Dynamic_Rules"
import Prompt from "../Features/Modules/Prompt"


const Routing = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={SignIn} />
        <Route path="/register" component={SignUp} />
        <Route path="/emailverification" component={EmailVerify} />
        <Route path="/forgotpassword" component={ForgotPasword} />
        <Route path="/resendemail" component={ResendEmail} />
        <Route path="/resetpassword" component={ResetPasword} />
        {/* <Route path="/tree" component={tree} /> */}

        <MenuAppBar>
          <ProtectedRoute exact path="/dashboard" component={Home} />
          <ProtectedRoute path="/create" component={CreateFeature} />
          <ProtectedRoute path="/PreviewCode" component={PreviewCode} />
          <ProtectedRoute path="/EditFeature" component={EditFeature} />
          <ProtectedRoute path="/accessreview" component={AccessReview} />
          <ProtectedRoute path="/rules" component={Rules} />
          {/* <ProtectedRoute path="/conversion" component={Conversion} /> */}
          <ProtectedRoute path="/requestdata" component={RequestFeatureData} />

          <ProtectedRoute path="/Request" component={Request} />
          <ProtectedRoute path="/superadmin" component={SuperadminFunction} />
          <ProtectedRoute path="/AdminAccesslist" component={AdminAccesslist} />
          <ProtectedRoute path="/createrules" component={CreateRules} />
          <ProtectedRoute path="/conversion" component={ConversionProcess} />
          <ProtectedRoute path="/prompt" component={Prompt} />
          <ProtectedRoute
            path="/FeatureApprovals"
            component={FeatureApprovals}
          />
          <ProtectedRoute
            path="/dynamicrules"
            component={Dynamic_Rules}
          />
          <ProtectedRoute path="/useradmin" component={UseradminFunction} />
          <ProtectedRoute path="/migadmin" component={MigrationAdmin} />
        </MenuAppBar>
      </Switch>
    </BrowserRouter>
  );
};

export default Routing;
