import {
  Box,
  Grid,
  TextField,
  Typography,
  styled,
  TableContainer,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import GetAppIcon from "@material-ui/icons/GetApp";
// import React from 'react';
// import clsx from "clsx";
import PropTypes from "prop-types";
import ActionMenu from "../../Redux/actions/Menuaction";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { Autocomplete } from "@material-ui/lab";
import ConfirmDialog from "../../Features/Notifications/ConfirmDialog";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import config from "../../Config/config";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import axios from "axios";
import MenuAppBar from "../../Components/header";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AddIcon from "@material-ui/icons/Add";
import { Avatar } from "@material-ui/core";
import Notification from "../Notifications/Notification";
import Menuaction from "../../Redux/actions/Menuaction";

import { Container, Modal, Snackbar } from "@material-ui/core";

const useStylestable = makeStyles((theme) => ({
  table: {
    width: "100%",
    // width:10
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 300,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  texttablecell: {
    // overflowX: 'hidden',
    whiteSpace: "nowrap",
    width: "140px",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    "&:hover": {
      overflow: "visible",
    },
  },

  table: {
    // minWidth: 150,
    width: "60%",
    height: "10%",
    border: "1px black",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  rootc: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },

  //pop up weindow

  container: {
    border: "none",
    borderRadius: 15,
    width: 460,
    height: 390,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  container1: {
    border: "none",
    borderRadius: 15,
    width: 450,
    height: 300,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  container2: {
    border: "none",
    borderRadius: 15,
    width: 450,
    height: 400,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  root: {
    padding: "0px 16px",
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    height: 10,
  },
}))(TableRow);

export default function SuperadminFunction() {
  const FileDownload = require("js-file-download");
  const classes = useStyles();
  const dispatch = useDispatch();
  const classestable = useStylestable();
  const [open1, setOpen1] = useState(false);
  const [open, setOpen] = useState(false);
  const [open_mig_admin, setOpen_mig_admin] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [openAlert_mig_admin, setOpenAlert_mig_admin] = useState(false);
  const [migtype_create, setMigtype_create] = useState();
  const [project_max_limit, setProject_max_limit] = useState();
  const [feature_max_limit, setFeautre_max_limit] = useState();
  const [DropDownList, setDropdownList] = useState([]);
  const [updateDropDownList, setUpdateDropDownList] = useState(false);
  const [parentdropDownList, setParentdropDownList] = useState([]);
  const [modelMigtype_ObjectCreation, setModelMigtype_ObjectCreation] =
    useState();
  // const [Objtype_create, setObjtype_create] = useState();
  const [list_objects, setList_of_objects] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [path, setPath] = useState();
  const [checkbox, setCheckbox] = useState(false);
  const [subobjType, setSubobjtype] = useState();
  const [useremail, setuseremail] = useState();
  const [useremail2, setuseremail2] = useState();
  // const [superuseremail, setsuperuseremail] = useState()
  const [updateAdminTable, setUpdateAdminTable] = useState(false);
  const [updateSuperAdminTable, setUpdateSuperAdminTable] = useState(false);
  const [updatemigrationAdminTable, setUpdatemigrationAdminTable] =
    useState(false);
  const [updatermSuperAdminTable, setUpdatermSuperAdminTable] = useState(false);
  const [updateaccessAdminTable, setupdateaccessAdminTable] = useState(false);
  const [userslist, setUserslist] = useState([]);
  const [selecetd1, setSelected1] = useState(false);
  const [useradmin_tableupdate, setuseradmin_tableupdate] = useState(false);
  const [superadminlist, setsuperadminlist] = useState([]);
  const [isData, setIsData] = useState(false);
  const [loading_salist, setLoading_salist] = useState(false);
  const [useradmin_list, setUseradmin_list] = useState([]);
  const [isUserAdminData, setIsUserAdminData] = useState(false);
  const [loading_ualist, setLoading_ualist] = useState(false);
  const [parentObject, setParentObject] = useState();
  const [migAdminList, setMigAdminList] = useState([]);
  const [select_prj_versionitem, setSelect_prj_versionitem] = useState();

  const [isMigAdminData, setIsMigAdminData] = useState(false);
  const [loading_malist, setLoading_malist] = useState(false);
  const [migAdminCreateEmail, setMigAdminCreateEmail] = useState(false);

  const [rm_admin_email, setRm_admin_email] = useState();
  const [rm_admin_mig, setRm_admin_mig] = useState();
  const [rm_admin_objectslist, setRm_admin_objectslist] = useState([]);
  const [openrm_admin, setOpenrm_admin] = useState(false);
  const [objecttype_rm_admin, setObjecttype_rm_admin] = useState();
  const [useradminmiglist, setuseradminmiglist] = useState([]);
  const [newMigtype, setNewMigtype] = useState();
  const [new_prj_versionitem, setNew_prj_versionitem] = useState();
  const [deletemig, setDeletemig] = useState();
  const [mig_subdelete, setMig_subdelete] = useState();
  const [mig_id, setMig_id] = useState();
  const [azure_mig_name, setAzure_mig_name] = useState();
  const [azure_mig_id, setAzure_mig_id] = useState();
  const [migAdminCreation_mig, setMigAdminCreation_mig] = useState();

  const {
    details,
    createFeature,
    preview,
    editpreview,
    editPreviewdetails,
    headerValue,
    project_version,
    DropDownValues,
  } = useSelector((state) => state.dashboardReducer);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  let history = useHistory();

  useEffect(() => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios.get(`${config.API_BASE_URL()}/api/useradmins_list/`, conf).then(
      (res) => {
        setUseradmin_list(res.data);
        if (res.data.length > 0) {
          setIsUserAdminData(true);
        }
        setLoading_ualist(true);
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
        setLoading_ualist(false);
      }
    );
  }, [useradmin_tableupdate]);

  const handleMiguseradminlist = (v) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: v?.email,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(
        `${config.API_BASE_URL()}/api/migration_names_list_user_admin/`,
        form,
        conf
      )
      .then(
        (res) => {
          if (res.data.length > 0) {
            dispatch(
              Menuaction.is_mig_admin_select(res.data[0]?.is_Migration_Admin)
            );
          }
          setuseradminmiglist(res.data);
          if (updateDropDownList) {
            dispatch(Menuaction.getdropdownlist(res.data));
          }
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  useEffect(() => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios.get(`${config.API_BASE_URL()}/api/migration_admin_list/`, conf).then(
      (res) => {
        setMigAdminList(res.data);
        if (res.data.length > 0) {
          setIsMigAdminData(true);
        }
        setLoading_malist(true);
      },

      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
        setLoading_malist(false);
      }
    );
  }, [updatemigrationAdminTable]);

  useEffect(() => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios.get(`${config.API_BASE_URL()}/api/superadmins_list/`, conf).then(
      (res) => {
        setsuperadminlist(res.data);
        setLoading_salist(true);
        if (res.data.length > 0) {
          setIsData(true);
        }
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
        setLoading_salist(false);
      }
    );
  }, [updateSuperAdminTable, updatermSuperAdminTable]);

  useEffect(() => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios.get(`${config.API_BASE_URL()}/api/users_list/`, conf).then(
      (res) => {
        setUserslist(res.data);
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
      }
    );
  }, []);

  React.useEffect(() => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios.get(`${config.API_BASE_URL()}/api/migration_names_list/`, conf).then(
      (res) => {
        setDropdownList(res.data);
        // if (updateDropDownList) {
        //   dispatch(Menuaction.getdropdownlist(res.data));
        // }
        //
        // if (res.data.length > 0) {
        // }
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
      }
    );
  }, [updateDropDownList]);

  const handlePearentobjecttypes = (Migration_Name) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Migration_Name: Migration_Name,
      Project_Version_Id: project_version,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/parent_object_list/`, form, conf)
      .then(
        (res) => {
          // setParentdropDownList([{ Parent_Object: "ALL" }].concat(res.data));
          setParentdropDownList(res.data);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handleMigrationCreate = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Project_Version_Id: "1",
      Migration_Name: migtype_create,
      Project_Version_limit: project_max_limit,
      Feature_Version_Limit: feature_max_limit,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/migrationcreate/`, form, conf)
      .then(
        (res) => {
          if (res.data === "Migration Type already exist") {
            setNotify({
              isOpen: true,
              message: "Migration Type already exist",
              type: "error",
            });
          } else {
            setNotify({
              isOpen: true,
              message: "Created Migration Type",
              type: "success",
            });
            dispatch(Menuaction.project_version(body.Project_Version_Id));
            setUpdateDropDownList(true);
          }
          setOpen1(false);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setUpdateDropDownList(false);
  };

  const handleObjectypeCreate = (migtype_create, mig_id) => {
    let body;
    let obj;
    if (checkbox === true) {
      if (subobjType || subobjType !== "") {
        obj = path + "/" + subobjType;
      } else {
        obj = path;
      }
      body = {
        Project_Version_Id: project_version,
        Migration_Name: migtype_create,
        Object_Type_Str: obj,
        Migration_Id: mig_id,
      };
    } else {
      body = {
        Project_Version_Id: project_version,
        Migration_Name: migtype_create,
        Object_Type_Str: path,
        Migration_Id: mig_id,
      };
    }
    // console.log(body,' body')

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/object_type_create/`, form, conf)
      .then(
        (res) => {
          if (res.data === "Object Type already exist") {
            setNotify({
              isOpen: true,
              message: "Object Type already exist",
              type: "error",
            });
          } else {
            setNotify({
              isOpen: true,
              message: "Object Type created",
              type: "success",
            });
            // handlePearentobjecttypes(modelMigtype_ObjectCreation);
            handleGetObjecttypesList(migtype_create);
            dispatch(Menuaction.reloadAction(true));
          }

          // setOpen(false);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handleGetObjecttypesList = (migtype) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    // console.log(project_version, " project versin");
    let body = {
      Project_Version_Id: project_version,
      Migration_Name: migtype,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/object_types_format/`, form, conf)
      .then(
        (res) => {
          setList_of_objects(res.data);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const RenderTree = (nodes) => {
    // console.log("selection path ", );
    // const history = useHistory();
    return (
      <TreeItem
        key={nodes?.Object_Type}
        nodeId={nodes?.Object_Type}
        onLabelClick={() => handleTreeItemClick(nodes?.Object_Type)}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "black", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_Type}
            </Typography>
          </div>
        }
      >
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              console.log(node?.Object_Type);
              if (node?.Object_Type === "Post" || node?.Object_Type === "Pre") {
              } else {
                return RenderTree(node, 0);
              }
            })
          : null}
      </TreeItem>
    );
  };
  const handleTreeStructureselected = (v) => {
    setSelectedItem(v);
  };

  const handleTreeItemClick = (obj) => {
    if (path) {
      let mod_str = path.split("/");
      let identified_str = mod_str.pop();
      // console.log(identified_str)
      // console.log(identified_str, '===', obj)
      if (identified_str === obj) {
        setPath(path);
      } else {
        setPath(path + "/" + obj);
      }
    } else {
      setPath(obj);
    }
  };

  const handle_sub_obj = (v) => {
    setSubobjtype(v);
  };
  const clearPath = () => {
    console.log("clear");
    setPath("");
    setSubobjtype("");
  };

  const handleChangeCheckBox = (event) => {
    setCheckbox(event.target.checked);
  };
  // console.log(path,' path')

  const handlesuperadmincreation = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: useremail,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/create_superadmin/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "super admin created successfully",
            type: "success",
          });

          setUpdateSuperAdminTable(true);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setUpdateSuperAdminTable(false);
  };

  const handlemigrationadmincreation = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: useremail2,
      Migration_Name: migtype_create,
      // Object_Type: parentObject,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/create_migration_admin/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });

          setUpdatemigrationAdminTable(true);
          dispatch(Menuaction.reloadAction(true));
          handleMiguseradminlist({ email: sessionStorage.getItem("uemail") });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong",
            type: "error",
          });
        }
      );
    setUpdatemigrationAdminTable(false);
    dispatch(Menuaction.reloadAction(false));
  };

  const handleuseremail = (v) => {
    setSelected1(true);
    setuseremail(v?.email);
  };

  const handleuseradmincreation = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: useremail,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/create_useradmin/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
          setuseradmin_tableupdate(true);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setuseradmin_tableupdate(false);
    // setIsUserAdminData(false)
  };

  const handleMigratioAdminCreationfunc = (email, mig) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: email,
      Migration_Name: mig,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/create_migration_admin/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handledeleteuseradmin = (email) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: email,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/remove_useradmin/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
          setuseradmin_tableupdate(true);
          // setIsUserAdminData(true)
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setuseradmin_tableupdate(false);
    // setIsUserAdminData(false)
  };

  const handleuseremail1 = (v) => {
    setuseremail(v?.email);
  };

  const handleuseremail2 = (v) => {
    setuseremail2(v?.email);
  };

  const handledeletesuperadmin = (email) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: email,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/remove_superadmin/`, form, conf)
      .then(
        (res) => {
          if (res.data === "super admin removed successfully") {
            setNotify({
              isOpen: true,
              message: res.data,
              type: "success",
            });
            setUpdatermSuperAdminTable(true);
            // sessionStorage.setItem("isSuperAdmin", false);
          } else {
            setNotify({
              isOpen: true,
              message: res.data,
              type: "error",
            });
            // setUpdatermSuperAdminTable(true)
            // sessionStorage.setItem("isSuperAdmin", false);
          }
          // setNotify({
          //   isOpen: true,
          //   message: res.data,
          //   type: "success",
          // });
          // setUpdatermSuperAdminTable(true)
          // // sessionStorage.setItem("isSuperAdmin", false);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setUpdatermSuperAdminTable(false);
  };

  const handleModelopen = (item) => {
    setRm_admin_email(item.Email);
    setRm_admin_mig(item.Migration_Name);
    // setRm_admin_objectslist
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: item.Email,
      Migration_Name: item.Migration_Name,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(
        `${config.API_BASE_URL()}/api/object_type_list_remove_admin/`,
        form,
        conf
      )
      .then(
        (res) => {
          setRm_admin_objectslist(res.data);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setOpenrm_admin(true);
  };

  const handleRm_migadmin = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: rm_admin_email,
      Migration_Name: rm_admin_mig,
      // Object_Type: objecttype_rm_admin,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/remove_migration_admin/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
          setUpdatemigrationAdminTable(true);
          dispatch(Menuaction.reloadAction(true));
          handleMiguseradminlist({ email: sessionStorage.getItem("uemail") });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setUpdatemigrationAdminTable(false);
    setOpenrm_admin(false);
    dispatch(Menuaction.reloadAction(false));
  };

  const handleCreateNewVersion = (mig_name_prj) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Migration_Name: mig_name_prj,
      Migration_Id: mig_id,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/create_project_version/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });

          let body1 = {
            Migration_Name: headerValue?.Migration_Name,
          };
          const form1 = new FormData();
          Object.keys(body1).forEach((key) => {
            form1.append(key, body1[key]);
          });

          axios
            .post(
              `${config.API_BASE_URL()}/api/project_versions_list/`,
              form1,
              conf
            )
            .then(
              (res) => {
                dispatch(Menuaction.getproj_header_dropdownlist(res.data));
                dispatch(
                  Menuaction.prj_version_title(res.data.slice(-1)[0]?.Title)
                );
                dispatch(
                  Menuaction.project_version(res.data.slice(-1)[0]?.Code)
                );
                setTimeout(() => {
                  history.push("/");
                }, 1000);
              },
              (error) => {
                setNotify({
                  isOpen: true,
                  message: "Something Went Wrong Please try Again",
                  type: "error",
                });
              }
            );
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handleNewVersionMigration = (
    new_prj_versionitem,
    newMigtype,
    mig_id
  ) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Migration_Name: new_prj_versionitem,
      New_Migration_Name: newMigtype,
      Migration_Id: mig_id,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/create_migration_copy/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
          setUpdateDropDownList(true);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setUpdateDropDownList(false);
  };

  const handleDeleteMig = (mig) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Migration_Name: mig,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/migration_delete/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
          setTimeout(() => {
            history.push("/");
          }, 1);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handleDeleteSuObjecttype = (mig, path) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Migration_Name: mig,
      Project_Version_Id: project_version,
      Object_Path_Str: path,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/sub_object_delete/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
          setTimeout(() => {
            history.push("/");
          }, 1);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const migration_id = (mig) => {
    setMig_id(mig);
  };

  const handleAzure_key_encryption = (migname, migid) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    setNotify({
      isOpen: true,
      message: "Encryption Started will notify once completed",
      type: "info",
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: sessionStorage.getItem("uemail"),
      Migration_Name: migname,
      Migration_Id: migid,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/crypto_key_creation/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };
  const downloadDoc = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios({
      url: `${config.API_BASE_URL()}/api/superadmindoc/`,
      headers: conf.headers,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      FileDownload(response.data, "Super_Admin_Interface.pdf");
    });
  };

  return (
    <Box style={{ width: "99%" }}>
      {/* <Box py={1} px={1} style={{ paddingRight: 50 }}> */}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        style={{ marginTop: 10 }}
      >
        <Grid item>
          <div onClick={(e) => downloadDoc()}>
            Document <GetAppIcon style={{ color: "blue", marginBottom: -6 }} />
          </div>
        </Grid>
      </Grid>
      {/* </Box> */}
      <Box>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Super Admin Creation</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={userslist}
              groupBy={""}
              getOptionLabel={(option) => option?.email}
              style={{ width: 300 }}
              onChange={(e, v) => handleuseremail(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="username/email"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid>
            <Button
              variant="contained"
              size="small"
              color="primary"
              component="span"
              style={{ marginTop: 10 }}
              onClick={() => handlesuperadmincreation()}
            >
              {" "}
              Create Super Admin
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
            >
              Super Admin List
            </Typography>
            {loading_salist ? (
              <>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      {/* <StyledTableCell align="left">User Name</StyledTableCell> */}
                      <StyledTableCell align="center">
                        User Email
                      </StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isData ? (
                      <>
                        {superadminlist.map((item) => (
                          <StyledTableRow container>
                            <StyledTableCell item xl={8} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {item.email}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell item xl={8} align="center">
                              <Button
                                type="button"
                                size="small"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                style={{
                                  marginTop: "9px",
                                  fontSize: "9px",
                                  marginBottom: "8px",
                                }}
                                onClick={() =>
                                  handledeletesuperadmin(item.email)
                                }
                              >
                                Delete
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <StyledTableRow container>
                          <StyledTableCell align="center"></StyledTableCell>
                          <StyledTableCell align="left">
                            No Requests
                          </StyledTableCell>
                          {/* <StyledTableCell align="center"></StyledTableCell> */}
                        </StyledTableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </>
            ) : (
              <center>
                <CircularProgress color="primary" style={{ marginTop: 15 }} />
              </center>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">User Admin Creation</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={userslist}
              groupBy={""}
              getOptionLabel={(option) => option?.email}
              style={{ width: 300 }}
              onChange={(e, v) => handleuseremail1(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="username/email"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid>
            <Button
              variant="contained"
              // disabled={!selecetd1}
              size="small"
              color="primary"
              component="span"
              style={{ marginTop: 10 }}
              onClick={() => handleuseradmincreation()}
            >
              {" "}
              Create User Admin
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container xl={12} justifyContent="space-between" spacing={3}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
            >
              User Admin List
            </Typography>
            {loading_ualist ? (
              <>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      {/* <StyledTableCell align="left">User Name</StyledTableCell> */}
                      <StyledTableCell align="center">
                        User Email
                      </StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isUserAdminData ? (
                      <>
                        {useradmin_list.map((item) => (
                          <StyledTableRow container>
                            {/* <StyledTableCell item xl={8}>
                          <div className={classes.texttablecell}>
                            {item.username}
                          </div>
                        </StyledTableCell> */}
                            <StyledTableCell item xl={8} align="center">
                              <div>{item.email}</div>
                            </StyledTableCell>
                            <StyledTableCell item xl={8} align="center">
                              <Button
                                type="button"
                                size="small"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                style={{
                                  marginTop: "9px",
                                  fontSize: "9px",
                                  marginBottom: "8px",
                                }}
                                onClick={() =>
                                  handledeleteuseradmin(item.email)
                                }
                              >
                                Delete
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <StyledTableRow container>
                          <StyledTableCell align="center"></StyledTableCell>
                          <StyledTableCell align="left">
                            No Requests
                          </StyledTableCell>
                          {/* <StyledTableCell align="center"></StyledTableCell> */}
                        </StyledTableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </>
            ) : (
              <center>
                <CircularProgress color="primary" style={{ marginTop: 15 }} />
              </center>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Migration Admin Creation</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={userslist}
              groupBy={""}
              getOptionLabel={(option) => option?.email}
              style={{ width: 300, marginLeft: 30 }}
              onChange={(e, v) => {
                handleuseremail2(v);
                handleMiguseradminlist(v);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Email"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={useradminmiglist}
              groupBy={""}
              // defaultValue={{
              //   Migration_Name: DropDownList[0]?.Migration_Name,
              // }}
              getOptionLabel={(option) => option?.Migration_Name}
              style={{ width: 300 }}
              onChange={(e, v) => {
                setMigtype_create(v?.Migration_Name);
                migration_id(v?.Migration_Id);
                // handlePearentobjecttypes(v?.Migration_Name);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="MigrationTypes"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          {/* <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={parentdropDownList}
              groupBy={""}
              style={{ width: 300, marginLeft: 30 }}
              getOptionLabel={(option) => option?.Parent_Object}
              onChange={(e, v) => setParentObject(v?.Parent_Object)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Parent Object Type"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid> */}
        </Grid>
      </Box>

      <Grid>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            component="span"
            style={{ marginTop: 19 }}
            onClick={() => handlemigrationadmincreation()}
          >
            {" "}
            Create Admin
          </Button>
        </Grid>
      </Grid>

      <Box py={2} px={2}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
            >
              Migration Admin List
            </Typography>
            {loading_malist ? (
              <>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      <StyledTableCell align="center">
                        Migration Name
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        User Email
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">Object Types</StyledTableCell> */}
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isMigAdminData ? (
                      <>
                        {migAdminList.map((item) => (
                          <StyledTableRow container>
                            {item?.Object_Types.includes("ALL") ? (
                              <>
                                <StyledTableCell item xl={8} align="center">
                                  {/* <div className={classes.texttablecell}> */}
                                  {item.Migration_Name}
                                  {/* </div> */}
                                </StyledTableCell>
                                <StyledTableCell item xl={8} align="center">
                                  {/* <div className={classes.texttablecell}> */}
                                  {item.Email}
                                  {/* </div> */}
                                </StyledTableCell>

                                <StyledTableCell item xl={8} align="center">
                                  <Button
                                    type="button"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    style={{
                                      marginTop: "9px",
                                      fontSize: "9px",
                                      marginBottom: "8px",
                                    }}
                                    onClick={() => {
                                      handleModelopen(item);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </StyledTableCell>
                              </>
                            ) : null}
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <StyledTableRow container>
                          <StyledTableCell align="center"></StyledTableCell>
                          <StyledTableCell align="left">
                            No Requests
                          </StyledTableCell>
                          <StyledTableCell align="center"></StyledTableCell>
                          {/* <StyledTableCell align="center"></StyledTableCell> */}
                        </StyledTableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </>
            ) : (
              <>
                {" "}
                <center>
                  <CircularProgress color="primary" style={{ marginTop: 15 }} />
                </center>
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      ></Snackbar>
      <Modal open={openrm_admin}>
        <Container className={classes.container1}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            component="h2"
            className={classes.Object_Type}
            style={{ marginBottom: "20px" }}
          >
            Admin Access
          </Typography>
          <Grid item xs={4}>
            <TextField
              id="outlined-multiline-static"
              label="email"
              name="email"
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={rm_admin_email}
              size="small"
              disabled
              style={{ width: 400, marginBottom: "20px", height: "60px" }}
            />
          </Grid>

          <TextField
            id="outlined-multiline-static"
            label="Migration Type"
            name="migrationtype"
            className={classes.textField}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={rm_admin_mig}
            size="small"
            disabled
            style={{ width: 400, marginBottom: "20px", height: "60px" }}
          />

          {/* <Grid item xs={4}>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={rm_admin_objectslist}
              groupBy={""}
              // defaultValue={{ title: "Oracle TO Postgres" }}
              // value={objecttype_rm}
              getOptionLabel={(option) => option.Object_Type}
              style={{ width: 400, marginBottom: "20px", height: "60px" }}
              onChange={(e, v) => setObjecttype_rm_admin(v?.Object_Type)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Object types"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid> */}
          <div className={classes.item}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 20, marginLeft: 100 }}
              onClick={() => handleRm_migadmin()}
            >
              Remove
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenrm_admin(false)}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </Modal>

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Migration Type Creation</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={DropDownList}
              groupBy={""}
              defaultValue={{ Migration_Name: DropDownList[0]?.Migration_Name }}
              getOptionLabel={(option) => option?.Migration_Name}
              style={{ width: 300 }}
              onChange={(e, v) => {
                setMigtype_create(v?.Migration_Name);
                migration_id(v?.Migration_Id);
                handleGetObjecttypesList(v?.Migration_Name);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Migration type"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            {/* <Avatar className={classes.avatar}> */}
            {/* <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpen1(true)}
              >
                
              </Button> */}
            <Button
              variant="contained"
              color="primary"
              component="span"
              size="small"
              style={{ marginTop: 3 }}
              onClick={() => setOpen1(true)}
            >
              Create Migration
            </Button>
            {/* <AddIcon style={{ color: "green" }} /> */}
            {/* </Avatar> */}
          </Grid>
          <Snackbar
            open={openAlert}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          ></Snackbar>
          <Modal open={open1}>
            <Container className={classes.container}>
              <Typography
                gutterBottom
                align="center"
                variant="h6"
                component="h2"
                className={classes.Object_Type}
                style={{ marginBottom: "20px" }}
              >
                Create Migration Type
              </Typography>
              {/* <form className={classes.form} autoComplete="off"> */}
              <div className={classes.item}>
                <TextField
                  id="outlined-multiline-static"
                  label="Migration Type"
                  style={{ width: 410, marginBottom: "20px" }}
                  multiline
                  rows={1}
                  // value ={row.Keywords}
                  onChange={(e) => setMigtype_create(e.target.value)}
                  name="Migration_Name"
                  // defaultValue={edithandle.Keywords}
                  // helperText={featurenamemsg}
                  // value={edithandle.Keywords}
                  className={classes.textField}
                  // helperText="Some important text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.item}>
                <TextField
                  id="outlined-multiline-static"
                  label="Major Version"
                  style={{ width: 410, marginBottom: "20px" }}
                  multiline
                  rows={1}
                  onChange={(e) => setProject_max_limit(e.target.value)}
                  name="Major Version"
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.item}>
                <TextField
                  id="outlined-multiline-static"
                  label="Minor Version"
                  style={{ width: 410, marginBottom: "10px" }}
                  multiline
                  rows={1}
                  onChange={(e) => setFeautre_max_limit(e.target.value)}
                  name="Minor Version"
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <h4>
                Note:Major version and Minor version should not be 0 and 1
              </h4>
              <div className={classes.item}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 20, marginLeft: 100 }}
                  onClick={() => handleMigrationCreate()}
                >
                  Create
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setOpen1(false)}
                >
                  Cancel
                </Button>
              </div>
            </Container>
          </Modal>
        </Grid>
      </Box>

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">
              New Migration Creation Based on Old Migration
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={DropDownList}
              groupBy={""}
              // defaultValue={{ title: "Oracle TO Postgres" }}
              getOptionLabel={(option) => option.Migration_Name}
              style={{ width: 300 }}
              onChange={(e, v) => {
                setNew_prj_versionitem(v?.Migration_Name);
                migration_id(v?.Migration_Id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Old Migration Name"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="New Migration Name"
              multiline
              rows={1}
              onChange={(e) => setNewMigtype(e.target.value)}
              name="NewMigType"
              // defaultValue="Default Value"
              // helperText={featurenamemsg}
              // className={classes.textField}
              // helperText="Some important text"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              // multiline
              fullWidth
              style={{ width: 300, marginBottom: "20px", height: "50px" }}
            />
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              // disabled={!selecetd1}
              color="primary"
              component="span"
              size="small"
              // style={{ marginTop: 10, marginLeft: 240 }}
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title:
                    "Do You Want Create New Migration Based on Old Migration ?",
                  onConfirm: () => {
                    handleNewVersionMigration(
                      new_prj_versionitem,
                      newMigtype,
                      mig_id
                    );
                  },
                });
              }}
              // onClick={() =>
              //   handleNewVersionMigration(new_prj_versionitem, newMigtype)
              // }
            >
              {" "}
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Project Version Creation</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box py={2} px={2} style={{ marginBottom: 10 }}>
        <Grid container direction="row" justifyContent="center" xs={12}>
          
          <Grid item xs={5}>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={DropDownList}
              groupBy={""}
              // defaultValue={{ title: "Oracle TO Postgres" }}
              getOptionLabel={(option) => option.Migration_Name}
              style={{ width: 300 }}
              onChange={(e, v) => {
                setSelect_prj_versionitem(v?.Migration_Name);
                migration_id(v?.Migration_Id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Migration Name"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={2}>
            <Button
              variant="contained"
              // disabled={!selecetd1}
              color="primary"
              component="span"
              size="small"
              style={{ marginTop: 5 }}
              // onClick={() => handleCreateNewVersion(select_prj_versionitem)}
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Do You Want Create New Project Version?",
                  onConfirm: () => {
                    handleCreateNewVersion(select_prj_versionitem);
                  },
                });
              }}
            >
              {" "}
              Create
            </Button>
          </Grid>
        </Grid>
      </Box> */}

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Delete Migration</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={DropDownList}
              groupBy={""}
              // defaultValue={{ title: "Oracle TO Postgres" }}
              getOptionLabel={(option) => option.Migration_Name}
              style={{ width: 300 }}
              onChange={(e, v) => setDeletemig(v?.Migration_Name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Migration type"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              // disabled={!selecetd1}
              color="primary"
              component="span"
              size="small"
              style={{ marginTop: 5, backgroundColor: "red" }}
              // onClick={() => handleDeleteMig(deletemig)}
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Do You Want Remove the Migration?",
                  onConfirm: () => {
                    handleDeleteMig(deletemig); // call the function
                  },
                });
              }}
            >
              {" "}
              Delete
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">
              Migrations Object-types Deletion
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={4}>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={DropDownList}
              groupBy={""}
              defaultValue={{ Migration_Name: DropDownList[0]?.Migration_Name }}
              getOptionLabel={(option) => option?.Migration_Name}
              style={{ width: 300 }}
              onChange={(e, v) => {
                setMig_subdelete(v?.Migration_Name);
                handleGetObjecttypesList(v?.Migration_Name);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Migration type"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={list_objects}
              groupBy={""}
             
              getOptionLabel={(option) => option?.Object_Type}
              onChange={(e, v) => {
                handleTreeStructureselected(v);
                clearPath();
              }}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="ObjectType"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>

      <Box py={3} px={3}>
        <Grid container direction="row" justifyContent="center">
          <TreeView
            className={classes.root}
            defaultExpanded={["3"]}
            // expanded={true}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 24 }} />}
            sx={{
              height: 264,
              flexGrow: 1,
              maxWidth: 400,
              overflowY: "auto",
            }}
          >
            <>{RenderTree(selectedItem)}</>
          </TreeView>
        </Grid>
      </Box>

      <Box py={4} px={4}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          style={{ position: "relative" }}
          spacing={2}
        >
          <Grid spacing={3}>
            <>
              <TextField
                id="outlined-multiline-static"
                label="Selected Path"
                size="small"
                style={{ width: 350, marginTop: 8 }}
                multiline
                rows={2}
                
                name="Selected Path"
                value={path}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />{" "}
              
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginTop: 20 }}
                onClick={() => clearPath()}
              >
                Clear
              </Button>{" "}
              <Button
                variant="contained"
               
                color="primary"
                component="span"
                size="small"
                style={{ marginTop: 20, backgroundColor: "red" }}
                onClick={(e) => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Do You Want Remove the Object Type?",
                    onConfirm: () => {
                      handleDeleteSuObjecttype(mig_subdelete, path);
                    },
                  });
                }}
              >
                {" "}
                Delete
              </Button>
            </>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          style={{ position: "relative", marginBottom: 20 }}
          spacing={2}
        >
          <Grid spacing={3}>
            
          </Grid>
        </Grid>
      </Box>

   
      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">
              Conversion Files Encryption in Azure
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginTop: 15 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          style={{ position: "relative" }}
          spacing={2}
        >
          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={DropDownList}
              groupBy={""}
              
              getOptionLabel={(option) => option.Migration_Name}
              style={{ width: 300 }}
              onChange={(e, v) => {
                setAzure_mig_name(v?.Migration_Name);
                setAzure_mig_id(v?.Migration_Id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Migration Name"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />{" "}
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginTop: 5 }}
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Do You Want Create New Key?",
                  onConfirm: () => {
                    handleAzure_key_encryption(azure_mig_name,azure_mig_id);
                  },
                });
              }}
            >
              Generate New key
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginBottom: 70 }}>
        <Grid container direction="row " justifyContent="center" spacing={2}>
          <h4 style={{ color: "red" }}>
            Note: When you click on Generate New Key button, it will encrypt
            the Conversion files with New Generated Key.
          </h4>
        </Grid>
      </Box> */}
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <></>
    </Box>
  );
}

function StyledTreeItem(props) {
  const history = useHistory();
  const classes = useTreeItemStyles();
  const dispatch = useDispatch();
  const IsSuperAdmin = sessionStorage.getItem("isSuperAdmin");
  // const [confirmDialog, setConfirmDialog] = useState({isOpen: false, title: '', subTitle: '' })
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    mainheader,
    data,
    // deleteitem,
    datavalue,
    sub,
    admin,
    createflag,
    ...other
  } = props;

  return (
    <>
      <TreeItem
        // icon={ViewModuleIcon}
        label={
          <div className={classes.labelRoot}>
            {/* <ViewModuleIcon color="inherit" className={classes.labelIcon} /> */}
            <Typography
              variant="body2"
              className={classes.labelText}
              style={{ color: "white" }}
            >
              {labelText}
            </Typography>

            <Typography
              variant="caption"
              color="inherit"
              style={{ color: "white" }}
            >
              {labelInfo}
            </Typography>
          </div>
        }
        style={{
          "--tree-view-color": color,
          "--tree-view-bg-color": bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
    </>
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: "#0A7D7F",
      color: "",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));
