import {
  Box,
  Grid,
  TextField,
  Typography,
  styled,
  Tooltip,
} from "@material-ui/core";
import SearchBar from "material-ui-search-bar";
import CircularProgress from "@material-ui/core/CircularProgress";
import ConfirmDialog from "../../Features/Notifications/ConfirmDialog";
import RefreshIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
// import { format } from 'date-fns';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import MenuAppBar from "../../Components/header";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import config from "../../Config/config";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import { TableContainer } from "@material-ui/core";
import Notification from "../Notifications/Notification";

import { Container, Modal, Snackbar } from "@material-ui/core";
import { LocalActivity } from "@material-ui/icons";

const useStylestable = makeStyles((theme) => ({
  table: {
    // minWidth: 650
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  searchtable: {
    width: "30%",
    marginLeft: "auto",
    marginRight: "auto",
    height: "15%",
    alignItems: "flex-end",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 300,
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  Accesslistcontainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "120px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },

  texttablecell: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      overflow: "visible",
    },
  },
  buttton: {
    height: 10,
  },

  table: {
    // minWidth: 150,
    width: "60%",
    height: "10%",
    border: "1px black",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  rootc: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  container1: {
    border: "none",
    borderRadius: 15,
    width: 380,
    height: 250,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  root: {
    padding: "0px 8px",
  },

  body: {
    fontSize: 13,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // height: 9,
  },
}))(TableRow);

export default function AdminAccesslist() {
  const classes = useStyles();
  const {
    details,
    createFeature,
    preview,
    editpreview,
    editPreviewdetails,
    headerValue,
    lable,
    project_version,
    mig_id,
  } = useSelector((state) => state.dashboardReducer);
  const classestable = useStylestable();
  const [isData, setIsData] = useState(false);
  const [loading_aalist, setLoading_aalist] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlert2, setOpenAlert2] = useState(false);
  const [objtype, setObjtype] = useState();
  const [fnnames, setFnnames] = useState([]);
  const [data, setData] = useState([]);
  const [isEdit, setEdit] = React.useState(false);
  const [isEditaccess, setEditaccess] = React.useState(false);
  const [date, setDate] = useState();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedDateTable, setdateValue] = useState();
  const [objtypelist, setObjtypeslist] = useState([]);
  const [userslist, setUserslist] = useState([]);
  const [approvalslist, setApprovallist] = useState([]);
  const [selecetd, setSelected] = useState(false);
  const [permissionslist, setpermissionslist] = useState([]);
  const [fnname, setFnname] = useState();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [objcount, setobjcount] = useState(0);
  const [updatetable, setupdateTable] = useState(false);
  const [accesschnage, setaccesschange] = useState();
  // const [accesstypeslist, setAccesstypeslist] = useState([
  //   { title: "Edit", code: 'Edit' },
  //   { title: "View", code: 'View' },
  // ])
  const [selectedItem, setSelectedItem] = useState({});
  const [isselectedItem, setisSelectedItem] = useState(false);
  const [path, setPath] = useState();
  // const [grant_mig_type, setGrant_mig_type]= useState()
  const [grant_obj_type, setGrant_obj_type] = useState();
  const [grant_access_type, setGrant_access_type] = useState();
  const [grant_user, setGrant_user] = useState();
  const [grant_featurename, setGrant_featurename] = useState("");
  // const [grant_expiry_date, setGrant_expiry_date]= useState()
  const [edithandle, setEdithandle] = useState([]);
  const [model_Item, setModel_Item] = useState([]);
  const [model_Item1, setModel_Item1] = useState([]);
  const [list_features, setList_of_features] = useState([]);
  const [feature_Path, setFeature_Path] = useState();
  const [object_Id, setObject_Id] = useState();
  const [approvalsRefresh, setApprovalsRefresh] = useState(false);

  const [migtypeid, setMigtypeid] = useState(headerValue?.Migration_Name);
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");

  useEffect(() => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios.get(`${config.API_BASE_URL()}/api/users_list/`, conf).then(
      (res) => {
        setUserslist(res.data);
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
      }
    );
  }, []);

  useEffect(() => {
    if (headerValue) {
      if (Object.keys(headerValue).length > 0) {
        let body = {
          Migration_Name: headerValue?.Migration_Name,
          Parent_Object_Name: lable,
          // "Project_Version_Id": project_version
          User_Email: sessionStorage.getItem("uemail"),
        };
        let conf = {
          headers: {
            Authorization: "Bearer " + config.ACCESS_TOKEN(),
          },
        };
        const form = new FormData();
        Object.keys(body).forEach((key) => {
          form.append(key, body[key]);
        });
        axios
          .post(
            `${config.API_BASE_URL()}/api/approval_requests_list/`,
            form,
            conf
          )
          .then(
            (res) => {
              if (res.data.length > 0) {
                setApprovallist(res.data);
                setRows(res.data);
                setIsData(true);
              }
              setLoading_aalist(true);
            },
            (error) => {
              setNotify({
                isOpen: true,
                message: "Something Went Wrong Please try Again",
                type: "error",
              });
              setLoading_aalist(false);
            }
          );
      }
    }
  }, [updatetable, headerValue, lable, approvalsRefresh]);

  // Function to handle edit
  const handleEdit = (i) => {
    // If edit mode is true setEdit will
    // set it to false and vice versa
    setEdit(!isEdit);
  };

  const handleEditaccess = (i) => {
    // If edit mode is true setEdit will
    // set it to false and vice versa
    setEditaccess(!isEditaccess);
  };

  const handleSaveDate = () => {};

  const handleaccess = () => {
    // setEditaccess(!isEditaccess);
  };

  const handledatedesible = () => {
    setSelected(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert2(false);
  };

  // useEffect(() => {
  //   let sval = 0;
  //   // if (headerValue) {
  //   //   if (headerValue?.Migration_Name === "Oracle TO Postgres") {
  //   //     sval = 1;
  //   //   } else if (headerValue?.Migration_Name === "SQLServer TO Postgres") {
  //   //     sval = 2;
  //   //   } else if (headerValue?.Migration_Name === "MYSQL TO Postgres") {
  //   //     sval = 3;
  //   //   }
  //   // }

  //   if (objtype === "ALL") {
  //     setFnnames([{ Feature_Name: "ALL" }]);
  //   } else if (objcount === 0) {
  //     setFnnames([]);
  //   } else {
  //     let body = {
  //       Object_Type: objtype,
  //       Migration_TypeId: headerValue?.Migration_Name,
  //       Feature_Name: grant_featurename,
  //       Project_Version_Id: project_version,
  //     };
  //     let conf = {
  //       headers: {
  //         Authorization: "Bearer " + config.ACCESS_TOKEN(),
  //       },
  //     };
  //     const form = new FormData();
  //     Object.keys(body).forEach((key) => {
  //       form.append(key, body[key]);
  //     });
  //     axios
  //       .post(`${config.API_BASE_URL()}/api/requestfndata/`, form, conf)
  //       .then(
  //         (res) => {
  //           // setFnnames(res.data);
  //           // console.log(res.data);
  //           setFnnames([{ Feature_Name: "ALL" }].concat(res.data));
  //         },
  //         (error) => {
  //           setNotify({
  //             isOpen: true,
  //             message: "Something Went Wrong Please try Again",
  //             type: "error",
  //           });
  //         }
  //       );
  //   }
  // }, []);

  const handleObjecttype = (v) => {
    setObjtype(v?.Object_Type);
    setGrant_obj_type(v?.Object_Type);
    let body = {
      Object_Type: v?.Object_Type,
      Migration_TypeId: headerValue?.Migration_Name,
      Project_Version_Id: project_version,
    };
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    if (objcount === 0) {
      setFnnames([]);
    } else if (v?.Object_Type === "ALL") {
      setFnnames([{ Feature_Name: "ALL" }]);
    } else {
      axios
        .post(`${config.API_BASE_URL()}/api/requestfndata/`, form, conf)
        .then(
          (res) => {
            // setFnnames(res.data);
            if (res.data.length > 0) {
              setFnnames([{ Feature_Name: "ALL" }].concat(res.data));
            } else {
              setFnnames([]);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  };

  const handledropdown = (e, v) => {
    setGrant_featurename(v?.Feature_Name);
    if (v?.Feature_Name === "All_Features") {
      setFeature_Path(path + "/" + "F-ALL");
    } else if (v?.Feature_Name === "All_Sub_Objects") {
      setFeature_Path(path + "/" + "ALL");
    } else {
      setFeature_Path(path + "/" + v?.Feature_Name);
    }
  };

  useEffect(() => {
    if (headerValue?.Migration_Name && project_version) {
      if (Object.keys(headerValue).length > 0) {
        let conf = {
          headers: {
            Authorization: "Bearer " + config.ACCESS_TOKEN(),
          },
        };
        let body = {
          Migration_Name: headerValue?.Migration_Name,
          // "Object_Type": lable,
          // "User_Email": sessionStorage.getItem('uemail'),
          Project_Version_Id: project_version,
          User_Email: sessionStorage.getItem("uemail"),
        };

        const form = new FormData();
        Object.keys(body).forEach((key) => {
          form.append(key, body[key]);
        });
        axios
          .post(
            `${config.API_BASE_URL()}/api/object_types_format_adminaccess/`,
            form,
            conf
          )
          .then(
            (res) => {
              if (res.data.length > 0) {
                setObjtypeslist(res.data);
                setobjcount(1);
              }
            },
            (error) => {
              setNotify({
                isOpen: true,
                message: "Something Went Wrong Please try Again",
                type: "error",
              });
            }
          );
      }
    }
  }, [headerValue?.Migration_Name, lable, project_version]);

  const handleversion = (v) => {
    setGrant_access_type(v?.title);
  };
  const handleUsername = (v) => {
    setGrant_user(v?.email);
  };

  const handleDate = (e) => {
    setData(e.target.value);
  };

  const handleRequestAccessApproved = (item, action) => {
    const form = new FormData();
    let body = {
      User_Email: item.User_Email,
      Access_Type: item.Access_Type,
      Expiry_date: item.Expiry_date,
      Migration_Name: item.Migration_Name,
      // Object_Type: item.Object_Type,
      // Feature_Name: item.Feature_Name,
      Created_at: item.Created_at,
      Approval_Request: item.Approval_Request,
      Approval_Status: action,
      // id: item.id,
      Approved_by: item.Approved_by,
      Migration_Id: item.Migration_Id,
      // Project_Version_Id: 1,
    };

    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    axios
      .put(
        `${config.API_BASE_URL()}/api/approval_request_update/${item.id}/`,
        form,
        conf
      )
      .then(
        (res) => {
          // setOpen1(false);
          setupdateTable(true);
        },
        (error) => {
          // setOpen1(false);
        }
      );
    setupdateTable(false);
    let body1_perm_create = {
      Migration_Name: headerValue?.Migration_Name,
      User_Email: item.User_Email,
      // Feature_Name: ,
      Access_Type: item.Access_Type,
      Approval_Str: item.Approval_Request,
      // Project_Version_Id: project_version,
      Migration_Id: item.Migration_Id,
    };

    const form1 = new FormData();
    Object.keys(body1_perm_create).forEach((key) => {
      form1.append(key, body1_perm_create[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/permission_create/`, form1, conf)
      .then(
        (res) => {
          // setupdateTable(true);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went Wrong",
            type: "error",
          });
        }
      );
  };

  const handleRequestAccessDeny = (item, action) => {
    if (item.Expiry_date === null) {
      item.Expiry_date = moment().format("YYYY-MM-DD");
    }
    if (item.Approved_by === null) {
      item.Approved_by = sessionStorage.getItem("uemail");
    }
    const form = new FormData();
    let body = {
      User_Email: item.User_Email,
      Access_Type: item.Access_Type,
      Expiry_date: item.Expiry_date,
      Migration_Name: item.Migration_Name,
      // Object_Type: item.Object_Type,
      // Feature_Name: item.Feature_Name,
      Created_at: item.Created_at,
      Approval_Request: item.Approval_Request,
      Approval_Status: action,
      // id: item.id,
      Approved_by: item.Approved_by,
      // Project_Version_Id: 1,
    };

    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    axios
      .put(
        `${config.API_BASE_URL()}/api/approval_request_update/${item.id}/`,
        form,
        conf
      )
      .then(
        (res) => {
          // setOpen1(false);
          setupdateTable(true);
        },
        (error) => {
          // setOpen1(false);
        }
      );
    setupdateTable(false);
  };

  const handleUpdateApproval = (
    selectedDateTable,
    accesschnage,
    item,
    action
  ) => {
    const form = new FormData();
    let Expiry_date;

    Expiry_date = moment(selectedDateTable).format("YYYY-MM-DD");

    let body = {
      User_Email: item.User_Email,
      Access_Type: accesschnage,
      Expiry_date: Expiry_date,
      Migration_Name: item.Migration_Name,
      // Object_Type: item.Object_Type,
      // Feature_Name: item.Feature_Name,
      Created_at: item.Created_at,
      Approval_Request: item.Approval_Request,
      Approval_Status: action,
      // id: item.id,
      Approved_by: sessionStorage.getItem("uemail"),
      // Project_Version_Id: 1,
    };

    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    axios
      .put(
        `${config.API_BASE_URL()}/api/approval_request_update/${item.id}/`,
        form,
        conf
      )
      .then(
        (res) => {
          setOpen1(false);
          setupdateTable(true);
        },
        (error) => {
          setOpen1(false);
        }
      );
    setupdateTable(false);
  };

  const handleUpdateApproval_expiry = (item, exp_date) => {
    console.log(item);
    const form = new FormData();
    let Expiry_date;

    Expiry_date = moment(selectedDateTable).format("YYYY-MM-DD");

    let body = {
      User_Email: item.User_Email,
      Access_Type: item.Access_Type,
      Expiry_date: exp_date,
      Migration_Name: item.Migration_Name,
      Created_at: item.Created_at,
      Approval_Request: item.Approval_Request,
      Approval_Status: item.Approval_Status,
      // id: item.id,
      Approved_by: sessionStorage.getItem("uemail"),
      // Project_Version_Id: 1,
    };

    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    axios
      .put(
        `${config.API_BASE_URL()}/api/approval_request_update_expirymodify/${
          item.id
        }/`,
        form,
        conf
      )
      .then(
        (res) => {
          setOpen2(false);
          setupdateTable(true);
          setNotify({
            isOpen: true,
            message: "Modified Expiry Date and Permissions",
            type: "success",
          });
        },
        (error) => {
          setOpen2(false);
        }
      );
    setupdateTable(false);
  };

  const handleEditAcesschange = (e) => {
    setaccesschange(e.target.value);
  };

  // const handleGrant_permission_create = (item, action) => {
  //   const form = new FormData();

  //   let body = {
  //     User_Email: item.User_Email,
  //     Migration_TypeId: item.Migration_TypeId,
  //     Object_Type: item.Object_Type,
  //     Feature_Name: item.Feature_Name,
  //     Access_Type: item.Access_Type,
  //     Created_at: item.Created_at,
  //     Expiry_date: moment(item.Expiry_date).format("YYYY-MM-DD"),
  //     Approval_Status: action,
  //     Approved_by: sessionStorage.getItem("uemail"),
  //     Project_Version_Id: 1,
  //   };
  //   Object.keys(body).forEach((key) => {
  //     form.append(key, body[key]);
  //   });

  //   let conf = {
  //     headers: {
  //       Authorization: "Bearer " + config.ACCESS_TOKEN(),
  //     },
  //   };

  //   axios
  //     .post(`${config.API_BASE_URL()}/api/permissionscreate/`, form, conf)
  //     .then(
  //       (res) => {
  //         if (res.data === "User already has permission") {
  //           setNotify({
  //             isOpen: true,
  //             message: res.data,
  //             type: "error",
  //           });
  //         } else {
  //           setNotify({
  //             isOpen: true,
  //             message: "Request Creted and acepted Permissions",
  //             type: "success",
  //           });
  //         }
  //         // handleUpdateApproval(res.data.Expiry_date, res.data.Created_at, item, action)
  //       },

  //       (error) => {
  //         setNotify({
  //           isOpen: true,
  //           message: "Something Went Wrong Please try Again",
  //           type: "error",
  //         });
  //       }
  //     );
  // };

  const handleGrantAcess = (app_str) => {
    let body1_perm_create = {
      Migration_Name: headerValue?.Migration_Name,
      User_Email: grant_user,
      Feature_Name: grant_featurename,
      Access_Type: grant_access_type,
      Approval_Str: app_str,
      Migration_Id: mig_id,
      // Project_Version_Id: project_version,
    };
    // console.log(body1_perm_create, "perm")
    let body2_approval_create = {
      Migration_Name: headerValue?.Migration_Name,
      User_Email: grant_user,
      Approval_Request: app_str,
      Access_Type: grant_access_type,
      Approval_Status: "Approved",
      Expiry_date: moment(selectedDate).format("YYYY-MM-DD"),
      Approved_by: sessionStorage.getItem("uemail"),
      Migration_Id: mig_id,
    };
    // console.log(body2_approval_create, "approval");
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    const form = new FormData();
    Object.keys(body1_perm_create).forEach((key) => {
      form.append(key, body1_perm_create[key]);
    });
    const form1 = new FormData();
    Object.keys(body2_approval_create).forEach((key) => {
      form1.append(key, body2_approval_create[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/permission_create/`, form, conf)
      .then(
        (res) => {
          axios
            .post(
              `${config.API_BASE_URL()}/api/approval_request_create/`,
              form1,
              conf
            )
            .then(
              (res) => {
                if (
                  res.data ===
                  "Approval Request Already Present OR Please Wait For Admin To Approve"
                ) {
                  setNotify({
                    isOpen: true,
                    message:
                      "Approval Request Already Present OR Please Wait For Admin To Approve",
                    type: "info",
                  });
                } else if (res.data === "Already Having Access") {
                  setNotify({
                    isOpen: true,
                    message: res.data,
                    type: "info",
                  });
                } else {
                  setupdateTable(true);
                  setNotify({
                    isOpen: true,
                    message: "Acesss Granted",
                    type: "success",
                  });
                }
              },
              (error) => {
                setNotify({
                  isOpen: true,
                  message: "Something went Wrong",
                  type: "error",
                });
              }
            );
          setupdateTable(false);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went Wrong",
            type: "error",
          });
        }
      );
  };

  const handleModelopen = (item) => {
    // debugger
    setOpen1(true);
    setModel_Item(item);
  };
  const handleModelopen1 = (item) => {
    // debugger
    setOpen2(true);
    setModel_Item1(item);
  };
  const handleChangeDate = (date) => {
    date = moment(date).format("YYYY-MM-DD");
    setModel_Item({
      ...model_Item,
      Expiry_date: date,
    });
  };
  const handleChangeDate1 = (date) => {
    date = moment(date).format("YYYY-MM-DD");
    setModel_Item1({
      ...model_Item1,
      Expiry_date: date,
    });
  };

  const handleSelectgroup = (value) => {
    setModel_Item({
      ...model_Item,
      Access_Type: value,
    });
  };

  const handleTreeItemClick = (obj, obj_id) => {
    if (path) {
      let mod_str = path.split("/");
      let identified_str = mod_str.pop();
      // console.log(identified_str)
      // console.log(identified_str, '===', obj)
      if (identified_str === obj) {
        setPath(path);
      } else {
        setPath(path + "/" + obj);
      }
    } else {
      setPath(obj);
    }

    setObject_Id(obj_id);
    // console.log(path, ' path')
  };

  const handleTreeStructureselected = (v) => {
    setSelectedItem(v);
    setisSelectedItem(true);
  };

  const clearPath = () => {
    setPath("");
    setList_of_features([]);
  };

  const call_Features = (path) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    // console.log(project_version, " project versin");
    let body = {
      Project_Version_Id: project_version,
      Migration_Name: headerValue?.Migration_Name,
      // Object_Id: object_Id,
      Object_Path_Str: path,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios.post(`${config.API_BASE_URL()}/api/features_list/`, form, conf).then(
      (res) => {
        if (res.data === "Object path does not exist") {
          setNotify({
            isOpen: true,
            message: "Selected Object Path Doesn't Exist",
            type: "error",
          });
        } else if (res.data.length > 0) {
          setList_of_features(res.data);
        } else {
          setList_of_features([]);
        }
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something went wrong Please try Again",
          type: "error",
        });
      }
    );
  };
  const RenderTree = (nodes) => {
    // console.log("selection path ", );
    // const history = useHistory();
    return (
      <TreeItem
        key={nodes?.Object_Id}
        nodeId={nodes?.Object_Id}
        onLabelClick={() => {
          handleTreeItemClick(nodes?.Object_Type, nodes?.Object_Id);
        }}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "black", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_Type}
            </Typography>
          </div>
        }
      >
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Pre") {
                return RenderTree(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type !== "Pre" || node?.Object_Type !== "Post") {
                return RenderTree(node, 0);
              }
            })
          : null} */}

        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              console.log(node?.Object_Type);
              if (node?.Object_Type === "Post" || node?.Object_Type === "Pre") {
              } else {
                return RenderTree(node, 0);
              }
            })
          : null}
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Post") {
                return RenderTree(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              RenderTree(node, 0);
            })
          : null} */}
      </TreeItem>
    );
  };

  const handleRemove = (data) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let bodyrm = {
      Migration_Name: data.Migration_Name,
      User_Email: data.User_Email,
      Approval_Request: data.Approval_Request,
      Access_Type: data.Access_Type,
      Approval_Status: data.Approval_Status,
    };
    const formrm = new FormData();
    Object.keys(bodyrm).forEach((key) => {
      formrm.append(key, bodyrm[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/approvals_delete/`, formrm, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "Permmission Deleted Successfully",
            type: "success",
          });
          setupdateTable(true);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setupdateTable(false);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = approvalslist.filter((row) => {
      return row.User_Email.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  return (
    <div className={classes.Accesslistcontainer}>
      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Admin Access & Roles</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="Migration Type"
              // onChange={(e) => handleChange(e)}
              name="MigrationType_Id"
              // defaultValue="Default Value"
              // helperText={featurenamemsg}
              className={classes.textField}
              // helperText="Some important text"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={headerValue?.Migration_Name}
              size="small"
              disabled
              style={{ width: 300 }}
            />
          </Grid>
          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={objtypelist}
              groupBy={""}
              // defaultValue={{ title: "Procedure" }}
              getOptionLabel={(option) => option.Object_Type}
              style={{ width: 300 }}
              onChange={(e, v) => {
                handleTreeStructureselected(v);
                clearPath();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="ObjectType"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <TreeView
              className={classes.root}
              defaultExpanded={["3"]}
              // expanded={true}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 24 }} />}
              sx={{
                height: 264,
                flexGrow: 1,
                maxWidth: 400,
                overflowY: "auto",
              }}
            >
              {isselectedItem ? <>{RenderTree(selectedItem)}</> : null}
              {/* {} */}
            </TreeView>
          </Grid>
        </Grid>
      </Box>
      {isselectedItem ? (
        <>
          <Box py={2} px={2}>
            <Grid container direction="row" justifyContent="center" spacing={1}>
              <Grid item>
                <TextField
                  id="outlined-multiline-static"
                  label="Selected Path"
                  size="small"
                  style={{ width: 300 }}
                  multiline
                  rows={1}
                  // onChange={(e) => handlePath()}
                  name="Selected Path"
                  value={path}
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ marginTop: 6 }}
                  onClick={() => clearPath()}
                >
                  Clear
                </Button>
                {"  "}
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ marginTop: 6 }}
                  onClick={() => call_Features(path)}
                >
                  Get Features
                </Button>
              </Grid>
              <Grid item>
                <StyledAutocomplete
                  size="small"
                  id="grouped-demo"
                  className={classes.inputRoottype}
                  options={list_features}
                  groupBy={""}
                  // defaultValue={{ title: "Edit" }}
                  getOptionLabel={(option) => option.Feature_Name}
                  style={{ width: 300 }}
                  onChange={(e, v) => handledropdown(e, v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Feature Names"
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box py={2} px={2}>
            <Grid container direction="row" justifyContent="center" spacing={1}>
              <Grid item>
                <StyledAutocomplete
                  size="small"
                  id="grouped-demo"
                  className={classes.inputRoottype}
                  options={[
                    { title: "Edit", code: "Edit" },
                    { title: "View", code: "View" },
                    // { title: "ALL", code: "ALL" },
                  ]}
                  groupBy={""}
                  // defaultValue={{ title: "Edit" }}
                  getOptionLabel={(option) => option?.title}
                  style={{ width: 280, marginTop: 10 }}
                  onChange={(e, v) => handleversion(v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Accesstype"
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <StyledAutocomplete
                  size="small"
                  id="grouped-demo"
                  className={classes.inputRoottype}
                  options={userslist}
                  groupBy={""}
                  // defaultValue={{ title: "Select email" }}
                  getOptionLabel={(option) => option.email}
                  style={{ width: 280, marginTop: 10 }}
                  onChange={(e, v) => handleUsername(v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="ID"
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    // disablePast
                    size="small"
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    label="Expiry Date"
                    style={{ width: 280, marginTop: "10px" }}
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}
      <Box>
        <Grid container direction="row" justifyContent="center">
          <Button
            variant="contained"
            // disabled={!selecetd}
            // startIcon={<CloudUploadIcon />}
            color="primary"
            component="span"
            style={{ marginTop: 15 }}
            onClick={() => {
              handleGrantAcess(feature_Path);
            }}
          >
            {" "}
            Grant Access
          </Button>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container xl={12} justifyContent="space-between" spacing={2}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
            >
              Approval Requests{" "}
              <Button
                // size="small"
                type="submit"
                // fullWidth
                variant="inlined"
                // color="primary"
                // align='center'
                // className={classes.submit}

                // startIcon={<RefreshIcon />}
                // justifyContent='center'
                onClick={() => setApprovalsRefresh(!approvalsRefresh)}
                style={{ color: "black", backgroundColor: "#ebebeb" }}
              >
                Refresh
              </Button>
              {/* <RefreshIcon
                onClick={() => setApprovalsRefresh(!approvalsRefresh)}
                style={{ marginBottom: -5, color: "blue" }}
              /> */}
            </Typography>

            <Grid container justifyContent="flex-end">
              <SearchBar
                className={classestable.searchtable}
                // style={{width:'70%', height:40}}
                variant="outlined"
                placeholder="search by user email..."
                // type="search"
                value={searched}
                onChange={(searchVal) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
              />
            </Grid>
            {loading_aalist ? (
              <>
                <TableContainer className={classestable.table}>
                  <Table>
                    {/* <Table className={classestable.table} aria-label="simple table"> */}
                    <TableHead className={classes.primary}>
                      <TableRow>
                        <StyledTableCell align="left">
                          User Email
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Migration_Name
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Approval_Request
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Access_Type
                        </StyledTableCell>
                        {/* <StyledTableCell align="left">Approval_Status</StyledTableCell> */}
                        <StyledTableCell align="left">
                          Approved_by
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Created_at
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Expiry_date
                        </StyledTableCell>
                        <StyledTableCell align="left">Actions</StyledTableCell>
                        <StyledTableCell align="center">
                          Approval Status
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Modify Expiry
                        </StyledTableCell>
                        <StyledTableCell align="center">Delete</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isData ? (
                        <>
                          {rows.map((item) => (
                            <StyledTableRow container>
                              <StyledTableCell item xl={10}>
                                <div>{item.User_Email}</div>
                              </StyledTableCell>
                              <StyledTableCell item xl={6}>
                                <div>{item.Migration_Name}</div>
                              </StyledTableCell>
                              <StyledTableCell item xl={5}>
                                <div>{item.Approval_Request}</div>
                              </StyledTableCell>
                              <StyledTableCell item xl={6}>
                                <div>{item.Access_Type}</div>
                              </StyledTableCell>
                              {/* <StyledTableCell item xl={6}>
                            <div className={classes.texttablecell}>
                              
                              {item.Approval_Status}
                            </div>
                          </StyledTableCell> */}
                              <StyledTableCell item xl={6}>
                                <div>{item.Approved_by}</div>
                              </StyledTableCell>
                              <StyledTableCell item xl={6}>
                                <div>{item.Created_at}</div>
                              </StyledTableCell>
                              <StyledTableCell item xl={6}>
                                <div>{item.Expiry_date}</div>
                              </StyledTableCell>

                              {/* <StyledTableCell> */}
                              <StyledTableCell item xl={6}>
                                <Grid item xs={1}>
                                  {item.Approval_Status === "Pending" ? (
                                    <Tooltip
                                      title="Edit"
                                      label="Edit"
                                      aria-label="Edit"
                                      onClick={() => {
                                        handleModelopen(item);
                                      }}
                                    >
                                      <EditSharpIcon
                                        style={{ color: "blue" }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    "No Actions"
                                  )}
                                </Grid>
                              </StyledTableCell>
                              {/* </StyledTableCell> */}
                              <StyledTableCell item align="center" xl={10}>
                                {item.Approval_Status === "Pending" ? (
                                  <div className={classes.texttablecell}>
                                    <Button
                                      type="button"
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      className={classes.submit}
                                      style={{
                                        marginTop: "9px",
                                        fontSize: "9px",
                                        marginBottom: "8px",
                                      }}
                                      onClick={(e) => {
                                        handleRequestAccessApproved(
                                          item,
                                          "Approved"
                                        );
                                      }}
                                    >
                                      APPROVE
                                    </Button>{" "}
                                    <Button
                                      type="button"
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      className={classes.submit}
                                      style={{
                                        marginTop: "9px",
                                        fontSize: "9px",
                                        marginBottom: "8px",
                                      }}
                                      onClick={(e) => {
                                        handleRequestAccessDeny(item, "Denied");
                                      }}
                                    >
                                      Deny
                                    </Button>
                                  </div>
                                ) : (
                                  <div className={classes.texttablecell}>
                                    {item.Approval_Status}
                                  </div>
                                )}
                              </StyledTableCell>
                              <StyledTableCell item xl={6}>
                                <Grid item xs={1}>
                                  {item.Approval_Status === "Pending" ||
                                  item.Approval_Status === "Denied" ? (
                                    <>No Action</>
                                  ) : (
                                    <>
                                      {" "}
                                      <Tooltip
                                        title="Edit"
                                        label="Edit"
                                        aria-label="Edit"
                                        onClick={() => {
                                          handleModelopen1(item);
                                        }}
                                      >
                                        <EditSharpIcon
                                          style={{ color: "blue" }}
                                        />
                                      </Tooltip>
                                    </>
                                  )}
                                </Grid>
                              </StyledTableCell>
                              <StyledTableCell item xl={2}>
                                {/* <Box flexDirection="row"> */}
                                {/* <div className={classes.texttablecell}> */}

                                <IconButton
                                  onClick={(e) => {
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Do You Want Remove the Permission?",
                                      onConfirm: () => {
                                        handleRemove(item);
                                      },
                                    });
                                  }}
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </IconButton>
                                {/* </div> */}
                                {/* </Box> */}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      ) : (
                        <>
                          <StyledTableRow container>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center">
                              No Requests
                            </StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                          </StyledTableRow>
                        </>
                      )}
                    </TableBody>
                    {/* </Table> */}
                  </Table>
                </TableContainer>
              </>
            ) : (
              <>
                <center>
                  <CircularProgress color="primary" style={{ marginTop: 15 }} />
                </center>
              </>
            )}
          </Grid>
          <Snackbar
            open={openAlert}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          ></Snackbar>
          <Modal open={open1}>
            <Container
              className={classes.container1}
              style={{ marginBottom: 200 }}
            >
              <Typography
                gutterBottom
                align="center"
                variant="h6"
                component="h2"
                style={{ marginBottom: "20px" }}
              >
                Edit Data
              </Typography>
              {/* <FormControl fullWidth variant="outlined" className={classes.formControl}> */}
              <Grid item xs={12} sm={4} md={4} xl={4}>
                <StyledAutocomplete
                  size="small"
                  id="grouped-demo"
                  className={classes.inputRoottype}
                  options={[
                    { title: "Edit", code: "Edit" },
                    { title: "View", code: "View" },
                    // { title: "ALL", code: "ALL" },
                  ]}
                  groupBy={""}
                  defaultValue={{ title: model_Item.Access_Type }}
                  // value={model_Item.Access_Type}
                  getOptionLabel={(option) => option?.title}
                  style={{ width: 330, marginTop: 20 }}
                  onChange={(e, v) => handleSelectgroup(v?.title)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Accesstype"
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} xl={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    label="Expiry Date"
                    // disablePast
                    style={{ width: 330, marginTop: "30px" }}
                    format="MM/dd/yyyy"
                    value={model_Item.Expiry_date}
                    size="small"
                    onChange={(date) => handleChangeDate(date)}
                    defaultValue={model_Item.Expiry_date}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <div className={classes.item}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 20, marginLeft: 70, marginTop: "20px" }}
                  onClick={() =>
                    handleUpdateApproval(
                      model_Item.Expiry_date,
                      model_Item.Access_Type,
                      model_Item,
                      "Pending"
                    )
                  }
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setOpen1(false)}
                  style={{ marginTop: "20px" }}
                >
                  Cancel
                </Button>
              </div>
            </Container>
          </Modal>

          <Snackbar
            open={openAlert2}
            autoHideDuration={4000}
            onClose={handleClose2}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          ></Snackbar>
          <Modal open={open2}>
            <Container
              className={classes.container1}
              style={{ marginBottom: 200 }}
            >
              <Typography
                gutterBottom
                align="center"
                variant="h6"
                component="h2"
                style={{ marginBottom: "20px" }}
              >
                Edit Data
              </Typography>

              <Grid item xs={12} sm={4} md={4} xl={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    inputVariant="outlined"
                    label="Expiry Date"
                    // disablePast
                    style={{ width: 330, marginTop: "30px" }}
                    format="MM/dd/yyyy"
                    value={model_Item1.Expiry_date}
                    size="small"
                    onChange={(date) => handleChangeDate1(date)}
                    defaultValue={model_Item1.Expiry_date}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <div className={classes.item}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: 20, marginLeft: 70, marginTop: "20px" }}
                  onClick={() =>
                    handleUpdateApproval_expiry(
                      model_Item1,
                      model_Item1.Expiry_date
                    )
                  }
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setOpen2(false)}
                  style={{ marginTop: "20px" }}
                >
                  Cancel
                </Button>
              </div>
            </Container>
          </Modal>
        </Grid>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}

function StyledTreeItem(props) {
  const history = useHistory();
  const classes = useTreeItemStyles();
  const dispatch = useDispatch();
  const IsSuperAdmin = sessionStorage.getItem("isSuperAdmin");
  // const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    mainheader,
    data,
    // deleteitem,
    datavalue,
    sub,
    admin,
    createflag,
    ...other
  } = props;

  return (
    <>
      <TreeItem
        // icon={ViewModuleIcon}
        label={
          <div className={classes.labelRoot}>
            {/* <ViewModuleIcon color="inherit" className={classes.labelIcon} /> */}
            <Typography
              variant="body2"
              className={classes.labelText}
              style={{ color: "white" }}
            >
              {labelText}
            </Typography>

            <Typography
              variant="caption"
              color="inherit"
              style={{ color: "white" }}
            >
              {labelInfo}
            </Typography>
          </div>
        }
        style={{
          "--tree-view-color": color,
          "--tree-view-bg-color": bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
    </>
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: "#0A7D7F",
      color: "",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));
