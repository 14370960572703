import {
  Box,
  Grid,
  TextField,
  Typography,
  styled,
  Tooltip,
  InputAdornment,
  FilledInput,
  TextareaAutosize,
  CircularProgress,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import config from "../../Config/config";
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Notification from "../Notifications/Notification";

const useStyles = makeStyles((theme) => ({
  Accesslistcontainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "120px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },
  button: {
    margin: theme.spacing(1),
  },

  texttablecell: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      overflow: "visible",
    },
  },
  buttton: {
    height: 10,
  },

  table: {
    // minWidth: 150,
    width: "60%",
    height: "10%",
    border: "1px black",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  rootc: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  container1: {
    border: "none",
    borderRadius: 15,
    width: 380,
    height: 250,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
}));

export default function Prompt() {
  const classes = useStyles();
  const [userInpt, setUserInpt] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [assistance_Message, setAssistance_Message] = useState(null);
  const [loading_output, setLoading_output] = useState(false);

  const handleSubmit = () => {
    setLoading_output(true);
    if (userInpt === undefined || userInpt === null) {
      setNotify({
        isOpen: true,
        message: "Please provide User Input",
        type: "error",
      });
    } else {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      let body = {
        User_Message: userInpt,
        Assistance_Message: assistance_Message,
      };
      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(`${config.API_BASE_URL()}/api/open_ai_trigger/`, form, conf)
        .then(
          (res) => {
            if (
              assistance_Message === undefined ||
              assistance_Message === null
            ) {
              let ques_ans = userInpt + '\n'+ res.data
              setAssistance_Message(ques_ans);
            } else {
              // assistance_Message.concat(res.data)
              let assistance_Message_concat =
                assistance_Message + "\n\n\n\n" +userInpt+'\n'+ res.data;
              setAssistance_Message(assistance_Message_concat);
            }
            setLoading_output(false);
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something went Wrong",
              type: "error",
            });
            setLoading_output(false);
          }
        );
    }
    
  };

  return (
    <div>
      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6" style={{ fontFamily: "-moz-initial" }}>
              <b>AI Prompt</b>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <TextField
            fullWidth
            label="AI Output"
            id="outlined-size-small"
            multiline
            rows={17}
            variant="outlined"
            // disabled
            // defaultValue={assistance_Message}
            value={assistance_Message}
            size="large"
            required
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Box>

      <Box px={2} py={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <TextField
              label="User Input"
              id="outlined-size-small"
              // defaultValue="Small"
              onChange={(e) => setUserInpt(e.target.value)}
              multiline
              variant="outlined"
              placeholder={"Enter User Input"}
              size="large"
              style={{ width: 1035 }}
            />
          </Grid>
          <Grid item>
            {loading_output ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleSubmit()}
                  style={{ marginTop: 10 }}
                  size="medium"
                  endIcon={<CircularProgress style={{ color: "white" }} />}
                >
                  Send
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleSubmit()}
                  style={{ marginTop: 10 }}
                  size="medium"
                  startIcon={<SendIcon />}
                >
                  Send
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
