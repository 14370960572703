import { Box, Grid, TextField, Typography, styled } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import PropTypes from "prop-types";
import TreeView from "@material-ui/lab/TreeView";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import moment from "moment";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Notification from "../Notifications/Notification";
import config from "../../Config/config";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TreeItem from "@material-ui/lab/TreeItem";
import axios from "axios";
import MenuAppBar from "../../Components/header";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector, useDispatch } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const useStylestable = makeStyles((theme) => ({
  table: {
    minWidth: 100,
    // width:10
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 300,
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  requestcontainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "120px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },
  texttablecell: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    width: "140px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // '&:hover': {
    //     overflow: 'visible'
    // }
  },

  table: {
    // minWidth: 150,
    width: "60%",
    height: "10%",
    border: "1px black",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  rootc: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  root: {
    padding: "0px 16px",
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    height: 10,
  },
}))(TableRow);

export default function Request() {
  const classes = useStyles();
  const classestable = useStylestable();
  const {
    details,
    createFeature,
    preview,
    editpreview,
    editPreviewdetails,
    headerValue,
    project_version,
    mig_id,
  } = useSelector((state) => state.dashboardReducer);
  const [selectedItem, setSelectedItem] = useState({});
  const [isselectedItem, setisSelectedItem] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [path, setPath] = useState();
  const [list_objects, setList_of_objects] = useState([]);
  const [list_features, setList_of_features] = useState([]);
  const [access, setAccess] = useState("");
  const [fn_name, setFn_name] = useState();
  const [selectedF_data, setSelectedF_data] = useState([]);
  const [sourceDesc, setSourceDesc] = useState();
  const [Show_All, setShow_ALL] = useState(false);
  const [targetDesc, setTargetDesc] = useState();
  const [Fdata, setFdata] = useState([]);
  const [fName_desc, setFName_desc] = useState(true);
  const [object_Id, setObject_Id] = useState();

  let history = useHistory();

  useEffect(() => {
    if (headerValue && project_version) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      // console.log(project_version, " project versin");
      let body = {
        Project_Version_Id: project_version,
        Migration_Name: headerValue?.Migration_Name,
      };

      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(`${config.API_BASE_URL()}/api/object_types_format/`, form, conf)
        .then(
          (res) => {
            setList_of_objects(res.data);
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something went wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [headerValue?.Migration_Name, project_version]);

  const call_Features = (path) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    // console.log(project_version, " project versin");
    let body = {
      Project_Version_Id: project_version,
      Migration_Name: headerValue?.Migration_Name,
      Object_Path_Str: path,
      // Object_Id:object_Id
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios.post(`${config.API_BASE_URL()}/api/features_list/`, form, conf).then(
      (res) => {
        // setList_of_features(res.data);
        if (res.data === "Object path does not exist") {
          setNotify({
            isOpen: true,
            message: "Selected Object Path Doesn't Exist",
            type: "error",
          });
        } else if (res.data.length > 0) {
          setList_of_features(res.data);
        } else {
          setList_of_features([]);
        }
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something went wrong Please try Again",
          type: "error",
        });
      }
    );
  };

  const handleTreeItemClick = (obj, id) => {
    if (path) {
      let mod_str = path.split("/");
      let identified_str = mod_str.pop();
      // console.log(identified_str)
      // console.log(identified_str, '===', obj)
      if (identified_str === obj) {
        setPath(path);
      } else {
        setPath(path + "/" + obj);
      }
    } else {
      setPath(obj);
    }
    setObject_Id(id);
    // console.log(path, ' path')
  };

  const RenderTree = (nodes) => {
    console.log("selection path ", nodes);
    // const history = useHistory();
    return (
      <TreeItem
        key={nodes?.Object_Id}
        nodeId={nodes?.Object_Id}
        onLabelClick={() => {
          handleTreeItemClick(nodes?.Object_Type, nodes?.Object_Id);
        }}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "black", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_Type}
            </Typography>
          </div>
        }
      >
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Pre") {
                return RenderTree(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type !== "Pre" || node?.Object_Type !== "Post") {
                return RenderTree(node, 0);
              }
            })
          : null} */}

        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              console.log(node?.Object_Type);
              if (node?.Object_Type === "Post" || node?.Object_Type === "Pre") {
              } else {
                return RenderTree(node, 0);
              }
            })
          : null}
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Post") {
                return RenderTree(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              RenderTree(node, 0);
            })
          : null} */}
      </TreeItem>
    );
  };

  const handleTreeStructureselected = (v) => {
    // console.log(v)
    setSelectedItem(v);
    setisSelectedItem(true);
  };

  const clearPath = () => {
    setPath("");
  };

  const handle_Feature_data = (selecetdfname) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let body = {
      Object_Id: "",
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(
        `${config.API_BASE_URL()}/api/feature_detail/${selecetdfname}`,
        form,
        conf
      )
      .then(
        (res) => {
          setSelectedF_data(res.data[0]);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handle_call_fdata = (e, v) => {
    if (v?.Feature_Name === "All_Features") {
      setTargetDesc("");
      setSourceDesc("");
      setShow_ALL(true);
    } else if (v?.Feature_Name === "All_Sub_Objects") {
      setTargetDesc("");
      setSourceDesc("");
      setShow_ALL(true);
    } else {
      setShow_ALL(false);
      if (v?.Feature_Name === "" || v?.Feature_Name === undefined) {
        setTargetDesc("");
        setSourceDesc("");
      } else {
        let conf = {
          headers: {
            Authorization: "Bearer " + config.ACCESS_TOKEN(),
          },
        };

        let body = {
          Object_Id: v?.Object_Id,
          User_Email: sessionStorage.getItem("uemail"),
          Migration_Name: headerValue?.Migration_Name,
          Object_Type_String: path,
        };
        const form = new FormData();
        Object.keys(body).forEach((key) => {
          form.append(key, body[key]);
        });

        axios
          .post(
            `${config.API_BASE_URL()}/api/feature_detail/${v?.Feature_Name}/`,
            form,
            conf
          )
          .then(
            (res) => {
              Object.keys(res.data).forEach((val) => {
                if (res.data[val]?.Max_Version === true) {
                  setFdata(res.data[val]);
                  setTargetDesc(res.data[val].Target_FeatureDescription);
                  setSourceDesc(res.data[val].Source_FeatureDescription);
                }
              });
            },
            (error) => {
              setNotify({
                isOpen: true,
                message: "Something went wrong Please try Again",
                type: "error",
              });
            }
          );
      }
    }
  };

  const handleViewEditAccessbuttons = (e, v) => {
    let f_select;
    if (v?.Feature_Name === "All_Features") {
      setFName_desc(true);
      f_select = path + "/" + "F-ALL";
      setFn_name(path + "/" + "F-ALL");
    } else if (v?.Feature_Name === "All_Sub_Objects") {
      setFName_desc(true);
      f_select = path + "/" + "ALL";
      setFn_name(path + "/" + "ALL");
    } else {
      setFName_desc(false);
      setFn_name(path + "/" + v?.Feature_Name);
      f_select = path + "/" + v?.Feature_Name;
    }

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    // console.log(project_version, " project versin");
    let body = {
      Project_Version_Id: project_version,
      User_Email: sessionStorage.getItem("uemail"),
      Migration_Name: headerValue?.Migration_Name,
      Object_Type_String: f_select,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/check_user_access/`, form, conf)
      .then(
        (res) => {
          setAccess(res.data);
          // console.log("Access ", access);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handleRequestAccess = (access_type) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let body = {
      Project_Version_Id: project_version,
      User_Email: sessionStorage.getItem("uemail"),
      Migration_Name: headerValue?.Migration_Name,
      Approval_Request: fn_name,
      Access_Type: access_type,
      Approval_Status: "Pending",
      Migration_Id: mig_id,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/approval_request_create/`, form, conf)
      .then(
        (res) => {
          // console.log(res.data);
          if (
            res.data ===
            "Approval Request Already Present OR Please Wait For Admin To Approve"
          ) {
            setNotify({
              isOpen: true,
              message:
                "Approval Request Already Present OR Please Wait For Admin To Approve",
              type: "info",
            });
          } else if (res.data === "Already Having Access") {
            setNotify({
              isOpen: true,
              message: res.data,
              type: "info",
            });
          } else {
            setNotify({
              isOpen: true,
              message: `Request ${access_type} Access Created for the Feature`,
              type: "success",
            });
          }
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  // console.log(Fdata, "F");
  return (
    <div className={classes.requestcontainer}>
      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Request</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="Migration Type"
              name="MigrationType_Id"
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={headerValue?.Migration_Name}
              size="small"
              disabled
              style={{ width: 300 }}
            />
          </Grid>

          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={list_objects}
              groupBy={""}
              // defaultValue={{ title: "Procedure" }}
              getOptionLabel={(option) => option.Object_Type}
              style={{ width: 300 }}
              onChange={(e, v) => {
                handleTreeStructureselected(v);
                clearPath();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="ObjectType"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      {isselectedItem ? (
        <>
          <Box py={2} px={2}>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <TreeView
                  className={classes.root}
                  defaultExpanded={["3"]}
                  style={{ marginRight: 100 }}
                  defaultCollapseIcon={<ArrowDropDownIcon />}
                  defaultExpandIcon={<ArrowRightIcon />}
                  defaultEndIcon={<div style={{ width: 24 }} />}
                  sx={{
                    height: 264,
                    flexGrow: 1,
                    maxWidth: 400,
                    overflowY: "auto",
                  }}
                >
                  {RenderTree(selectedItem)}
                </TreeView>
              </Grid>

              <Grid item>
                <TextField
                  id="outlined-multiline-static"
                  label="Selected Path"
                  size="small"
                  style={{ width: 300, marginRight: 180 }}
                  multiline
                  rows={2}
                  // onChange={(e) => handlePath()}
                  name="Selected Path"
                  value={path}
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid>
                <Button
                  variant="outlined"
                  color="primary"
                  // size='small'
                  style={{ marginTop: 13, marginLeft: "-130px" }}
                  onClick={() => clearPath()}
                >
                  Clear
                </Button>
              </Grid>
              {"  "}
              <Grid>
                <Button
                  variant="outlined"
                  color="primary"
                  // size='small'
                  style={{ marginTop: 13, marginRight: "0px" }}
                  onClick={() => call_Features(path)}
                >
                  Get Features
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Box py={2} px={2}>
            <Grid container direction="row" justifyContent="center" spacing={1}>
              <Grid>
                <StyledAutocomplete
                  size="small"
                  id="grouped-demo"
                  className={classes.inputRoottype}
                  options={list_features}
                  groupBy={""}
                  // defaultValue={{ Feature_Name: "All" }}
                  getOptionLabel={(option) => option?.Feature_Name}
                  style={{ width: 300, marginRight: 120 }}
                  onChange={(e, v) => {
                    handleViewEditAccessbuttons(e, v);
                    handle_call_fdata(e, v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Feature Names"
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}

      {isselectedItem ? (
        <>
          {fName_desc ? null : (
            <>
              <Box py={2} px={2}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid item xs={10}>
                    {/* <TextField
                      id="outlined-multiline-static"
                      label="Source Description"
                      size="small"
                      style={{ width: 470 }}
                      multiline
                      rows={15}
                      // onChange={(e) => setPath(e.target.value)}
                      name="Source Description"
                      value={sourceDesc}
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    /> */}
                    {/* <div className="App"> */}
                    <center>{"Source Description"}</center>
                    <CKEditor
                      editor={ClassicEditor}
                      data={sourceDesc}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                      disabled
                    />
                    {/* </div> */}
                  </Grid>
                  <Grid item xs={10}>
                    {/* <TextField
                      id="outlined-multiline-static"
                      label="Target Description"
                      size="small"
                      style={{ width: 470 }}
                      multiline
                      rows={15}
                      // onChange={(e) => setPath(e.target.value)}
                      name="Target Description"
                      value={targetDesc}
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    /> */}
                    <center>{"Target Description"}</center>
                    <CKEditor
                      editor={ClassicEditor}
                      data={targetDesc}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onBlur={(event, editor) => {
                        console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        console.log("Focus.", editor);
                      }}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      ) : null}
      <Box style={{ marginBottom: "30px" }}>
        <Grid container direction="row" justifyContent="center">
          <Grid item direction="row" justifyContent="center" spacing={1}>
            {access === 0 ? (
              <>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  component="span"
                  style={{ marginTop: 15, width: "190px" }}
                  onClick={(e) => {
                    handleRequestAccess("View");
                  }}
                >
                  Request View Access
                </Button>{" "}
                {Show_All === false ? (
                  <>
                    <Button
                      variant="contained"
                      // disabled={!selecetd}
                      size="small"
                      onClick={() =>
                        history.push({
                          pathname: `/requestdata`,
                          data: { Fdata },
                        })
                      }
                      disabled
                      color="primary"
                      component="span"
                      style={{ marginTop: 15, width: "100px" }}
                    >
                      Show All
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      // disabled={!selecetd}
                      size="small"
                      onClick={() =>
                        history.push({
                          pathname: `/requestdata`,
                          data: { Fdata },
                        })
                      }
                      disabled
                      color="primary"
                      component="span"
                      style={{ marginTop: 15, width: "100px" }}
                    >
                      Show All
                    </Button>
                  </>
                )}{" "}
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  component="span"
                  style={{ marginTop: 15, width: "180px" }}
                  onClick={(e) => {
                    handleRequestAccess("Edit");
                  }}
                >
                  Request Edit Access
                </Button>
              </>
            ) : null}

            {access === 1 ? (
              <>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  component="span"
                  style={{ marginTop: 15, width: "190px" }}
                  onClick={(e) => {
                    // handleRequestAccess("View");
                  }}
                  disabled
                >
                  Request View Access
                </Button>{" "}
                {Show_All === false ? (
                  <>
                    <Button
                      variant="contained"
                      // disabled={!selecetd}
                      size="small"
                      onClick={() =>
                        history.push({
                          pathname: `/requestdata`,
                          data: { Fdata },
                        })
                      }
                      color="primary"
                      component="span"
                      style={{ marginTop: 15, width: "100px" }}
                    >
                      Show All
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      // disabled={!selecetd}
                      size="small"
                      onClick={() =>
                        history.push({
                          pathname: `/requestdata`,
                          data: { Fdata },
                        })
                      }
                      disabled
                      color="primary"
                      component="span"
                      style={{ marginTop: 15, width: "100px" }}
                    >
                      Show All
                    </Button>
                  </>
                )}{" "}
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  component="span"
                  style={{ marginTop: 15, width: "180px" }}
                  onClick={(e) => {
                    handleRequestAccess("Edit");
                  }}
                >
                  Request Edit Access
                </Button>
              </>
            ) : null}

            {access === 2 ? (
              <>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  component="span"
                  style={{ marginTop: 15, width: "190px" }}
                  onClick={(e) => {
                    // handleRequestAccess("View");
                  }}
                  disabled
                >
                  Request View Access
                </Button>{" "}
                {Show_All === false ? (
                  <>
                    <Button
                      variant="contained"
                      // disabled={!selecetd}
                      size="small"
                      onClick={() =>
                        history.push({
                          pathname: `/requestdata`,
                          data: { Fdata },
                        })
                      }
                      color="primary"
                      component="span"
                      style={{ marginTop: 15, width: "100px" }}
                    >
                      Show All
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      // disabled={!selecetd}
                      size="small"
                      onClick={() =>
                        history.push({
                          pathname: `/requestdata`,
                          data: { Fdata },
                        })
                      }
                      disabled
                      color="primary"
                      component="span"
                      style={{ marginTop: 15, width: "100px" }}
                    >
                      Show All
                    </Button>
                  </>
                )}{" "}
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  component="span"
                  style={{ marginTop: 15, width: "180px" }}
                  onClick={(e) => {
                    // handleRequestAccess("Edit");
                  }}
                  disabled
                >
                  Request Edit Access
                </Button>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}

function StyledTreeItem(props) {
  const history = useHistory();
  const classes = useTreeItemStyles();
  const dispatch = useDispatch();
  const IsSuperAdmin = sessionStorage.getItem("isSuperAdmin");
  // const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    mainheader,
    data,
    // deleteitem,
    datavalue,
    sub,
    admin,
    createflag,
    ...other
  } = props;

  return (
    <>
      <TreeItem
        // icon={ViewModuleIcon}
        label={
          <div className={classes.labelRoot}>
            {/* <ViewModuleIcon color="inherit" className={classes.labelIcon} /> */}
            <Typography
              variant="body2"
              className={classes.labelText}
              style={{ color: "white" }}
            >
              {labelText}
            </Typography>

            <Typography
              variant="caption"
              color="inherit"
              style={{ color: "white" }}
            >
              {labelInfo}
            </Typography>
          </div>
        }
        style={{
          "--tree-view-color": color,
          "--tree-view-bg-color": bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
    </>
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: "#0A7D7F",
      color: "",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));
