import { Box, Grid, TextField, Typography, styled } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import Notification from "../Notifications/Notification";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import DeleteIcon from "@material-ui/icons/Delete";
import TableBody from "@material-ui/core/TableBody";
// import fileDownload from "js-file-download";
import Table from "@material-ui/core/Table";
import InfoIcon from "@material-ui/icons/Info";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import TreeItem from "@material-ui/lab/TreeItem";
import PropTypes from "prop-types";
import TreeView from "@material-ui/lab/TreeView";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import config from "../../Config/config";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  container1: {
    border: "none",
    borderRadius: 15,
    width: 380,
    height: 250,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  Accesslistcontainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "120px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },

  texttablecell: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    // width: "140px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      overflow: "visible",
    },
  },

  table: {
    // minWidth: 150,
    width: "90%",
    height: "10%",
    border: "1px black",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  rootc: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5",
    },
  },
});

export default function ConversionProcess() {
  const classes = useStyles();
  const classestable = useStylestable();
  const { headerValue, project_version, mig_id } = useSelector(
    (state) => state.dashboardReducer
  );
  const [isscattdata, setIsscattdata] = useState(false);
  // const [source_codeatt, setSource_codeatt] = useState([]);
  const [path, setPath] = useState();
  const [objectslist, setObjectslist] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isselectedItem, setisSelectedItem] = useState(false);
  const [obj, setObj] = useState("ALL");
  const [root_objid, setRootobjid] = useState();

  const [list_features, setList_of_features] = useState([]);

  const [object_Id, setObject_Id] = useState();
  const [ruleslist, setRuleslist] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [inputdata, setInputdata] = useState("");
  const [outputdata, setOutputdata] = useState("");
  const [schemaname, setSchemaname] = useState("");
  const [loading_dynamicrules, setLoading_dynamicrules] = useState(false);
  const [loading_conversion_modules, setLoading_conversion_modules] =
    useState(false);
  const [loading_convert, setLoading_convert] = useState(false);

  useEffect(() => {
    if (headerValue?.Migration_Name && project_version) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      let body = {
        Migration_Name: headerValue?.Migration_Name,
        Project_Version_Id: project_version,
        User_Email: sessionStorage.getItem("uemail"),
      };
      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      // axios.get(`${config.API_BASE_URL()}/api/migrationviewlist/`, conf).then(
      axios
        .post(`${config.API_BASE_URL()}/api/menu_view_creation/`, form, conf)
        .then(
          (res) => {
            setObjectslist(res.data.concat([{ Object_Type: "ALL" }]));
            // setList_of_features(
            //   [{ Feature_Name: "ALL", Object_Id: "" }].concat(res.data)
            // );
            // console.log(res.data);
          },
          (error) => {}
        );
    }
  }, [headerValue?.Migration_Name, project_version]);
  // console.log("return");
  const handleTreeStructureselected = (v) => {
    if (v?.Object_Type !== "ALL") {
      setSelectedItem(v);
      setisSelectedItem(true);
    } else {
      // setSelectedItem(v);
      setisSelectedItem(false);
    }
  };

  const clearPath = () => {
    setPath("");
  };

  const add_feature = (fname) => {
    if (path) {
      setPath(path + "/" + fname);
    }
  };

  const RenderTree = (nodes) => {
    console.log("selection path ", nodes);
    // const history = useHistory();
    return (
      <TreeItem
        key={nodes?.Object_Id}
        nodeId={nodes?.Object_Id}
        onLabelClick={() => {
          handleTreeItemClick(nodes?.Object_Type, nodes?.Object_Id);
        }}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "black", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_Type}
            </Typography>
          </div>
        }
      >
        {nodes?.Sub_Menu.map((fn, key) => {
          return (
            <StyledTreeItem
              key={fn?.Feature_Name}
              nodeId={key + fn?.Feature_Name}
              labelText={fn?.Feature_Name}
              labelIcon={ViewModuleIcon}
              style={{ color: "black" }}
              onClick={() => add_feature(fn?.Feature_Name)}
            ></StyledTreeItem>
          );
        })}

        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Pre") {
                return RenderTree(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type !== "Pre" || node?.Object_Type !== "Post") {
                return RenderTree(node, 0);
              }
            })
          : null} */}

        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              console.log(node?.Object_Type);
              if (node?.Object_Type === "Post" || node?.Object_Type === "Pre") {
              } else {
                return RenderTree(node, 0);
              }
            })
          : null}
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Post") {
                return RenderTree(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              RenderTree(node, 0);
            })
          : null} */}
      </TreeItem>
    );
  };

  const handle_call_fdata = (e, v) => {
    if (v?.Feature_Name === "ALL") {
      // setTargetDesc("");
      // setSourceDesc("");
      // setShow_ALL(true);
    } else {
      // setShow_ALL(false);
      if (v?.Feature_Name === "" || v?.Feature_Name === undefined) {
        // setTargetDesc("");
        // setSourceDesc("");
      } else {
        let conf = {
          headers: {
            Authorization: "Bearer " + config.ACCESS_TOKEN(),
          },
        };

        let body = {
          Object_Id: v?.Object_Id,
          User_Email: sessionStorage.getItem("uemail"),
          Migration_Name: headerValue?.Migration_Name,
          Object_Type_String: path,
        };
        const form = new FormData();
        Object.keys(body).forEach((key) => {
          form.append(key, body[key]);
        });

        axios
          .post(
            `${config.API_BASE_URL()}/api/feature_detail/${v?.Feature_Name}/`,
            form,
            conf
          )
          .then(
            (res) => {
              Object.keys(res.data).forEach((val) => {
                if (res.data[val]?.Max_Version === true) {
                  // setFdata(res.data[val]);
                  // setTargetDesc(res.data[val].Target_FeatureDescription);
                  // setSourceDesc(res.data[val].Source_FeatureDescription);
                }
              });
            },
            (error) => {
              setNotify({
                isOpen: true,
                message: "Something went wrong Please try Again",
                type: "error",
              });
            }
          );
      }
    }
  };

  const validate = (path) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Project_Version_Id: project_version,
      Migration_Name: headerValue?.Migration_Name,
      Feature_Path_String: path,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/feature_validate/`, form, conf)
      .then(
        (res) => {
          // console.log(res)
          if (res.data === 0) {
            setNotify({
              isOpen: true,
              message: "Path Invalid please try again",
              type: "error",
            });
            // console.log("entere")
          } else {
            setNotify({
              isOpen: true,
              message: "Path Added in the Features List",
              type: "success",
            });
            ruleslist_data(path);
            clearPath();
          }
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const call_Features = (path) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    // console.log(project_version, " project versin");
    let body = {
      Project_Version_Id: project_version,
      Migration_Name: headerValue?.Migration_Name,
      Object_Path_Str: path,
      // Object_Id:object_Id
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios.post(`${config.API_BASE_URL()}/api/features_list/`, form, conf).then(
      (res) => {
        // setList_of_features(res.data);
        if (res.data === "Object path does not exist") {
          setNotify({
            isOpen: true,
            message: "Selected Object Path Doesn't Exist",
            type: "error",
          });
        } else if (res.data.length > 0) {
          setList_of_features(
            [{ Feature_Name: "ALL", Object_Id: "" }].concat(res.data)
          );
        } else {
          setList_of_features([{ Feature_Name: "ALL", Object_Id: "" }]);
        }
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something went wrong Please try Again",
          type: "error",
        });
      }
    );
  };

  const handleTreeItemClick = (obj, id) => {
    if (path) {
      let mod_str = path.split("/");
      let identified_str = mod_str.pop();
      // console.log(identified_str)
      // console.log(identified_str, '===', obj)
      if (identified_str === obj) {
        setPath(path);
      } else {
        setPath(path + "/" + obj);
      }
    } else {
      setPath(obj);
    }
    setObject_Id(id);
    // console.log(path, ' path')
  };

  const deleteRow = async (index) => {
    var data = ruleslist;
    data.splice(index, 1);
    setRuleslist([...data]);
    if (ruleslist.length === 0) {
      setIsscattdata(false);
    }
  };

  const ruleslist_data = async (path) => {
    var obj = {
      Path: path,
    };

    var data = ruleslist;
    data.push(obj);

    setRuleslist([...data]);
    setIsscattdata(true);
  };

  const handleInputdata = (value) => {
    setInputdata(value);
  };

  const handleConvert = (rules) => {
    setLoading_convert(true);
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body;

    if (obj !== "ALL") {
      // console.log('=========')
      body = {
        Project_Version_Id: project_version,
        Migration_Name: headerValue?.Migration_Name,
        // Object_Path_Str: path,
        Parent_Object_Name: obj,
        Schema_Type: schemaname,
        Source_data: inputdata,
        User_Features_List_JSON: JSON.stringify(rules),
        Parent_Object_Id: root_objid,
      };
    } else {
      body = {
        Project_Version_Id: project_version,
        Migration_Name: headerValue?.Migration_Name,
        // Object_Path_Str: path,
        Parent_Object_Name: obj,
        Schema_Type: schemaname,
        Source_data: inputdata,
        User_Features_List_JSON: null,
        Parent_Object_Id: 0,
      };
    }
    // console.log(body);

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/master_conversion/`, form, conf)
      .then(
        (res) => {
          setLoading_convert(false);
          setOutputdata(res.data);
          setNotify({
            isOpen: true,
            message: "Completed Conversion",
            type: "success",
          });
        },
        (error) => {
          setLoading_convert(false);
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handleLoadModules = () => {
    setNotify({
      isOpen: true,
      message: "Modules Loading started Once Completed Will Notify",
      type: "info",
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Migration_Name: headerValue?.Migration_Name,
      Migration_Id: mig_id,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    setLoading_conversion_modules(true);
    axios
      .post(`${config.API_BASE_URL()}/api/load_conversion_modules/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "Modules Loaded please Start the Conversion",
            type: "success",
          });
          setLoading_conversion_modules(false);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please Try Again!",
            type: "error",
          });
          setLoading_conversion_modules(false);
        }
      );
  };

  const handleLoadDynamicrules = () => {
    setNotify({
      isOpen: true,
      message: "Dynamic Rules Loading started Once Completed Will Notify",
      type: "info",
    });

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Migration_Name: headerValue?.Migration_Name,
      Migration_Id: mig_id,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    setLoading_dynamicrules(true);
    axios
      .post(`${config.API_BASE_URL()}/api/load_dynamic_rules/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "Rules Loaded please Start the Conversion",
            type: "success",
          });
          setLoading_dynamicrules(false);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please Try Again!",
            type: "error",
          });
          setLoading_dynamicrules(false);
        }
      );
  };

  const handleSchemname = (val) => {
    if (val === undefined) {
      setSchemaname("");
    } else {
      setSchemaname(val);
    }
  };

  const handleObject = (val) => {
    setObj(val);
  };
  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />

      <Box py={1} px={1}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          style={{ marginTop: 10 }}
        >
          <Grid item>
            {loading_dynamicrules ? (
              <>
                {/* <center>
                  <CircularProgress color="primary" style={{ marginTop: 15 }} />
                </center> */}
                Loading...
              </>
            ) : (
              <>
                {" "}
                <Button
                  variant="outlined"
                  //   disabled={!selecetd1}
                  size="small"
                  color="primary"
                  component="span"
                  style={{ textTransform: "none" }}
                  onClick={() => handleLoadDynamicrules()}
                >
                  Load Dynamic Rules
                </Button>
              </>
            )}{" "}
            {loading_conversion_modules ? (
              <>
                <>
                  {/* <center>
                  <CircularProgress color="primary" style={{ marginTop: 15 }} />
                </center> */}
                  Loading...
                </>
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  //   disabled={!selecetd1}
                  size="small"
                  color="primary"
                  component="span"
                  style={{ textTransform: "none" }}
                  onClick={() => handleLoadModules()}
                >
                  {" "}
                  Load Approved Features
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Conversion</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box py={2} px={2}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={3}
          style={{ marginTop: 10 }}
        >
          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="Migration Type"
              // onChange={(e) => handleChange(e)}
              name="MigrationType_Id"
              // defaultValue="Default Value"
              // helperText={featurenamemsg}
              className={classes.textField}
              // helperText="Some important text"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={headerValue?.Migration_Name}
              size="small"
              disabled
              style={{ width: 280 }}
            />
          </Grid>
          <Grid item>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={objectslist}
              groupBy={""}
              // defaultValue={{ Object_Type: "ALL" }}
              getOptionLabel={(option) => option.Object_Type}
              style={{ width: 280 }}
              onChange={(e, v) => {
                handleObject(v?.Object_Type);
                setRootobjid(v?.Object_Id);
                handleTreeStructureselected(v);
                clearPath();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Parent Object Type"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="Schema Name"
              // onChange={(e) => handleChange(e)}
              name="schemaname"
              // defaultValue="Default Value"
              // helperText={featurenamemsg}
              className={classes.textField}
              // helperText="Some important text"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={schemaname}
              size="small"
              onChange={(e) => handleSchemname(e.target.value)}
              style={{ width: 280 }}
            />
          </Grid>
        </Grid>
      </Box>

      {isselectedItem ? (
        <>
          <Box py={2} px={2}>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <TreeView
                  className={classes.root}
                  defaultExpanded={["3"]}
                  style={{ marginRight: 100 }}
                  defaultCollapseIcon={<ArrowDropDownIcon />}
                  defaultExpandIcon={<ArrowRightIcon />}
                  defaultEndIcon={<div style={{ width: 24 }} />}
                  sx={{
                    height: 264,
                    flexGrow: 1,
                    maxWidth: 400,
                    overflowY: "auto",
                  }}
                >
                  {RenderTree(selectedItem)}
                </TreeView>
              </Grid>
              <Grid item>
                <TextField
                  id="outlined-multiline-static"
                  label="Selected Path"
                  size="small"
                  style={{ width: 350, marginRight: 110 }}
                  multiline
                  rows={5}
                  // onChange={(e) => handlePath()}
                  name="Selected Path"
                  value={path}
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />
              </Grid>
              <Grid>
                <Button
                  variant="outlined"
                  color="primary"
                  // size='small'
                  style={{ marginTop: 40, marginLeft: "-85px" }}
                  onClick={() => clearPath()}
                >
                  Clear
                </Button>
              </Grid>
              {/* {"  "} */}
              {/* <Grid>
                <Button
                  variant="outlined"
                  color="primary"
                  // size='small'
                  style={{ marginTop: 13, marginRight: "0px" }}
                  onClick={() => call_Features(path)}
                >
                  Get Features
                </Button>
              </Grid> */}
              <Grid>
                <Button
                  variant="outlined"
                  color="primary"
                  // size='small'
                  style={{ marginTop: 40, marginRight: "0px" }}
                  onClick={() => {
                    validate(path);
                  }}
                >
                  Validate & Add
                </Button>
              </Grid>
              {/* &nbsp;
              <Grid>
                <Button
                  variant="outlined"
                  color="primary"
                  // size='small'
                  style={{ marginTop: 40 }}
                  onClick={() => {
                    ruleslist_data(path);
                    clearPath();
                  }}
                >
                  Add
                </Button>
              </Grid> */}
            </Grid>
          </Box>

          {/* <Box py={2} px={2}>
            <Grid container direction="row" justifyContent="center" spacing={1}>
              <Grid>
                <StyledAutocomplete
                  size="small"
                  id="grouped-demo"
                  className={classes.inputRoottype}
                  options={list_features}
                  groupBy={""}
                  // defaultValue={{ Feature_Name: "All" }}
                  getOptionLabel={(option) => option?.Feature_Name}
                  style={{ width: 300, marginRight: 120 }}
                  onChange={(e, v) => {
                    // handleViewEditAccessbuttons(e, v);
                    handle_call_fdata(e, v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Feature Names"
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box> */}

          <Box py={2} px={2}>
            <Grid container direction="row" justifyContent="center" spacing={1}>
              <Grid item xl={11} xs={12} sm={12} md={11}>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h6"
                  component="h2"
                  className={classes.Object_Type}
                >
                  Selected Features List
                </Typography>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      <StyledTableCell align="center">Path</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isscattdata ? (
                      <>
                        {ruleslist.map((row) => (
                          <StyledTableRow container>
                            {/* <StyledTableCell item xl={5}>
                                                    <div className={classes.texttablecell}>{row.AttachmentType}</div>
                                                </StyledTableCell> */}
                            <StyledTableCell item xl={10} align="center">
                              <div className={classes.texttablecell}>
                                {row.Path}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell item xl={2} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              <IconButton onClick={(e) => deleteRow(row.id)}>
                                <DeleteIcon style={{ color: "red" }} />
                              </IconButton>
                              {/* </div> */}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <StyledTableCell align="center"></StyledTableCell>

                        <StyledTableCell align="left">No Data</StyledTableCell>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="Input"
              name="input"
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              rows={15}
              multiline
              fullWidth
              value={inputdata}
              size="small"
              onChange={(e) => handleInputdata(e.target.value)}
              style={{ width: 900 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container direction="row " justifyContent="center" spacing={2}>
          <Grid item style={{ marginTop: 15 }}>
            <InfoIcon style={{ color: "blue" }} />
          </Grid>
          <Grid item>
            <h4 style={{ color: "red" }}>
              Note : Before Convert Please Click on Load Dynamic Rules & Load
              Approved Features
            </h4>
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            {loading_convert ? (
              <>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  endIcon={<CircularProgress style={{ color: "white" }} />}
                  // style={{ marginTop: 60 }}
                  onClick={() => handleConvert(ruleslist)}
                >
                  Convert
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  // style={{ marginTop: 60 }}
                  onClick={() => handleConvert(ruleslist)}
                >
                  Convert
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box py={2} px={2}>
        <Grid container direction="row" justifyContent="center" spacing={1}>
          <Grid item>
            <TextField
              id="outlined-multiline-static"
              label="output"
              name="output"
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              rows={15}
              multiline
              // disabled
              fullWidth
              value={outputdata}
              size="small"
              // onChange={(e) => setOutputdata(e.target.value)}
              // style={{ width: 950 }}
              style={{ width: 900, marginBottom: 20 }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

function StyledTreeItem(props) {
  const history = useHistory();
  const classes = useTreeItemStyles();
  const dispatch = useDispatch();
  const IsSuperAdmin = sessionStorage.getItem("isSuperAdmin");
  // const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    mainheader,
    data,
    // deleteitem,
    datavalue,
    sub,
    admin,
    createflag,
    ...other
  } = props;

  return (
    <>
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography
              variant="body2"
              className={classes.labelText}
              style={{ color: "black" }}
            >
              {labelText}
            </Typography>

            <Typography
              variant="caption"
              color="inherit"
              style={{ color: "black" }}
            >
              {labelInfo}
            </Typography>
          </div>
        }
        style={{
          "--tree-view-color": color,
          "--tree-view-bg-color": bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
    </>
  );
}

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    // "&:hover > $content": {
    //   backgroundColor: theme.palette.action.hover,
    // },
    "&:focus > $content, &$selected > $content": {
      // backgroundColor: "#0A7D7F",
      color: "",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  root: {
    padding: "0px 16px",
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    height: 10,
  },
}))(TableRow);

const useStylestable = makeStyles({
  table: {
    minWidth: 100,
    // width:10
  },
});
