import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Container, Modal, Snackbar } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import GetAppIcon from "@material-ui/icons/GetApp";
// import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Avatar } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ConfirmDialog from "../../Features/Notifications/ConfirmDialog";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import config from "../../Config/config";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import axios from "axios";
import Notification from "../Notifications/Notification";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import Menuaction from "../../Redux/actions/Menuaction";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
const useStylestable = makeStyles((theme) => ({
  table: {
    width: "100%",
    // width:10
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 300,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  texttablecell: {
    // overflowX: 'hidden',
    whiteSpace: "nowrap",
    width: "140px",
    // overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      overflow: "visible",
    },
    maxWidth: "300px",
    width: "300px",
  },

  table: {
    // minWidth: 150,
    width: "60%",
    height: "10%",
    border: "1px black",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  rootc: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },

  //pop up weindow

  container: {
    border: "none",
    borderRadius: 15,
    width: 460,
    height: 390,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  container1: {
    border: "none",
    borderRadius: 15,
    width: 450,
    height: 350,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  container2: {
    border: "none",
    borderRadius: 15,
    width: 450,
    height: 400,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanellink(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanellink-${index}`}
      aria-labelledby={`simple-tablink-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanellink.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function a11yProps_link(index) {
  return {
    id: `simple-tablink-${index}`,
    "aria-controls": `simple-tabpanellink-${index}`,
  };
}

const useStyles_tab_source = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: "95%",
    marginLeft: "30px",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  root: {
    padding: "0px 16px",
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    height: 10,
  },
}))(TableRow);

export default function MigrationAdmin() {
  const classes = useStyles();
  const classes_tab_source = useStyles_tab_source();
  const FileDownload = require("js-file-download");
  const classestable = useStylestable();
  let history = useHistory();
  const dispatch = useDispatch();
  const {
    details,
    createFeature,
    preview,
    editpreview,
    editPreviewdetails,
    headerValue,
    project_version,
    DropDownValues,
  } = useSelector((state) => state.dashboardReducer);
  const [select_prj_versionitem, setSelect_prj_versionitem] = useState();
  const [updateDropDownList, setUpdateDropDownList] = useState(false);
  const [objecttype_rm_admin, setObjecttype_rm_admin] = useState();
  const [mig_id, setMig_id] = useState();
  const [useremail2, setuseremail2] = useState();
  const [useradminmiglist, setuseradminmiglist] = useState([]);
  const [azure_mig_name, setAzure_mig_name] = useState();
  const [migtype_create, setMigtype_create] = useState();
  const [azure_mig_id, setAzure_mig_id] = useState();
  const [DropDownList, setDropdownList] = useState([]);
  const [list_objects, setList_of_objects] = useState([]);
  const [userslist, setUserslist] = useState([]);
  const [list_features, setList_of_features] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItem_tab_source, setSelectedItem_tab_source] = useState({});
  const [selectedItem_tab_target, setSelectedItem_tab_target] = useState({});
  const [selectedItem_tab_source_link, setSelectedItem_tab_source_link] =
    useState({});
  const [selectedItem_tab_target_link, setSelectedItem_tab_target_link] =
    useState({});
  const [isselectedItem, setisSelectedItem] = useState(false);
  const [isselectedItem_link, setisSelectedItem_link] = useState(false);
  const [isselectedItem_target, setisSelectedItem_target] = useState(false);
  const [isselectedItem_target_link, setisSelectedItem_target_link] =
    useState(false);
  const [path, setPath] = useState();
  const [path_tab_source, setPath_tab_source] = useState();
  const [path_tab_target, setPath_tab_target] = useState();
  const [path_tab_source_link, setPath_tab_source_link] = useState();
  const [path_tab_target_link, setPath_tab_target_link] = useState();
  const [open1, setOpen1] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [parentObject, setParentObject] = useState();
  const [parentdropDownList, setParentdropDownList] = useState([]);
  const [deletemig, setDeletemig] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [isMigAdminData, setIsMigAdminData] = useState(false);
  const [loading_malist, setLoading_malist] = useState(false);
  const [rm_admin_email, setRm_admin_email] = useState();
  const [rm_admin_mig, setRm_admin_mig] = useState();
  const [rm_admin_objectslist, setRm_admin_objectslist] = useState([]);
  const [openrm_admin, setOpenrm_admin] = useState(false);
  const [mig_subdelete, setMig_subdelete] = useState();
  const [subobjType, setSubobjtype] = useState();
  const [selectedItem1, setSelectedItem1] = useState({});
  const [updatemigrationAdminTable, setUpdatemigrationAdminTable] =
    useState(false);
  const [migAdminList, setMigAdminList] = useState([]);
  const [path1, setPath1] = useState();
  const [checkbox, setCheckbox] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [feature_name_tab_source, setFeature_name_tab_source] = useState("");
  const [feature_name_tab_source_list, setFeature_name_tab_source_list] =
    useState([]);
  const [features_json_data, setFeatures_json_data] = useState([]);
  const [feature_name_tab_target, setFeature_name_tab_target] = useState("");
  const [feature_name_tab_source_link, setFeature_name_tab_source_link] =
    useState("");
  const [feature_name_tab_target_link, setFeature_name_tab_target_link] =
    useState("");
  const [feature_objid, setFeature_objid] = useState();
  const [feature_objid_link, setFeature_objid_link] = useState();
  const [feature_objid_target, setFeature_objid_target] = useState();
  const [feature_objid_target_link, setFeature_objid_target_link] = useState();
  const [value, setValue] = React.useState(0);
  const [value_link, setValue_link] = React.useState(0);
  const [process, setProcess] = useState();
  const [category, setCategory] = useState();
  const [orderID, setOrderID] = useState(0);
  const [objecttypesData_edit, setObjecttypesData_edit] = useState([]);
  const [loading_otlist, setLoading_otlist] = useState(false);
  const [isobjecttypesData_edit, setIsObjecttypesData_edit] = useState(false);

  const [objecttypesModel_data, setObjecttypesModel_data] = useState([]);
  const [objectLinkingList, setObjectLinking_List] = useState([]);
  const [isobjectLinkingListData, setIsObjectLinking_ListData] =
    useState(false);
  const [objectTypesID, setObjectTypesID] = useState();

  useEffect(() => {
    if (
      headerValue?.Migration_Name === null ||
      headerValue?.Migration_Name === undefined
    ) {
      history.push("/dashboard");
    }
  });

  useEffect(() => {
    if (project_version) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      let body = {
        Project_Version_Id: project_version,
        Migration_Name: headerValue?.Migration_Name,
        Parent_Object_Id: 0,
      };
      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(
          `${config.API_BASE_URL()}/api/object_processing_list/`,
          form,
          conf
        )
        .then(
          (res) => {
            if (res.data.length > 0) {
              setIsObjecttypesData_edit(true);
              setObjecttypesData_edit(res.data);
            }
            setLoading_otlist(true);
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something went wrong Please try Again",
              type: "error",
            });
            setLoading_otlist(false);
          }
        );
    }
  }, []);

  useEffect(() => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios.get(`${config.API_BASE_URL()}/api/users_list/`, conf).then(
      (res) => {
        setUserslist(res.data);
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
      }
    );
  }, []);

  const handleChange_tab_source = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange_tab_source_link = (event, newValue) => {
    setValue_link(newValue);
  };

  // React.useEffect(() => {
  //   let conf = {
  //     headers: {
  //       Authorization: "Bearer " + config.ACCESS_TOKEN(),
  //     },
  //   };
  //   let body = {
  //     User_Email: sessionStorage.getItem("uemail"),
  //   };
  //   const form = new FormData();
  //   Object.keys(body).forEach((key) => {
  //     form.append(key, body[key]);
  //   });
  //   axios
  //     .post(
  //       `${config.API_BASE_URL()}/api/migration_names_list_user_admin/`,
  //       form,
  //       conf
  //     )
  //     .then(
  //       (res) => {
  //         setuseradminmiglist(res.data);
  //         //   if (updateDropDownList) {
  //         //     dispatch(Menuaction.getdropdownlist(res.data));
  //         //   }
  //       },
  //       (error) => {
  //         setNotify({
  //           isOpen: true,
  //           message: "Something Went Wrong Please try Again",
  //           type: "error",
  //         });
  //       }
  //     );
  // }, []);

  useEffect(() => {
    if (headerValue?.Migration_Name && project_version) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      // console.log(project_version, " project versin");
      let body = {
        Project_Version_Id: project_version,
        Migration_Name: headerValue?.Migration_Name,
      };

      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(`${config.API_BASE_URL()}/api/object_types_format/`, form, conf)
        .then(
          (res) => {
            setList_of_objects(res.data);
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something went wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [headerValue?.Migration_Name && project_version]);

  useEffect(() => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios.get(`${config.API_BASE_URL()}/api/migration_admin_list/`, conf).then(
      (res) => {
        setMigAdminList(res.data);
        if (res.data.length > 0) {
          setIsMigAdminData(true);
        }
        setLoading_malist(true)
      },

      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
        setLoading_malist(false)
      }
    );
  }, [updatemigrationAdminTable]);
  const handleGetObjecttypesList = (migtype) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    // console.log(project_version, " project versin");
    let body = {
      Project_Version_Id: project_version,
      Migration_Name: headerValue?.Migration_Name,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/object_types_format/`, form, conf)
      .then(
        (res) => {
          setList_of_objects(res.data);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handleObjectypeCreate = (order, process, category) => {
    let body;
    let obj;
    if (checkbox === true) {
      if (subobjType || subobjType !== "") {
        obj = path + "/" + subobjType;
      } else {
        obj = path;
      }
      body = {
        Project_Version_Id: project_version,
        Migration_Name: headerValue?.Migration_Name,
        Object_Type_Str: obj,
        Migration_Id: headerValue?.Migration_Id,
        Object_Category: category,
        Object_Process_Style: process,
        Object_Execution_Order: order,
        Parent_Object_Id: objectTypesID 
      };
    } else {
      body = {
        Project_Version_Id: project_version,
        Migration_Name: headerValue?.Migration_Name,
        Object_Type_Str: path,
        Migration_Id: headerValue?.Migration_Id,
        Object_Category: category,
        Object_Process_Style: process,
        Object_Execution_Order: order,
        Parent_Object_Id: objectTypesID 
      };
    }

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/object_type_create/`, form, conf)
      .then(
        (res) => {
          if (res.data === "Object Type already exist") {
            setNotify({
              isOpen: true,
              message: "Object Type already exist",
              type: "error",
            });
          } else {
            setNotify({
              isOpen: true,
              message: "Object Type created",
              type: "success",
            });
            // handlePearentobjecttypes(modelMigtype_ObjectCreation);
            handleGetObjecttypesList(migtype_create);
            dispatch(Menuaction.reloadAction(true));
          }

          // setOpen(false);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handleRm_migadmin = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: rm_admin_email,
      Migration_Name: rm_admin_mig,
      Object_Type: objecttype_rm_admin,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/remove_admin_permission/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
          setUpdatemigrationAdminTable(true);
          // dispatch(Menuaction.reloadAction(true));
          window.location.reload();
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setUpdatemigrationAdminTable(false);
    setOpenrm_admin(false);
    // dispatch(Menuaction.reloadAction(false));
  };
  const handlemigrationadmincreation = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: useremail2,
      Migration_Name: headerValue?.Migration_Name,
      Object_Type: parentObject,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(
        `${config.API_BASE_URL()}/api/create_admin_permissions/`,
        form,
        conf
      )
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });

          setUpdatemigrationAdminTable(true);
          // dispatch(Menuaction.reloadAction(true));
          // window.location.reload();
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong",
            type: "error",
          });
        }
      );
    setUpdatemigrationAdminTable(false);
    // dispatch(Menuaction.reloadAction(false));
  };

  const RenderTree_tab_source = (nodes) => {
    // console.log("selection path ", nodes);
    // const history = useHistory();
    return (
      <TreeItem
        key={nodes?.Object_Id}
        nodeId={nodes?.Object_Id}
        onLabelClick={() => {
          handleTreeItemClick_tab_source(nodes?.Object_Type, nodes?.Object_Id);
        }}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "black", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_Type}
            </Typography>
          </div>
        }
      >
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Pre") {
                return RenderTree_tab_source(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
        ? nodes?.Sub_Objects.map((node) => {
            if (node?.Object_Type !== "Pre" || node?.Object_Type !== "Post") {
              return RenderTree(node, 0);
            }
          })
        : null} */}

        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              // console.log(node?.Object_Type);
              if (node?.Object_Type === "Post" || node?.Object_Type === "Pre") {
              } else {
                return RenderTree_tab_source(node, 0);
              }
            })
          : null}
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Post") {
                return RenderTree_tab_source(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
        ? nodes?.Sub_Objects.map((node) => {
            RenderTree(node, 0);
          })
        : null} */}
      </TreeItem>
    );
  };

  const RenderTree_tab_source_link = (nodes) => {
    return (
      <TreeItem
        key={nodes?.Object_Id}
        nodeId={nodes?.Object_Id}
        onLabelClick={() => {
          handleTreeItemClick_tab_source_link(
            nodes?.Object_Type,
            nodes?.Object_Id
          );
        }}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "black", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_Type}
            </Typography>
          </div>
        }
      >
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Pre") {
                return RenderTree_tab_source_link(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
        ? nodes?.Sub_Objects.map((node) => {
            if (node?.Object_Type !== "Pre" || node?.Object_Type !== "Post") {
              return RenderTree(node, 0);
            }
          })
        : null} */}

        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              // console.log(node?.Object_Type);
              if (node?.Object_Type === "Post" || node?.Object_Type === "Pre") {
              } else {
                return RenderTree_tab_source_link(node, 0);
              }
            })
          : null}
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Post") {
                return RenderTree_tab_source_link(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
        ? nodes?.Sub_Objects.map((node) => {
            RenderTree(node, 0);
          })
        : null} */}
      </TreeItem>
    );
  };

  const RenderTree_tab_target = (nodes) => {
    return (
      <TreeItem
        key={nodes?.Object_Id}
        nodeId={nodes?.Object_Id}
        onLabelClick={() => {
          handleTreeItemClick_tab_target(nodes?.Object_Type, nodes?.Object_Id);
        }}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "black", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_Type}
            </Typography>
          </div>
        }
      >
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Pre") {
                return RenderTree_tab_target(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
        ? nodes?.Sub_Objects.map((node) => {
            if (node?.Object_Type !== "Pre" || node?.Object_Type !== "Post") {
              return RenderTree(node, 0);
            }
          })
        : null} */}

        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              // console.log(node?.Object_Type);
              if (node?.Object_Type === "Post" || node?.Object_Type === "Pre") {
              } else {
                return RenderTree_tab_target(node, 0);
              }
            })
          : null}
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Post") {
                return RenderTree_tab_target(node, 0);
              }
            })
          : null}
        {/* {Array?.isArray(nodes?.Sub_Objects)
        ? nodes?.Sub_Objects.map((node) => {
            RenderTree(node, 0);
          })
        : null} */}
      </TreeItem>
    );
  };

  const RenderTree_tab_target_link = (nodes) => {
    return (
      <TreeItem
        key={nodes?.Object_Id}
        nodeId={nodes?.Object_Id}
        onLabelClick={() => {
          handleTreeItemClick_tab_target_link(
            nodes?.Object_Type,
            nodes?.Object_Id
          );
        }}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "black", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_Type}
            </Typography>
          </div>
        }
      >
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Pre") {
                return RenderTree_tab_target_link(node, 0);
              }
            })
          : null}

        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              // console.log(node?.Object_Type);
              if (node?.Object_Type === "Post" || node?.Object_Type === "Pre") {
              } else {
                return RenderTree_tab_target_link(node, 0);
              }
            })
          : null}
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              if (node?.Object_Type === "Post") {
                return RenderTree_tab_target_link(node, 0);
              }
            })
          : null}
      </TreeItem>
    );
  };

  const RenderTree = (nodes) => {
    return (
      <TreeItem
        key={nodes?.Object_Type}
        nodeId={nodes?.Object_Type}
        onLabelClick={() =>
          handleTreeItemClick(nodes?.Object_Type, nodes?.Object_Id)
        }
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "black", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_Type}
            </Typography>
          </div>
        }
      >
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              // console.log(node?.Object_Type);
              if (node?.Object_Type === "Post" || node?.Object_Type === "Pre") {
              } else {
                return RenderTree(node, 0);
              }
            })
          : null}
      </TreeItem>
    );
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const handleModelopen = (item) => {
    setRm_admin_email(item.Email);
    setRm_admin_mig(item.Migration_Name);
    // setRm_admin_objectslist
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: item.Email,
      Migration_Name: item.Migration_Name,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(
        `${config.API_BASE_URL()}/api/object_type_list_remove_admin/`,
        form,
        conf
      )
      .then(
        (res) => {
          setRm_admin_objectslist(res.data);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setOpenrm_admin(true);
  };

  const handleModel_objecttypes_edit = (item) => {
    setObjecttypesModel_data(item);
    setOpen1(true);
  };

  const RenderTree1 = (nodes) => {
    // console.log("selection path ", );
    // const history = useHistory();

    return (
      <TreeItem
        key={nodes?.Object_Type}
        nodeId={nodes?.Object_Type}
        onLabelClick={() => handleTreeItemClick1(nodes?.Object_Type)}
        label={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Typography
              variant="body2"
              style={{ color: "black", fontWeight: "inherit", flexGrow: 1 }}
            >
              {nodes?.Object_Type}
            </Typography>
          </div>
        }
      >
        {Array?.isArray(nodes?.Sub_Objects)
          ? nodes?.Sub_Objects.map((node) => {
              // console.log(node?.Object_Type);
              if (node?.Object_Type === "Post" || node?.Object_Type === "Pre") {
              } else {
                return RenderTree1(node, 0);
              }
            })
          : null}
      </TreeItem>
    );
  };
  const handleTreeStructureselected1 = (v) => {
    setSelectedItem1(v);
  };
  const handleTreeStructureselected = (v) => {
    setSelectedItem(v);
  };

  const handleTreeStructureselected_tab_source = (v) => {
    setSelectedItem_tab_source(v);
    setisSelectedItem(true);
  };
  const handleTreeStructureselected_tab_target = (v) => {
    setSelectedItem_tab_target(v);
    setisSelectedItem_target(true);
  };
  const handleTreeStructureselected_tab_source_link = (v) => {
    setSelectedItem_tab_source_link(v);
    setisSelectedItem_link(true);
  };
  const handleTreeStructureselected_tab_target_link = (v) => {
    setSelectedItem_tab_target_link(v);
    setisSelectedItem_target_link(true);
  };
  const handleTreeItemClick1 = (obj) => {
    if (path1) {
      let mod_str = path1.split("/");
      let identified_str = mod_str.pop();
      // console.log(identified_str)
      // console.log(identified_str, '===', obj)
      if (identified_str === obj) {
        setPath1(path1);
      } else {
        setPath1(path1 + "/" + obj);
      }
    } else {
      setPath1(obj);
    }
  };

  const handleTreeItemClick_tab_source = (obj, id) => {
    if (path_tab_source) {
      let mod_str = path_tab_source.split("/");
      let identified_str = mod_str.pop();
      if (identified_str === obj) {
        setPath_tab_source(path_tab_source);
      } else {
        setPath_tab_source(path_tab_source + "/" + obj);
      }
    } else {
      setPath_tab_source(obj);
    }
    setFeature_objid(id);
  };
  const handleTreeItemClick_tab_source_link = (obj, id) => {
    if (path_tab_source_link) {
      let mod_str = path_tab_source_link.split("/");
      let identified_str = mod_str.pop();
      if (identified_str === obj) {
        setPath_tab_source_link(path_tab_source_link);
      } else {
        setPath_tab_source_link(path_tab_source_link + "/" + obj);
      }
    } else {
      setPath_tab_source_link(obj);
    }
    setFeature_objid_link(id);
    objecttypes_linkinglist(headerValue?.Migration_Name, project_version, id);
  };

  const handleTreeItemClick_tab_target = (obj, id) => {
    if (path_tab_target) {
      let mod_str = path_tab_target.split("/");
      let identified_str = mod_str.pop();
      if (identified_str === obj) {
        setPath_tab_target(path_tab_target);
      } else {
        setPath_tab_target(path_tab_target + "/" + obj);
      }
    } else {
      setPath_tab_target(obj);
    }
    setFeature_objid_target(id);
  };

  const handleTreeItemClick_tab_target_link = (obj, id) => {
    if (path_tab_target_link) {
      let mod_str = path_tab_target_link.split("/");
      let identified_str = mod_str.pop();
      if (identified_str === obj) {
        setPath_tab_target_link(path_tab_target_link);
      } else {
        setPath_tab_target_link(path_tab_target_link + "/" + obj);
      }
    } else {
      setPath_tab_target_link(obj);
    }
    setFeature_objid_target_link(id);
  };
  const handleTreeItemClick = (obj, obj_id) => {
    if (path) {
      let mod_str = path.split("/");
      let identified_str = mod_str.pop();
      // console.log(identified_str)
      // console.log(identified_str, '===', obj)
      if (identified_str === obj) {
        setPath(path);
      } else {
        setPath(path + "/" + obj);
      }
    } else {
      setPath(obj);
    }
    setObjectTypesID(obj_id);
    objecttypes_data(project_version, headerValue?.Migration_Name, obj_id);
  };

  const handle_sub_obj = (v) => {
    setSubobjtype(v);
  };
  const clearPath1 = () => {
    // console.log("clear");
    setPath1("");
    // setSubobjtype("");
  };
  const clearPath = () => {
    // console.log("clear");
    setPath("");
    // setSubobjtype("");
  };
  const clearPath_tab_source = () => {
    // console.log("clear");
    setPath_tab_source("");
    // setSubobjtype("");
  };
  const clearPath_tab_source_link = () => {
    // console.log("clear");
    setPath_tab_source_link("");
    // setSubobjtype("");
  };
  const clearPath_tab_target = () => {
    // console.log("clear");
    setPath_tab_target("");
    // setSubobjtype("");
  };
  const clearPath_tab_target_link = () => {
    // console.log("clear");
    setPath_tab_target_link("");
    // setSubobjtype("");
  };

  const handlePearentobjecttypes = (Migration_Name) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Migration_Name: Migration_Name,
      Project_Version_Id: project_version,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/parent_object_list/`, form, conf)
      .then(
        (res) => {
          setParentdropDownList([{ Parent_Object: "ALL" }].concat(res.data));
          // setParentdropDownList(res.data);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };
  const handleMiguseradminlist = (v) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: v?.email,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(
        `${config.API_BASE_URL()}/api/migration_names_list_user_admin/`,
        form,
        conf
      )
      .then(
        (res) => {
          setuseradminmiglist(res.data);
          if (updateDropDownList) {
            dispatch(Menuaction.getdropdownlist(res.data));
          }
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };
  const handleChangeCheckBox = (event) => {
    setCheckbox(event.target.checked);
  };
  // console.log(path,' path')

  const handleDeleteSuObjecttype = (mig, path) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Migration_Name: headerValue?.Migration_Name,
      Project_Version_Id: project_version,
      Object_Path_Str: path,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/sub_object_delete/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
          setTimeout(() => {
            history.push("/");
          }, 1);
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const handleAzure_key_encryption = (migname, migid) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    setNotify({
      isOpen: true,
      message: "Encryption Started will notify once completed",
      type: "info",
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: sessionStorage.getItem("uemail"),
      Migration_Name: headerValue?.Migration_Name,
      Migration_Id: headerValue?.Migration_Id,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/crypto_key_creation/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };
  const handleuseremail2 = (v) => {
    setuseremail2(v?.email);
  };

  const migration_id = (mig) => {
    setMig_id(mig);
  };

  const handleCreateNewVersion = (mig_name_prj) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Migration_Name: headerValue?.Migration_Name,
      Migration_Id: headerValue?.Migration_Id,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/create_project_version/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });

          let body1 = {
            Migration_Name: headerValue?.Migration_Name,
          };
          const form1 = new FormData();
          Object.keys(body1).forEach((key) => {
            form1.append(key, body1[key]);
          });

          axios
            .post(
              `${config.API_BASE_URL()}/api/project_versions_list/`,
              form1,
              conf
            )
            .then(
              (res) => {
                dispatch(Menuaction.getproj_header_dropdownlist(res.data));
                dispatch(
                  Menuaction.prj_version_title(res.data.slice(-1)[0]?.Title)
                );
                dispatch(
                  Menuaction.project_version(res.data.slice(-1)[0]?.Code)
                );
                setTimeout(() => {
                  history.push("/");
                }, 1000);
              },
              (error) => {
                setNotify({
                  isOpen: true,
                  message: "Something Went Wrong Please try Again",
                  type: "error",
                });
              }
            );
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const downloadDoc = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    axios({
      url: `${config.API_BASE_URL()}/api/migadmindoc/`,
      headers: conf.headers,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      FileDownload(response.data, "Migration_Admin_Interface.pdf");
    });
  };

  const call_Features_tab_source = (path_tab_source) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    // console.log(project_version, " project versin");
    let body = {
      Project_Version_Id: project_version,
      Migration_Name: headerValue?.Migration_Name,
      Object_Path_Str: path_tab_source,
      // Object_Id:object_Id
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios.post(`${config.API_BASE_URL()}/api/features_list/`, form, conf).then(
      (res) => {
        // setList_of_features(res.data);
        if (res.data === "Object path does not exist") {
          setNotify({
            isOpen: true,
            message: "Selected Object Path Doesn't Exist",
            type: "error",
          });
        } else if (res.data.length > 0) {
          let data = res.data?.splice(0, res.data?.length - 1);
          setList_of_features(data);
        } else {
          setList_of_features([]);
        }
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something went wrong Please try Again",
          type: "error",
        });
      }
    );
  };

  const call_Features_tab_source_link = (path_tab_source_link) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    // console.log(project_version, " project versin");
    let body = {
      Project_Version_Id: project_version,
      Migration_Name: headerValue?.Migration_Name,
      Object_Path_Str: path_tab_source_link,
      // Object_Id:object_Id
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios.post(`${config.API_BASE_URL()}/api/features_list/`, form, conf).then(
      (res) => {
        // setList_of_features(res.data);
        if (res.data === "Object path does not exist") {
          setNotify({
            isOpen: true,
            message: "Selected Object Path Doesn't Exist",
            type: "error",
          });
        } else if (res.data.length > 0) {
          let data = res.data?.splice(0, res.data?.length - 1);
          setList_of_features(data);
        } else {
          setList_of_features([]);
        }
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something went wrong Please try Again",
          type: "error",
        });
      }
    );
  };

  const feature_copy = (
    source_path,
    target_path,
    sid,
    tid,
    features_json_data
  ) => {
    setNotify({
      isOpen: true,
      message: "Copying Started Will Notify Once Completed",
      type: "info",
    });

    let source_path_f = source_path.split("/").pop();
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Project_Version_Id: project_version,
      Migration_Name: headerValue?.Migration_Name,
      // Object_Path_Str: path_tab_target,
      Feature_Name_List_Json: JSON.stringify(features_json_data),
      Source_Object_Id: sid,
      Target_Object_Id: tid,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios.post(`${config.API_BASE_URL()}/api/feature_copy/`, form, conf).then(
      (res) => {
        dispatch(Menuaction.reloadAction(true));
        setNotify({
          isOpen: true,
          message: "Feature Copied To Destination Path",
          type: "success",
        });
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something went wrong Please try Again",
          type: "error",
        });
      }
    );
  };

  const object_remove_link = (
    path_tab_source_link,
    path_tab_target_link,
    feature_objid_link,
    feature_objid_target_link
  ) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    setNotify({
      isOpen: true,
      message: "Removing Linking Will Notify Once Completed",
      type: "info",
    });

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Project_Version_Id: project_version,
      Migration_Name: headerValue?.Migration_Name,
      Source_Object_Id: feature_objid_link,
      Common_Object_Id: feature_objid_target_link,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/remove_object_link/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };
  const object_link = (
    path_tab_source_link,
    path_tab_target_link,
    feature_objid_link,
    feature_objid_target_link
  ) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    setNotify({
      isOpen: true,
      message: "Linking Started Will Notify Once Completed",
      type: "info",
    });

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Project_Version_Id: project_version,
      Migration_Name: headerValue?.Migration_Name,
      Source_Object_Id: feature_objid_link,
      Common_Object_Id: feature_objid_target_link,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios.post(`${config.API_BASE_URL()}/api/object_link/`, form, conf).then(
      (res) => {
        setNotify({
          isOpen: true,
          message: res.data,
          type: "success",
        });
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something went wrong Please try Again",
          type: "error",
        });
      }
    );
  };

  const handle_multipleValues = (path_source, list) => {
    setFeatures_json_data([]);
    setFeature_name_tab_source_list([]);
    if (list.length > 0) {
      list.map((value, ind) => {
        setFeature_name_tab_source_list((prevArray) => [
          ...prevArray,
          value?.Feature_Name,
        ]);
        setFeatures_json_data((prevArray) => [...prevArray, value]);
      });
    } else {
      setFeatures_json_data([]);
      setFeature_name_tab_source_list([]);
    }
  };
  const objecttypes_data = (prj, mig, pid) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Project_Version_Id: prj,
      Migration_Name: mig,
      Parent_Object_Id: pid,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/object_processing_list/`, form, conf)
      .then(
        (res) => {
          if (res.data.length > 0) {
            setIsObjecttypesData_edit(true);
            setObjecttypesData_edit(res.data);
          } else {
            setIsObjecttypesData_edit(true);
            setObjecttypesData_edit([]);
          }
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  const objecttypes_data_update = (objid, category, pstyle, order) => {
    // console.log(objid, category, pstyle, order)
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    setOpen1(false);
    let body = {
      Object_Id: objid,
      Object_Category: category,
      Object_Process_Style: pstyle,
      Object_Execution_Order: order,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(
        `${config.API_BASE_URL()}/api/update_object_processing_details/`,
        form,
        conf
      )
      .then(
        (res) => {
          objecttypes_data(
            project_version,
            headerValue?.Migration_Name,
            objectTypesID
          );
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };
  // console.log(features_json_data);
  // console.log(feature_name_tab_source_list);

  const handleSelect_objcategory = (value) => {
    setObjecttypesModel_data({
      ...objecttypesModel_data,
      Object_Category: value,
    });
  };
  const handleSelect_processorder = (value) => {
    setObjecttypesModel_data({
      ...objecttypesModel_data,
      Object_Process_Style: value,
    });
  };

  const handleSelect_orderid = (value) => {
    setObjecttypesModel_data({
      ...objecttypesModel_data,
      Object_Execution_Order: value,
    });
  };

  const objecttypes_linkinglist = (mig, prj, pid) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Migration_Name: mig,
      Project_Version_Id: prj,
      Parent_Object_Id: pid,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/object_link_list/`, form, conf)
      .then(
        (res) => {
          if (res.data.length > 0) {
            setObjectLinking_List(res.data);
            setIsObjectLinking_ListData(true);
          }
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something went wrong Please try Again",
            type: "error",
          });
        }
      );
  };

  return (
    <>
      {/* <Box py={1} px={1} style={{ paddingRight: 50 }}> */}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        style={{ marginTop: 10 }}
      >
        <Grid item>
          <div onClick={(e) => downloadDoc()}>
            Document <GetAppIcon style={{ color: "blue", marginBottom: -6 }} />
          </div>
        </Grid>
      </Grid>
      {/* </Box> */}
      <Box>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Object Type Creation</Typography>
          </Grid>
        </Grid>
      </Box>
      <Paper elevation={0} style={{ marginTop: 10, marginBottom: 10 }}>
        <Box py={2} px={2}>
          <Grid container direction="row" justifyContent="center" spacing={1}>
            <Grid item>
              <StyledAutocomplete
                size="small"
                id="grouped-demo"
                // className={classes.inputRoottype}
                options={useradminmiglist}
                groupBy={""}
                defaultValue={{ Migration_Name: headerValue?.Migration_Name }}
                getOptionLabel={(option) => option?.Migration_Name}
                style={{ width: 280 }}
                onChange={(e, v) => {
                  setMigtype_create(v?.Migration_Name);
                  migration_id(v?.Migration_Id);
                  handleGetObjecttypesList(v?.Migration_Name);
                }}
                // value={headerValue?.Migration_Name}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Migration type"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <StyledAutocomplete
                size="small"
                id="grouped-demo"
                // className={classes.inputRoottype}
                options={list_objects}
                groupBy={""}
                // defaultValue={{
                //   Object_Type: list_objects[0]?.Object_Type,
                // }}
                getOptionLabel={(option) => option?.Object_Type}
                onChange={(e, v) => {
                  handleTreeStructureselected(v);
                  objecttypes_data(
                    project_version,
                    headerValue?.Migration_Name,
                    v?.Object_Id
                  );
                  clearPath();
                }}
                style={{ width: 280 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ObjectType"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          {checkbox ? (
            <Grid container direction="row" justifyContent="center">
              <TreeView
                className={classes.root}
                defaultExpanded={["3"]}
                // expanded={true}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
                sx={{
                  height: 264,
                  flexGrow: 1,
                  maxWidth: 400,
                  overflowY: "auto",
                }}
              >
                <>{RenderTree(selectedItem)}</>
              </TreeView>
            </Grid>
          ) : null}
          <Box py={4} px={4}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              style={{ position: "relative" }}
              spacing={5}
            >
              <Grid spacing={3}>
                <Checkbox
                  checked={checkbox}
                  onChange={handleChangeCheckBox}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  style={{ marginTop: 8 }}
                />
                {checkbox ? (
                  <>
                    <TextField
                      id="outlined-multiline-static"
                      label="Selected Path"
                      size="small"
                      style={{ width: 350, marginTop: 8 }}
                      multiline
                      rows={2}
                      // onChange={(e) => handlePath()}
                      name="Selected Path"
                      value={path}
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    />{" "}
                    <TextField
                      id="outlined-multiline-static"
                      label="Sub Object Type"
                      size="small"
                      style={{ width: 350, marginTop: 8 }}
                      multiline
                      rows={2}
                      onChange={(e) => handle_sub_obj(e.target.value)}
                      name="Sub Object Type"
                      // value={path}
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />{" "}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginTop: 20 }}
                      onClick={() => clearPath()}
                    >
                      Clear
                    </Button>
                  </>
                ) : (
                  <>
                    <TextField
                      id="outlined-multiline-static"
                      label="New Object Type"
                      size="small"
                      style={{ width: 350, marginTop: 8 }}
                      multiline
                      rows={1}
                      onChange={(e) => setPath(e.target.value)}
                      name="New Object Type"
                      value={path}
                      className={classes.textField}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />{" "}
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginTop: 5 }}
                      onClick={() => clearPath()}
                    >
                      Clear
                    </Button>
                  </>
                )}{" "}
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              style={{ marginTop: 10, position: "relative" }}
              spacing={1}
            >
              <Grid item>
                <StyledAutocomplete
                  size="small"
                  id="grouped-demo"
                  options={[
                    { title: "Group", code: "Group" },
                    { title: "Individual", code: "Individual" },
                  ]}
                  groupBy={""}
                  // defaultValue={{
                  //   title: "Group",
                  //   code: "Group",
                  // }}
                  getOptionLabel={(option) => option?.code}
                  onChange={(e, v) => {
                    setCategory(v?.code);
                  }}
                  style={{ width: 280 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="ObjectType Category"
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <StyledAutocomplete
                  size="small"
                  id="grouped-demo"
                  options={[
                    { title: "Sequential", code: "Sequential" },
                    { title: "Mutually Exclusive", code: "Mutually Exclusive" },
                  ]}
                  groupBy={""}
                  // defaultValue={{
                  //   title: "Sequential",
                  //   code: "Sequential",
                  // }}
                  getOptionLabel={(option) => option?.code}
                  onChange={(e, v) => {
                    setProcess(v?.code);
                  }}
                  style={{ width: 280 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="ObjectType Process"
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="outlined-multiline-static"
                  label="Object Order ID"
                  size="small"
                  style={{ width: 300 }}
                  multiline
                  rows={1}
                  onChange={(e) => setOrderID(e.target.value)}
                  name="Object Order ID"
                  value={orderID}
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ marginTop: 10, position: "relative" }}
            // spacing={2}
          >
            <Button
              variant="contained"
              color="primary"
              align="center"
              size="small"
              // style={{ marginRight: 20, marginLeft: 100 }}
              onClick={() => handleObjectypeCreate(orderID, process, category)}
            >
              Create
            </Button>
          </Grid>
        </Box>
      </Paper>

      <Box py={2} px={2}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
            >
              Object Types List
            </Typography>
            {loading_otlist ? (
              <>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      <StyledTableCell align="center">
                        Object Type
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Object Category
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Object Process Type
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Object Execution Order
                      </StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isobjecttypesData_edit ? (
                      <>
                        {objecttypesData_edit.map((item) => (
                          <StyledTableRow container>
                            <StyledTableCell item xl={8} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {item.Object_Type}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell item xl={8} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {item.Object_Category}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell item xl={8} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {item.Object_Process_Style}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell item xl={8} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {item.Object_Execution_Order}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell item xl={8} align="center">
                              <Button
                                type="button"
                                size="small"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                style={{
                                  marginTop: "9px",
                                  fontSize: "9px",
                                  marginBottom: "8px",
                                }}
                                onClick={() => {
                                  handleModel_objecttypes_edit(item);
                                }}
                              >
                                Edit
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <StyledTableRow container>
                          <StyledTableCell align="center"></StyledTableCell>
                          <StyledTableCell align="center"></StyledTableCell>
                          <StyledTableCell align="center">
                            {" "}
                            No Requests
                          </StyledTableCell>
                          <StyledTableCell align="center"></StyledTableCell>
                          <StyledTableCell align="center"></StyledTableCell>
                        </StyledTableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </>
            ) : (
              <center>
                <CircularProgress color="primary" style={{ marginTop: 15 }} />
              </center>
            )}
          </Grid>
        </Grid>
      </Box>

      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      ></Snackbar>
      <Modal open={open1}>
        <Container className={classes.container1} style={{ marginBottom: 100 }}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            component="h2"
            style={{ marginBottom: "20px", marginTop: 15 }}
          >
            Edit Data
          </Typography>

          <Grid item xs={12} sm={4} md={4} xl={4}>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={[
                { title: "Group", code: "Group" },
                { title: "Individual", code: "Individual" },
              ]}
              groupBy={""}
              defaultValue={{ title: objecttypesModel_data.Object_Category }}
              // value={objecttypesModel_data.Object_Category}
              getOptionLabel={(option) => option?.title}
              style={{ width: 330, marginTop: 20, marginLeft: 30 }}
              onChange={(e, v) => handleSelect_objcategory(v?.title)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Object Category"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} xl={4}>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={[
                { title: "Sequential", code: "Sequential" },
                { title: "Mutually Exclusive", code: "Mutually Exclusive" },
              ]}
              groupBy={""}
              defaultValue={{
                title: objecttypesModel_data.Object_Process_Style,
              }}
              // value={model_Item.Access_Type}
              getOptionLabel={(option) => option?.title}
              style={{ width: 330, marginTop: 20, marginLeft: 30 }}
              onChange={(e, v) => handleSelect_processorder(v?.title)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Object Type Process"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4} xl={4}>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Execution Order"
              size="small"
              rows={1}
              name="Object_Execution_Order"
              style={{ marginTop: 20, width: 330, marginLeft: 30 }}
              onChange={(e) => handleSelect_orderid(e.target.value)}
              // defaultValue="Default Value"
              defaultValue={objecttypesModel_data.Object_Execution_Order}
              variant="outlined"
              // required
              value={objecttypesModel_data.Object_Execution_Order}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <div className={classes.item}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 20, marginLeft: 100, marginTop: "20px" }}
              onClick={() =>
                // handleUpdateApproval(
                //   model_Item.Expiry_date,
                //   model_Item.Access_Type,
                //   model_Item,
                //   "Pending"
                // )
                objecttypes_data_update(
                  objecttypesModel_data.Object_Id,
                  objecttypesModel_data.Object_Category,
                  objecttypesModel_data.Object_Process_Style,
                  objecttypesModel_data.Object_Execution_Order
                )
              }
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpen1(false)}
              style={{ marginTop: "20px" }}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </Modal>

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Object Admin Creation</Typography>
          </Grid>
        </Grid>
      </Box>
      <Paper elevation={0} style={{ marginTop: 10, marginBottom: 10 }}>
        <Box py={2} px={2}>
          <Grid container direction="row" justifyContent="center" spacing={1}>
            <Grid item>
              <StyledAutocomplete
                size="small"
                id="grouped-demo"
                className={classes.inputRoottype}
                options={userslist}
                groupBy={""}
                getOptionLabel={(option) => option?.email}
                style={{ width: 280 }}
                onChange={(e, v) => {
                  handleuseremail2(v);
                  handleMiguseradminlist(v);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Email"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <StyledAutocomplete
                size="small"
                id="grouped-demo"
                className={classes.inputRoottype}
                options={useradminmiglist}
                groupBy={""}
                // defaultValue={{
                //   Migration_Name: headerValue?.Migration_Name,
                // }}
                getOptionLabel={(option) => option?.Migration_Name}
                style={{ width: 280 }}
                onChange={(e, v) => {
                  setMigtype_create(v?.Migration_Name);
                  migration_id(v?.Migration_Id);
                  handlePearentobjecttypes(v?.Migration_Name);
                }}
                // disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="MigrationTypes"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <StyledAutocomplete
                size="small"
                id="grouped-demo"
                className={classes.inputRoottype}
                options={parentdropDownList}
                groupBy={""}
                style={{ width: 300 }}
                getOptionLabel={(option) => option?.Parent_Object}
                onChange={(e, v) => setParentObject(v?.Parent_Object)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Parent Object Type"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <Grid>
          <Grid container direction="row" justifyContent="center" spacing={1}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              component="span"
              style={{ marginTop: 19, marginBottom: 10 }}
              onClick={() => handlemigrationadmincreation()}
            >
              {" "}
              Create Admin
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Box py={2} px={2}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              align="center"
              variant="h6"
              component="h2"
              className={classes.Object_Type}
            >
              Object Admin List
            </Typography>
          {loading_malist?<> <Table className={classestable.table} aria-label="customized table">
              <TableHead className={classes.primary}>
                <TableRow>
                  <StyledTableCell align="center">
                    Migration Name
                  </StyledTableCell>
                  <StyledTableCell align="center">User Email</StyledTableCell>
                  <StyledTableCell align="center">Object Types</StyledTableCell>
                  <StyledTableCell align="center">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isMigAdminData ? (
                  <>
                    {migAdminList.map((item) => (
                      <StyledTableRow container>
                        <StyledTableCell item xl={8} align="center">
                          {/* <div className={classes.texttablecell}> */}
                          {item.Migration_Name}
                          {/* </div> */}
                        </StyledTableCell>
                        <StyledTableCell item xl={8} align="center">
                          {/* <div className={classes.texttablecell}> */}
                          {item.Email}
                          {/* </div> */}
                        </StyledTableCell>
                        <StyledTableCell item xl={8} align="center">
                          <div className={classes.texttablecell}>
                            {item.Object_Types.map((value, index, array) => {
                              if (array.length - 1 === index) {
                                return value;
                              } else {
                                return value + ",";
                              }
                            })}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell item xl={8} align="center">
                          <Button
                            type="button"
                            size="small"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            style={{
                              marginTop: "9px",
                              fontSize: "9px",
                              marginBottom: "8px",
                            }}
                            onClick={() => {
                              handleModelopen(item);
                            }}
                          >
                            Remove
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </>
                ) : (
                  <>
                    <StyledTableRow container>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align="right">
                        {" "}
                        No Requests
                      </StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                      <StyledTableCell align="center"></StyledTableCell>
                    </StyledTableRow>
                  </>
                )}
              </TableBody>
            </Table></>:<center>
                  <CircularProgress color="primary" style={{ marginTop: 15 }} />
                </center>}
           
          </Grid>
        </Grid>
      </Box>

      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      ></Snackbar>
      <Modal open={openrm_admin}>
        <Container className={classes.container1}>
          <Typography
            gutterBottom
            align="center"
            variant="h6"
            component="h2"
            className={classes.Object_Type}
            style={{ marginBottom: "20px" }}
          >
            Admin Access
          </Typography>
          <Grid item xs={4}>
            <TextField
              id="outlined-multiline-static"
              label="email"
              name="email"
              className={classes.textField}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={rm_admin_email}
              size="small"
              disabled
              style={{ width: 400, marginBottom: "20px", height: "60px" }}
            />
          </Grid>

          <TextField
            id="outlined-multiline-static"
            label="Migration Type"
            name="migrationtype"
            className={classes.textField}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={rm_admin_mig}
            size="small"
            disabled
            style={{ width: 400, marginBottom: "20px", height: "60px" }}
          />

          <Grid item xs={4}>
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.inputRoottype}
              options={rm_admin_objectslist}
              groupBy={""}
              // defaultValue={{ title: "Oracle TO Postgres" }}
              // value={objecttype_rm}
              getOptionLabel={(option) => option.Object_Type}
              style={{ width: 400, marginBottom: "20px", height: "60px" }}
              onChange={(e, v) => setObjecttype_rm_admin(v?.Object_Type)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Object types"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <div className={classes.item}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 20, marginLeft: 100 }}
              onClick={() => handleRm_migadmin()}
            >
              Remove
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenrm_admin(false)}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </Modal>

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Object Type Deletion</Typography>
          </Grid>
        </Grid>
      </Box>
      <Paper elevation={0} style={{ marginTop: 10, marginBottom: 10 }}>
        <Box py={2} px={2}>
          <Grid container direction="row" justifyContent="center" spacing={1}>
            <Grid item>
              <StyledAutocomplete
                size="small"
                id="grouped-demo"
                className={classes.inputRoottype}
                options={useradminmiglist}
                groupBy={""}
                defaultValue={{ Migration_Name: headerValue?.Migration_Name }}
                getOptionLabel={(option) => option?.Migration_Name}
                style={{ width: 300 }}
                onChange={(e, v) => {
                  setMig_subdelete(v?.Migration_Name);
                  handleGetObjecttypesList(v?.Migration_Name);
                }}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Migration type"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <StyledAutocomplete
                size="small"
                id="grouped-demo"
                className={classes.inputRoottype}
                options={list_objects}
                groupBy={""}
                // defaultValue={{
                //   Object_Type: list_objects[0]?.Object_Type,
                // }}
                getOptionLabel={(option) => option?.Object_Type}
                onChange={(e, v) => {
                  handleTreeStructureselected1(v);
                  clearPath1();
                }}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ObjectType"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <Box py={3} px={3}>
          <Grid container direction="row" justifyContent="center">
            <TreeView
              className={classes.root}
              defaultExpanded={["3"]}
              // expanded={true}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 24 }} />}
              sx={{
                height: 264,
                flexGrow: 1,
                maxWidth: 400,
                overflowY: "auto",
              }}
            >
              <>{RenderTree1(selectedItem1)}</>
            </TreeView>
          </Grid>
        </Box>

        <Box py={4} px={4}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ position: "relative" }}
            spacing={2}
          >
            <Grid spacing={3}>
              <>
                <TextField
                  id="outlined-multiline-static"
                  label="Selected Path"
                  size="small"
                  style={{ width: 350, marginTop: 8 }}
                  multiline
                  rows={2}
                  // onChange={(e) => handlePath()}
                  name="Selected Path"
                  value={path1}
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                />{" "}
                {/* <TextField
                id="outlined-multiline-static"
                label="Sub Object Type"
                size="small"
                style={{ width: 350, marginTop: 8 }}
                multiline
                rows={2}
                onChange={(e) => handle_sub_obj(e.target.value)}
                name="Sub Object Type"
                // value={path}
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />{" "} */}
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginTop: 20 }}
                  onClick={() => clearPath1()}
                >
                  Clear
                </Button>{" "}
                <Button
                  variant="contained"
                  // disabled={!selecetd1}
                  color="primary"
                  component="span"
                  size="small"
                  style={{ marginTop: 20, backgroundColor: "red" }}
                  onClick={(e) => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Do You Want Remove the Object Type?",
                      onConfirm: () => {
                        handleDeleteSuObjecttype(mig_subdelete, path1);
                      },
                    });
                  }}
                >
                  {" "}
                  Delete
                </Button>
              </>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ position: "relative", marginBottom: 20 }}
            spacing={2}
          >
            <Grid spacing={3}>
              {/* <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginTop: 20 }}
              onClick={() => clearPath()}
            >
              Clear
            </Button>{" "}
            <Button
              variant="contained"
              // disabled={!selecetd1}
              color="primary"
              component="span"
              size="small"
              style={{ marginTop: 20, backgroundColor: "red" }}
              onClick={(e) => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Do You Want Remove the Object Type?",
                  onConfirm: () => {
                    handleDeleteSuObjecttype(mig_subdelete, path)
                  },
                });
              }}
            >
              {" "}
              Delete
            </Button> */}
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Project Version Creation</Typography>
          </Grid>
        </Grid>
      </Box>

      <Paper elevation={0} style={{ marginTop: 10, marginBottom: 10 }}>
        <Box py={2} px={2} style={{ marginBottom: 10 }}>
          <Grid container direction="row" justifyContent="center" spacing={1}>
            {/* <Grid item style={{ marginTop: 8 }} xs={4}>
            <Typography variant="h7">Project Version Creation :</Typography>
          </Grid> */}

            <Grid item>
              <StyledAutocomplete
                size="small"
                id="grouped-demo"
                className={classes.inputRoottype}
                options={useradminmiglist}
                groupBy={""}
                defaultValue={{ Migration_Name: headerValue?.Migration_Name }}
                getOptionLabel={(option) => option.Migration_Name}
                style={{ width: 300 }}
                onChange={(e, v) => {
                  setSelect_prj_versionitem(v?.Migration_Name);
                  migration_id(v?.Migration_Id);
                }}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Migration Name"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                // disabled={!selecetd1}
                color="primary"
                component="span"
                size="small"
                style={{ marginTop: 5 }}
                // onClick={() => handleCreateNewVersion(select_prj_versionitem)}
                onClick={(e) => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Do You Want Create New Project Version?",
                    onConfirm: () => {
                      handleCreateNewVersion(select_prj_versionitem);
                    },
                  });
                }}
              >
                {" "}
                Create
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Generate Encryption Key</Typography>
          </Grid>
        </Grid>
      </Box>
      <Paper elevation={0} style={{ marginTop: 10, marginBottom: 10 }}>
        <Box style={{ marginTop: 15 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ position: "relative" }}
            spacing={2}
          >
            <Grid item>
              <StyledAutocomplete
                size="small"
                id="grouped-demo"
                className={classes.inputRoottype}
                options={useradminmiglist}
                groupBy={""}
                defaultValue={{ Migration_Name: headerValue?.Migration_Name }}
                getOptionLabel={(option) => option.Migration_Name}
                style={{ width: 300 }}
                onChange={(e, v) => {
                  setAzure_mig_name(v?.Migration_Name);
                  setAzure_mig_id(v?.Migration_Id);
                }}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Migration Name"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                  />
                )}
              />{" "}
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginTop: 5 }}
                // onClick={(e) => handleAzure_key_encryption(azure_mig_name,azure_mig_id)}
                onClick={(e) => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Do You Want Create New Key?",
                    onConfirm: () => {
                      handleAzure_key_encryption(azure_mig_name, azure_mig_id);
                    },
                  });
                }}
              >
                Generate New key
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ marginBottom: 20 }}>
          <Grid container direction="row " justifyContent="center" spacing={2}>
            <h4 style={{ color: "red" }}>
              {/* <pre> */}
              Note: When you click on Generate New Key button, it will encrypt
              the Conversion files with New Generated Key.
              {/* </pre> */}
            </h4>
          </Grid>
        </Box>
      </Paper>
      <Box py={1} px={1} style={{ marginBottom: 10 }}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Feature Copy</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box py={1} px={1} style={{ marginBottom: 20 }}>
        <Grid container direction="row" style={{ marginLeft: "40px" }}>
          <Grid item>
            <Typography variant="h7">
              Source Path: <b>{path_tab_source}</b>
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ marginLeft: "40px" }}>
          <Grid item>
            <Typography variant="h7">
              Selected Source Features List :{" "}
              <b>{feature_name_tab_source_list.join(",")}</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ marginLeft: "40px" }}>
          <Grid item>
            <Typography variant="h7">
              Destination Path: <b>{path_tab_target}</b>
            </Typography>
          </Grid>
        </Grid>

        <div className={classes_tab_source.root}>
          <AppBar position="static">
            <Tabs
              selectionFollowsFocus
              value={value}
              onChange={handleChange_tab_source}
              aria-label="simple tabs example"
              centered
              variant="fullWidth"
            >
              <Tab
                label="Source"
                {...a11yProps(0)}
                onClick={() => {
                  // setPath_tab_target("");
                  setisSelectedItem_target(false);
                }}
              />
              <Tab
                label="Destination"
                {...a11yProps(1)}
                onClick={() => {
                  // setPath_tab_source("");
                  setisSelectedItem(false);
                }}
              />
              <Tab label="COPY" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Box py={2} px={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  <StyledAutocomplete
                    size="small"
                    id="grouped-demo"
                    className={classes.inputRoottype}
                    options={useradminmiglist}
                    groupBy={""}
                    defaultValue={{
                      Migration_Name: headerValue?.Migration_Name,
                    }}
                    getOptionLabel={(option) => option?.Migration_Name}
                    style={{ width: 300 }}
                    disabled
                    onChange={(e, v) => {
                      // setMig_subdelete(v?.Migration_Name);
                      handleGetObjecttypesList(v?.Migration_Name);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Migration type"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item>
                  <StyledAutocomplete
                    size="small"
                    id="grouped-demo"
                    className={classes.inputRoottype}
                    options={list_objects}
                    groupBy={""}
                    // defaultValue={{ title: "Procedure" }}
                    getOptionLabel={(option) => option.Object_Type}
                    style={{ width: 300 }}
                    onChange={(e, v) => {
                      handleTreeStructureselected_tab_source(v);
                      clearPath_tab_source();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ObjectType"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            {isselectedItem ? (
              <>
                <Box py={2} px={2}>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item>
                      <TreeView
                        className={classes.root}
                        defaultExpanded={["3"]}
                        style={{ marginRight: 100 }}
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultEndIcon={<div style={{ width: 24 }} />}
                        sx={{
                          height: 264,
                          flexGrow: 1,
                          maxWidth: 400,
                          overflowY: "auto",
                        }}
                      >
                        {RenderTree_tab_source(selectedItem_tab_source)}
                      </TreeView>
                    </Grid>

                    <Grid item>
                      <TextField
                        id="outlined-multiline-static"
                        label="Selected Path"
                        size="small"
                        style={{ width: 300, marginRight: 180 }}
                        multiline
                        rows={2}
                        // onChange={(e) => handlePath()}
                        name="Selected Path"
                        value={path_tab_source}
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <Button
                        variant="outlined"
                        color="primary"
                        // size='small'
                        style={{ marginTop: 13, marginLeft: "-130px" }}
                        onClick={() => clearPath_tab_source()}
                      >
                        Clear
                      </Button>
                    </Grid>
                    {"  "}
                    <Grid>
                      <Button
                        variant="outlined"
                        color="primary"
                        // size='small'
                        style={{ marginTop: 13, marginRight: "0px" }}
                        onClick={() =>
                          call_Features_tab_source(path_tab_source)
                        }
                      >
                        Get Features
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                <Box py={2} px={2}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Grid>
                      <StyledAutocomplete
                        size="small"
                        id="grouped-demo"
                        multiple
                        filterSelectedOptions
                        className={classes.inputRoottype}
                        options={list_features}
                        // options={list_features.map((option) => option.Feature_Name)}
                        groupBy={""}
                        // defaultValue={{ Feature_Name: "All" }}
                        getOptionLabel={(option) => option?.Feature_Name}
                        style={{ width: 300 }}
                        onChange={(e, v) => {
                          // setFeature_name_tab_source(
                          //   path_tab_source + "/" + v?.Feature_Name
                          // );
                          handle_multipleValues(path_tab_source, v);
                        }}
                        // renderTags={(value, getTagProps) =>
                        //   value.map((option, index) => (
                        //     <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        //   ))
                        // }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Feature Names"
                            variant="outlined"
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : null}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box py={2} px={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  <StyledAutocomplete
                    size="small"
                    id="grouped-demo"
                    className={classes.inputRoottype}
                    options={useradminmiglist}
                    groupBy={""}
                    defaultValue={{
                      Migration_Name: headerValue?.Migration_Name,
                    }}
                    getOptionLabel={(option) => option?.Migration_Name}
                    style={{ width: 300 }}
                    disabled
                    onChange={(e, v) => {
                      // setMig_subdelete(v?.Migration_Name);
                      handleGetObjecttypesList(v?.Migration_Name);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Migration type"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item>
                  <StyledAutocomplete
                    size="small"
                    id="grouped-demo"
                    className={classes.inputRoottype}
                    options={list_objects}
                    groupBy={""}
                    // defaultValue={{ title: "Procedure" }}
                    getOptionLabel={(option) => option.Object_Type}
                    style={{ width: 300 }}
                    onChange={(e, v) => {
                      handleTreeStructureselected_tab_target(v);
                      clearPath_tab_target();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ObjectType"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            {isselectedItem_target ? (
              <>
                <Box py={2} px={2}>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item>
                      <TreeView
                        className={classes.root}
                        defaultExpanded={["3"]}
                        style={{ marginRight: 100 }}
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultEndIcon={<div style={{ width: 24 }} />}
                        sx={{
                          height: 264,
                          flexGrow: 1,
                          maxWidth: 400,
                          overflowY: "auto",
                        }}
                      >
                        {RenderTree_tab_target(selectedItem_tab_target)}
                      </TreeView>
                    </Grid>

                    <Grid item>
                      <TextField
                        id="outlined-multiline-static"
                        label="Selected Path"
                        size="small"
                        style={{ width: 300, marginRight: 180 }}
                        multiline
                        rows={2}
                        // onChange={(e) => handlePath()}
                        name="Selected Path"
                        value={path_tab_target}
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <Button
                        variant="outlined"
                        color="primary"
                        // size='small'
                        style={{ marginTop: 13, marginLeft: "-130px" }}
                        onClick={() => clearPath_tab_target()}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : null}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box py={2} px={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  {path_tab_source && path_tab_target ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() =>
                        feature_copy(
                          feature_name_tab_source,
                          path_tab_target,
                          feature_objid,
                          feature_objid_target,
                          features_json_data
                        )
                      }
                    >
                      COPY Feature
                    </Button>
                  ) : (
                    "Please select Source and Destination Path"
                  )}
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        </div>
      </Box>

      <Box py={1} px={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Typography variant="h6">Object Link</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box py={1} px={1} style={{ marginBottom: 20 }}>
        <Grid container direction="row" style={{ marginLeft: "40px" }}>
          <Grid item>
            <Typography variant="h7">
              Source Path: <b>{path_tab_source_link}</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ marginLeft: "40px" }}>
          <Grid item>
            <Typography variant="h7">
              Common Path which you want to Link To Source:{" "}
              <b>{path_tab_target_link}</b>
            </Typography>
          </Grid>
        </Grid>

        <div className={classes_tab_source.root}>
          <AppBar position="static">
            <Tabs
              selectionFollowsFocus
              value={value_link}
              onChange={handleChange_tab_source_link}
              aria-label="simple tabs example"
              centered
              variant="fullWidth"
            >
              <Tab
                label="Source"
                {...a11yProps_link(0)}
                onClick={() => {
                  // setPath_tab_target("");
                  setIsObjectLinking_ListData(false);
                  setisSelectedItem_target_link(false);
                }}
              />
              <Tab
                label="Destination"
                {...a11yProps_link(1)}
                onClick={() => {
                  // setPath_tab_source_link("");
                  setisSelectedItem_link(false);
                }}
              />
              <Tab label="Mapping" {...a11yProps_link(2)} />
            </Tabs>
          </AppBar>
          <TabPanellink value={value_link} index={0}>
            <Box py={2} px={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  <StyledAutocomplete
                    size="small"
                    id="grouped-demo"
                    className={classes.inputRoottype}
                    options={useradminmiglist}
                    groupBy={""}
                    defaultValue={{
                      Migration_Name: headerValue?.Migration_Name,
                    }}
                    getOptionLabel={(option) => option?.Migration_Name}
                    style={{ width: 300 }}
                    disabled
                    onChange={(e, v) => {
                      handleGetObjecttypesList(v?.Migration_Name);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Migration type"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item>
                  <StyledAutocomplete
                    size="small"
                    id="grouped-demo"
                    className={classes.inputRoottype}
                    options={list_objects}
                    groupBy={""}
                    // defaultValue={{ title: "Procedure" }}
                    getOptionLabel={(option) => option.Object_Type}
                    style={{ width: 300 }}
                    onChange={(e, v) => {
                      handleTreeStructureselected_tab_source_link(v);
                      objecttypes_linkinglist(
                        headerValue?.Migration_Name,
                        project_version,
                        v?.Object_Id
                      );
                      clearPath_tab_source_link();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ObjectType"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            {isselectedItem_link ? (
              <>
                <Box py={2} px={2}>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item>
                      <TreeView
                        className={classes.root}
                        defaultExpanded={["3"]}
                        style={{ marginRight: 100 }}
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultEndIcon={<div style={{ width: 24 }} />}
                        sx={{
                          height: 264,
                          flexGrow: 1,
                          maxWidth: 400,
                          overflowY: "auto",
                        }}
                      >
                        {RenderTree_tab_source_link(
                          selectedItem_tab_source_link
                        )}
                      </TreeView>
                    </Grid>

                    <Grid item>
                      <TextField
                        id="outlined-multiline-static"
                        label="Selected Path"
                        size="small"
                        style={{ width: 300, marginRight: 180 }}
                        multiline
                        rows={2}
                        // onChange={(e) => handlePath()}
                        name="Selected Path"
                        value={path_tab_source_link}
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <Button
                        variant="outlined"
                        color="primary"
                        // size='small'
                        style={{ marginTop: 13, marginLeft: "-130px" }}
                        onClick={() => clearPath_tab_source_link()}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : null}
          </TabPanellink>
          <TabPanellink value={value_link} index={1}>
            <Box py={2} px={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  <StyledAutocomplete
                    size="small"
                    id="grouped-demo"
                    className={classes.inputRoottype}
                    options={useradminmiglist}
                    groupBy={""}
                    defaultValue={{
                      Migration_Name: headerValue?.Migration_Name,
                    }}
                    getOptionLabel={(option) => option?.Migration_Name}
                    style={{ width: 300 }}
                    disabled
                    onChange={(e, v) => {
                      // setMig_subdelete(v?.Migration_Name);
                      handleGetObjecttypesList(v?.Migration_Name);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Migration type"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item>
                  <StyledAutocomplete
                    size="small"
                    id="grouped-demo"
                    className={classes.inputRoottype}
                    options={list_objects}
                    groupBy={""}
                    // defaultValue={{ title: "Procedure" }}
                    getOptionLabel={(option) => option.Object_Type}
                    style={{ width: 300 }}
                    onChange={(e, v) => {
                      handleTreeStructureselected_tab_target_link(v);
                      clearPath_tab_target_link();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ObjectType"
                        variant="outlined"
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>

            {isselectedItem_target_link ? (
              <>
                <Box py={2} px={2}>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item>
                      <TreeView
                        className={classes.root}
                        defaultExpanded={["3"]}
                        style={{ marginRight: 100 }}
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultEndIcon={<div style={{ width: 24 }} />}
                        sx={{
                          height: 264,
                          flexGrow: 1,
                          maxWidth: 400,
                          overflowY: "auto",
                        }}
                      >
                        {RenderTree_tab_target_link(
                          selectedItem_tab_target_link
                        )}
                      </TreeView>
                    </Grid>

                    <Grid item>
                      <TextField
                        id="outlined-multiline-static"
                        label="Selected Path"
                        size="small"
                        style={{ width: 300, marginRight: 180 }}
                        multiline
                        rows={2}
                        // onChange={(e) => handlePath()}
                        name="Selected Path"
                        value={path_tab_target_link}
                        className={classes.textField}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <Button
                        variant="outlined"
                        color="primary"
                        // size='small'
                        style={{ marginTop: 13, marginLeft: "-130px" }}
                        onClick={() => clearPath_tab_target_link()}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : null}
          </TabPanellink>
          <TabPanellink value={value_link} index={2}>
            <Box py={2} px={2}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  {path_tab_source_link && path_tab_target_link ? (
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              "Do You Want Map the Object Type from Source To Destination?",
                            onConfirm: () => {
                              object_link(
                                path_tab_source_link,
                                path_tab_target_link,
                                feature_objid_link,
                                feature_objid_target_link
                              );
                            },
                          });
                        }}
                      >
                        Mapping
                      </Button>{" "}
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={(e) => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              "Do You Want to Remove Object Type Mapping from Source To Destination?",
                            onConfirm: () => {
                              object_remove_link(
                                path_tab_source_link,
                                path_tab_target_link,
                                feature_objid_link,
                                feature_objid_target_link
                              );
                            },
                          });
                        }}
                      >
                        Remove Mapping
                      </Button>
                    </>
                  ) : (
                    "Please select Source and Destination Path"
                  )}
                </Grid>
              </Grid>
            </Box>
          </TabPanellink>
        </div>
      </Box>

      {isselectedItem_link ? (
        <>
          <Box py={1} px={1} style={{ marginBottom: 20 }}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h6"
                  component="h2"
                  // className={classes.Object_Type}
                >
                  Object Linking List
                </Typography>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      <StyledTableCell align="center">
                        Object Type
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Linked Objects Path
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isobjectLinkingListData ? (
                      <>
                        {objectLinkingList.map((item) => (
                          <StyledTableRow container>
                            <StyledTableCell item xl={8} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {item.Object_Type}
                              {/* </div> */}
                            </StyledTableCell>
                            <StyledTableCell item xl={8} align="center">
                              {/* <div className={classes.texttablecell}> */}
                              {item.Linked_Objects}
                              {/* </div> */}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <StyledTableRow container>
                          <StyledTableCell align="center"></StyledTableCell>
                          <StyledTableCell align="left">
                            No requests
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
