import React, { useEffect, useState } from "react";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import Notification from "../Notifications/Notification";
import Card from "@material-ui/core/Card";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import TableBody from "@material-ui/core/TableBody";
// import fileDownload from "js-file-download";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Box, Grid, Paper, TextField, Button, styled } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CardActionArea from "@material-ui/core/CardActionArea";
import Container from "@material-ui/core/Container";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import { useHistory, Link } from "react-router-dom";
// import fileDownload from "js-file-download";
import API_BASE_URL from "../../Config/config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import config from "../../Config/config";
import ActionMenu from "../../../src/Redux/actions/Menuaction";
import { Label } from "@material-ui/icons";
import fileSaver from "file-saver";
const useStylestable = makeStyles({
  table: {
    minWidth: 100,
    // width:10
  },
});
const useStylestable1 = makeStyles({
  table: {
    minWidth: 100,
    // width:10
    width: "92%",
    marginLeft: "auto",
    marginRight: "auto",
  },
});
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "180px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "120px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },

  texttablecell: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    width: "140px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // '&:hover': {
    //     overflow: 'visible'
    // }
  },
  root: {
    // display: "flex",
  },
  lineheight: {
    lineHeight: "30px",
  },

  Object_Type: {
    margin: "16px 0",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0em",
    paddingLeft: 1,
  },

  Description: {
    margin: "0 0 40px",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: "0em",
    paddingLeft: 3,
  },

  SourceDescription: {
    display: "block",
    lineHeight: 1.5,
    fontSize: "1.15rem",
    borderRadius: "10px",
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
    marginInlineStart: "0px",
    marginInlineEnd: " 0px",
    webkitJustifyContent: "center",
    justifyContent: "center",
    padding: "20px",
    backgroundColor: "#E7EBF0",
    paddingLeft: 30,
  },

  SourceCode: {
    margin: "24px auto",
    padding: "24px",
    fontSize: "1.35rem",
    color: "#FFF",
    overflow: "auto",
    direction: "ltr",
    maxHeight: "500px",
    lineHeight: 2,
    maxWidth: "calc(78vw - 32px)",
    borderRadius: " 5px",
    backgroundColor: "#383f4a",
    webkitOverflowScrolling: "touch",
  },

  SourceCode_text: {
    margin: "24px auto",
    padding: "24px",
    fontSize: "1.35rem",
    color: "white",
    overflow: "auto",
    direction: "ltr",
    maxHeight: "500px",
    lineHeight: 2,
    maxWidth: "calc(78vw - 32px)",
    borderRadius: " 5px",
    backgroundColor: "black",
    webkitOverflowScrolling: "touch",
  },

  Editpart: {
    borderRadius: "5px",
    justifyItems: "center",
    padding: "10px 5px 0px 5px",
    // backgroundColor: "#E7EBF0",
  },
}));
const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "blue",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5",
    },
  },
});

export default function PreviewCode(props) {
  const detaildata = props?.location?.data?.Fdata
//   console.log(detaildata,'fasdfgh')
  const classes = useStyles();
  const classtable = useStylestable1();
  const classestable = useStylestable();
//   const [detaildata, setDetaildata] = useState();
  // const id = props.InfoId;
  let history = useHistory();
  const [isdata, setIsdata] = useState(false);
  const dispatch = useDispatch();
  const [source_att, setSource_att] = useState([]);
  const [target_att, setTarget_att] = useState([]);
  const [conv_att, setConv_att] = useState([]);
  const { menuitem, objectid, object_path } = useSelector(
    (state) => state.dashboardReducer
  );
  // console.log(object_path, 'obj path')
  const [issattdata, setIssattdata] = useState(false);
  const [iscattdata, setIscattdata] = useState(false);
  const [istattdata, setIstattdata] = useState(false);

  const [source_codeatt, setSource_codeatt] = useState([]);
  const [target_acodeatt, setTarget_acodeatt] = useState([]);
  const [target_ecodeatt, setTarget_ecodeatt] = useState([]);
  const [isscattdata, setIsscattdata] = useState(false);
  const [istaattdata, setIstaattdata] = useState(false);
  const [istettdata, setIstettdata] = useState(false);
  const {
    details,
    createFeature,
    preview,
    editpreview,
    editPreviewdetails,
    headerValue,
    lable,
    project_version,
  } = useSelector((state) => state.dashboardReducer);
  const [migtypeid, setMigtypeid] = useState(headerValue?.Migration_Name);
  const [objtype, setObjtype] = useState();
  const [max_flag_ver, setMax_flag_ver] = useState();
  const [fnnames, setFnnames] = useState([]);
  const [fnname, setFnname] = useState();
  const [checkIsEdit, setCheckIsEdit] = useState(0);
  const [versionSelect, setVersionSelect] = useState("");
  const [fversionslist, setFversionslist] = useState([]);
  const [latest_flag, setLatest_flag] = useState(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [att_update, setAtt_update] = useState(false);


  useEffect(() => {
    if (detaildata) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      axios
        .get(
          `${config.API_BASE_URL()}/api/Source_Description/${
            detaildata.Feature_Id
          }/`,
          conf
        )
        .then(
          (res) => {
            setSource_att(res.data);
            if (res.data.length > 0) {
              setIssattdata(true);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [detaildata, att_update]);
  useEffect(() => {
    if (detaildata) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      axios
        .get(
          `${config.API_BASE_URL()}/api/Target_Description/${
            detaildata.Feature_Id
          }/`,
          conf
        )
        .then(
          (res) => {
            setTarget_att(res.data);
            if (res.data.length > 0) {
              setIstattdata(true);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [detaildata, att_update]);

  useEffect(() => {
    if (detaildata) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      axios
        .get(
          `${config.API_BASE_URL()}/api/Conversion_Code/${
            detaildata.Feature_Id
          }/`,
          conf
        )
        .then(
          (res) => {
            setConv_att(res.data);
            if (res.data.length > 0) {
              setIscattdata(true);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [detaildata, att_update]);

  useEffect(() => {
    if (detaildata) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      axios
        .get(
          `${config.API_BASE_URL()}/api/attachments_pattern/${
            detaildata.Feature_Id
          }/`,
          conf
        )
        .then(
          (res) => {
            setSource_codeatt(res.data);
            console.log(res.data);
            if (res.data.length > 0) {
              setIsscattdata(true);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [detaildata, att_update]);

  const handleDownload = (att_Type, att_name, fid) => {
    let body = {
      File_Name: att_name,
      // Migration_Name: migtypeid,
      // object_type: obj_type,
      Attachment_Type: att_Type,
      // id: id,
      // fname: editdata.detaildata.Feature_Name,
      Feature_Id: fid,
      responseType: "blob",
    };
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    // console.log(conf.headers)
    // axios
    //   .post(`${config.API_BASE_URL()}/api/download_att`, body, conf)
    //   .then((res) => {
    //     fileDownload(res.data, att_name);
    //     // const content = res.headers['content-type'];
    //     // download(res.data, att_name, content)
    //   })
    //   .catch((err) => { });

    axios
      .post(`${config.API_BASE_URL()}/api/attachment_download/`, body, conf,{
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileSaver.saveAs(blob, att_name);
      })
      .catch((err) => {});
  };

  const handleAttachment_delete = (id) => {
    // console.log(id, 'id ')
    let formData = {
      Attachment_Id: id,
    };
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/attachment_delete/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "File Deleted",
            type: "success",
          });
          setAtt_update(true);
        },
        (error) => {
          console.log(error);
          setNotify({
            isOpen: true,
            message: "Something Went Wrong! Please try Again",
            type: "error",
          });
          setAtt_update(true);
        }
      );
    setAtt_update(false);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#3f51b5",
      color: theme.palette.common.white,
    },
    root: {
      padding: "0px 16px",
    },

    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },

      height: 10,
    },
  }))(TableRow);



  var data = null;
  let seq = null;
  if (detaildata) {
    seq = detaildata.Sequence;

    data = (
      <div className={classes.container}>
        <Box py={1} px={1} style={{marginTop:10, marginLeft:35}}>
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={3}>
              <Typography variant="h7" component="h7">
                <strong>Created By :</strong> {detaildata?.Feature_Created_by}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h7">
                <strong> Created Date :</strong>{" "}
                {detaildata?.Feature_Created_at}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography variant="h7">
                <strong>Modified By :</strong> {detaildata?.Last_Modified_by}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h7">
                <strong>Modified Date :</strong> {detaildata?.Last_Modified_at}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Table className={classtable.table} aria-label="customized table" style={{marginTop:20}}>
            <TableHead className={classes.primary}>
              <TableRow>
                <StyledTableCell align="center">Feature Name</StyledTableCell>
                <StyledTableCell align="center">keywords</StyledTableCell>
                <StyledTableCell align="center">Estimation</StyledTableCell>
                <StyledTableCell align="center">Predecessor</StyledTableCell>
                <StyledTableCell align="center">% Automation</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow container>
                <StyledTableCell item xl={10} align="center">
                  <Box flexDirection="row">
                    <div>{detaildata?.Feature_Name}</div>
                  </Box>
                </StyledTableCell>
                <StyledTableCell item xl={10} align="center">
                  <Box flexDirection="row">
                    <div>
                      {detaildata?.Keywords}
                    </div>
                  </Box>
                </StyledTableCell>
                <StyledTableCell item xl={2} align="center">
                <Box flexDirection="row">
                    <div >
                      {detaildata?.Estimations}
                    </div>
                  </Box>
                </StyledTableCell>
                <StyledTableCell item xl={2} align="center">
                <Box flexDirection="row">
                    <div>
                      {detaildata?.Sequence}
                    </div>
                  </Box>
                </StyledTableCell>
                <StyledTableCell item xl={2} align="center">
                <Box flexDirection="row">
                    <div>
                      {detaildata?.Automation_Percentage}
                    </div>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

        <Grid container>
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={11} sm={11} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Source Feature Description
              </Typography>
              <div>
                <Card className={classes.SourceCode_text}>
                  <pre className={classes.lineheight}>
                    <code>{detaildata.Source_FeatureDescription}</code>
                  </pre>
                </Card>
              </div>
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <Grid item xs={11} sm={11} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Target Feature Description
              </Typography>
              <div>
                <Card className={classes.SourceCode_text}>
                  <pre className={classes.lineheight}>
                    <code>{detaildata.Target_FeatureDescription}</code>
                  </pre>
                </Card>
              </div>
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <Grid item xs={11} sm={11} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Source Code
              </Typography>
              <div>
                <Card className={classes.SourceCode}>
                  <pre className={classes.lineheight}>
                    <code>{detaildata.Source_Code}</code>
                  </pre>
                </Card>
              </div>
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Target Expected Code
              </Typography>
              <div>
                <Card className={classes.SourceCode}>
                  {/* <Typography component="h2"> */}
                  <pre className={classes.lineheight}>
                    <code>{detaildata.Target_Expected_Output}</code>
                  </pre>
                </Card>
              </div>
              {/* </Typography> */}
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Target Actual Code
              </Typography>
              <div>
                <Card className={classes.SourceCode}>
                  {/* <Typography component="h2"> */}
                  <pre className={classes.lineheight}>
                    <code>{detaildata.Target_ActualCode}</code>
                  </pre>
                </Card>
              </div>
              {/* </Typography> */}
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Conversion Module
              </Typography>
              <div>
                <Card className={classes.SourceCode}>
                  {/* <Typography component="h2"> */}
                  <pre className={classes.lineheight}>
                    <code>{detaildata.Conversion_Code}</code>
                  </pre>
                </Card>
              </div>
              {/* </Typography> */}
            </Grid>
          </Grid>
        </Grid>

        <Box py={2} px={2}>
          <Grid container direction="row" justifyContent="center">
            <Grid container xl={12} justifyContent="space-between" spacing={1}>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h6"
                  component="h2"
                  className={classes.Object_Type}
                >
                  SQL Code Attachemnets
                </Typography>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      {/* <StyledTableCell align="center">Type</StyledTableCell> */}
                      <StyledTableCell align="left">File Name</StyledTableCell>
                      <StyledTableCell align="left">
                        Source Code
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Expected Target Code
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Actual Target Code
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">Actions</StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isscattdata ? (
                      <>
                        {source_codeatt.map((row) => (
                          <StyledTableRow container>
                            {/* <StyledTableCell item xl={5}>
                                                    <div className={classes.texttablecell}>{row.AttachmentType}</div>
                                                </StyledTableCell> */}
                            <StyledTableCell item xl={10}>
                              <div className={classes.texttablecell}>
                                {row.Filename}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell item xl={10}>
                              <div className={classes.texttablecell}>
                                <Box flexDirection="row">
                                  {row.Sourcecode}
                                  {row.Sourcecode == "Y" ? (
                                    <>
                                      {/* <IconButton
                                        onClick={() =>
                                          handleAttachment_delete(row.sid)
                                        }
                                      >
                                        <DeleteIcon style={{ color: "red" }} />
                                      </IconButton> */}
                                      <IconButton
                                        onClick={(e) =>
                                          handleDownload(
                                            "Sourcecode",
                                            row.Filename,
                                            detaildata.Feature_Id
                                          )
                                        }
                                      >
                                        <GetAppIcon style={{ color: "blue" }} />
                                      </IconButton>{" "}
                                    </>
                                  ) : null}
                                </Box>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell item xl={10}>
                              <div className={classes.texttablecell}>
                                <Box flexDirection="row">
                                  {row.Expectedconversion}
                                  {row.Expectedconversion == "Y" ? (
                                    <>
                                      {/* <IconButton
                                        onClick={() => {
                                          handleAttachment_delete(row.etid);
                                        }}
                                      >
                                        <DeleteIcon style={{ color: "red" }} />
                                      </IconButton> */}
                                      <IconButton
                                        onClick={(e) =>
                                          handleDownload(
                                            "Expectedconversion",
                                            row.Filename,
                                            detaildata.Feature_Id
                                          )
                                        }
                                      >
                                        <GetAppIcon style={{ color: "blue" }} />
                                      </IconButton>{" "}
                                    </>
                                  ) : null}
                                </Box>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell item xl={10}>
                              <div className={classes.texttablecell}>
                                <Box flexDirection="row">
                                  {row.Actualtargetcode}
                                  {row.Actualtargetcode == "Y" ? (
                                    <>
                                      {/* <IconButton
                                        onClick={() => {
                                          handleAttachment_delete(row.atid);
                                        }}
                                      >
                                        <DeleteIcon style={{ color: "red" }} />
                                      </IconButton> */}
                                      <IconButton
                                        onClick={(e) =>
                                          handleDownload(
                                            "Actualtargetcode",
                                            row.Filename,
                                            detaildata.Feature_Id
                                          )
                                        }
                                      >
                                        <GetAppIcon style={{ color: "blue" }} />
                                      </IconButton>{" "}
                                    </>
                                  ) : null}
                                </Box>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="left">No Data</StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box py={2} px={2}>
          <Grid container xl={12} justifyContent="space-between" spacing={1}>
            <Grid container direction="row" justifyContent="center">
              <Grid item xl={4} xs={12} sm={12} md={4}>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h6"
                  component="h2"
                  className={classes.Object_Type}
                >
                  Source Description
                </Typography>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      <StyledTableCell align="center">File</StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {issattdata ? (
                      <>
                        {source_att.map((row) => (
                          <StyledTableRow container>
                            <StyledTableCell item xl={10}>
                              <div className={classes.texttablecell}>
                                {row.Filename}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell item xl={2}>
                              <Box flexDirection="row">
                                {/* <IconButton
                                  onClick={() =>
                                    handleAttachment_delete(row.id)
                                  }
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </IconButton> */}
                                <IconButton
                                  onClick={(e) =>
                                    handleDownload(
                                      row.Attachment_Type,
                                      row.Filename,
                                      detaildata.Feature_Id
                                    )
                                  }
                                >
                                  <GetAppIcon style={{ color: "blue" }} />
                                </IconButton>
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        {/* <StyledTableCell align="center"></StyledTableCell> */}
                        <StyledTableCell align="right">No Data</StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xl={4} xs={12} sm={12} md={4}>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h6"
                  component="h5"
                  className={classes.Object_Type}
                >
                  Target Description
                </Typography>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      {/* <StyledTableCell align="center">Type</StyledTableCell> */}
                      <StyledTableCell align="center">File</StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {istattdata ? (
                      <>
                        {target_att.map((row) => (
                          <StyledTableRow container>
                            {/* <StyledTableCell item xl={5}>
                                                    <div className={classes.texttablecell}>{row.AttachmentType}</div>
                                                </StyledTableCell> */}
                            <StyledTableCell item xl={10}>
                              <div className={classes.texttablecell}>
                                {row.Filename}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell item xl={2}>
                              <Box flexDirection="row">
                                {/* <IconButton
                                  onClick={(e) =>
                                    handleAttachment_delete(row.id)
                                  }
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </IconButton> */}
                                <IconButton
                                  onClick={(e) =>
                                    handleDownload(
                                      row.Attachment_Type,

                                      row.Filename,
                                      detaildata.Feature_Id
                                    )
                                  }
                                >
                                  <GetAppIcon style={{ color: "blue" }} />
                                </IconButton>
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        {/* <StyledTableCell align="center"></StyledTableCell> */}
                        <StyledTableCell align="right">No Data</StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xl={4} xs={12} sm={12} md={4}>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h6"
                  component="h2"
                  className={classes.Object_Type}
                >
                  Conversion Module
                </Typography>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      {/* <StyledTableCell align="center">Type</StyledTableCell> */}
                      <StyledTableCell align="center">File</StyledTableCell>
                      <StyledTableCell align="center">Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {iscattdata ? (
                      <>
                        {conv_att.map((row) => (
                          <StyledTableRow container>
                            {/* <StyledTableCell item xl={5}>
                                                    <div className={classes.texttablecell}>{row.AttachmentType}</div>
                                                </StyledTableCell> */}
                            <StyledTableCell item xl={10}>
                              <div className={classes.texttablecell}>
                                {row.Filename}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell item xl={2}>
                              <Box flexDirection="row">
                                {/* <IconButton
                                  onClick={(e) =>
                                    handleAttachment_delete(row.id)
                                  }
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </IconButton> */}
                                <IconButton
                                  onClick={(e) =>
                                    handleDownload(
                                      row.Attachment_Type,

                                      row.Filename,
                                      detaildata.Feature_Id
                                    )
                                  }
                                >
                                  <GetAppIcon style={{ color: "blue" }} />
                                </IconButton>
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        {/* <StyledTableCell align="center"></StyledTableCell> */}
                        <StyledTableCell align="right">No Data</StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
        </Box>

      
      </div>
    );
  }

  return (
    <Box style={{ width: "97%", marginLeft: 10 }}>
      {data}
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}
