import React from "react";
import Footer from "../Components/Footer";

import MenuAppBar from "../Components/header";
import PreviewCode from "./Modules/PreviewCode";
import CreateFeature from '../Features/Modules/CreateFeature'
import { useSelector } from "react-redux";
import EditFeature from "./Modules/EditFeature";
import { useHistory } from "react-router-dom";
export default function Home() {
  // console.log('home')
  const { details, createFeature, preview, editpreview, editPreviewdetails, headerValue } = useSelector(state => state.dashboardReducer);
  let history = useHistory();
  
  return (
    <div>
      <>
      </>
    </div>
  );
}
