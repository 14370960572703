import React, { useEffect, useState } from "react";
import axios from "axios";
import IconButton from "@material-ui/core/IconButton";
import Notification from "../Notifications/Notification";
import Card from "@material-ui/core/Card";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import TableBody from "@material-ui/core/TableBody";
// import fileDownload from "js-file-download";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Box, Grid, Paper, TextField, Button, styled } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CardActionArea from "@material-ui/core/CardActionArea";
import Container from "@material-ui/core/Container";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import { useHistory, Link } from "react-router-dom";
// import fileDownload from "js-file-download";
import API_BASE_URL from "../../Config/config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import config from "../../Config/config";
import ActionMenu from "../../../src/Redux/actions/Menuaction";
import { Label } from "@material-ui/icons";
import fileSaver from "file-saver";
const useStylestable1 = makeStyles({
  table: {
    minWidth: 100,
    // width:10
    width: "92%",
    marginLeft: "auto",
    marginRight: "auto",
  },
});
const useStylestable = makeStyles({
  table: {
    minWidth: 100,
    // width:10
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
});
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "180px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "120px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },

  texttablecell: {
    // overflowX: "hidden",
    whiteSpace: "nowrap",
    width: "140px",
    // overflow: "hidden",
    textOverflow: "ellipsis",
    // '&:hover': {
    //     overflow: 'visible'
    // }
  },
  root: {
    // display: "flex",
  },
  lineheight: {
    lineHeight: "30px",
  },

  Object_Type: {
    margin: "16px 0",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0em",
    paddingLeft: 1,
  },

  Description: {
    margin: "0 0 40px",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: "0em",
    paddingLeft: 3,
  },

  SourceDescription: {
    display: "block",
    lineHeight: 1.5,
    fontSize: "1.15rem",
    borderRadius: "10px",
    marginBlockStart: "1em",
    marginBlockEnd: "1em",
    marginInlineStart: "0px",
    marginInlineEnd: " 0px",
    webkitJustifyContent: "center",
    justifyContent: "center",
    padding: "20px",
    backgroundColor: "#E7EBF0",
    paddingLeft: 20,
  },

  SourceCode: {
    margin: "24px auto",
    padding: "24px",
    fontSize: "1.35rem",
    color: "#FFF",
    overflow: "auto",
    direction: "ltr",
    maxHeight: "500px",
    lineHeight: 2,
    maxWidth: "calc(78vw - 32px)",
    borderRadius: " 5px",
    backgroundColor: "#383f4a",
    webkitOverflowScrolling: "touch",
  },

  SourceCode_text: {
    margin: "24px auto",
    padding: "24px",
    fontSize: "1.35rem",
    color: "white",
    overflow: "auto",
    direction: "ltr",
    maxHeight: "500px",
    lineHeight: 2,
    maxWidth: "calc(78vw - 32px)",
    borderRadius: " 5px",
    backgroundColor: "black",
    webkitOverflowScrolling: "touch",
  },

  Editpart: {
    borderRadius: "5px",
    justifyItems: "center",
    padding: "10px 5px 0px 5px",
    // backgroundColor: "#E7EBF0",
  },
}));
const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "blue",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5",
    },
  },
});

export default function PreviewCode(props) {
  const classes = useStyles();
  const classestable = useStylestable();
  const classtable = useStylestable1();
  const [detaildata, setDetaildata] = useState();
  // const id = props.InfoId;
  let history = useHistory();
  const [isdata, setIsdata] = useState(false);
  const dispatch = useDispatch();
  const [source_att, setSource_att] = useState([]);
  const [target_att, setTarget_att] = useState([]);
  const [conv_att, setConv_att] = useState([]);
  const { menuitem, objectid } = useSelector((state) => state.dashboardReducer);

  const [issattdata, setIssattdata] = useState(false);
  const [iscattdata, setIscattdata] = useState(false);
  const [istattdata, setIstattdata] = useState(false);

  const [source_codeatt, setSource_codeatt] = useState([]);
  const [target_acodeatt, setTarget_acodeatt] = useState([]);
  const [target_ecodeatt, setTarget_ecodeatt] = useState([]);
  const [isscattdata, setIsscattdata] = useState(false);
  const [istaattdata, setIstaattdata] = useState(false);
  const [istettdata, setIstettdata] = useState(false);
  const {
    details,
    createFeature,
    preview,
    editpreview,
    editPreviewdetails,
    headerValue,
    lable,
    project_version,
    prj_max_flag,
    mig_id,
  } = useSelector((state) => state.dashboardReducer);
  const [migtypeid, setMigtypeid] = useState(headerValue?.Migration_Name);
  const [objtype, setObjtype] = useState();
  const [max_flag_ver, setMax_flag_ver] = useState(false);
  const [fnnames, setFnnames] = useState([]);
  const [fnname, setFnname] = useState();
  const [checkIsEdit, setCheckIsEdit] = useState();
  const [versionSelect, setVersionSelect] = useState("");
  const [fversionslist, setFversionslist] = useState([]);
  const [latest_flag, setLatest_flag] = useState(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [att_update, setAtt_update] = useState(false);

  // useEffect(() => {
  //   if (menuitem) {
  //     let conf = {
  //       headers: {
  //         Authorization: "Bearer " + config.ACCESS_TOKEN(),
  //       },
  //     };

  //     let body = {
  //       Migration_Type: headerValue?.Migration_Name,
  //       Object_Type: lable,
  //       Feature_Name: menuitem,
  //       Project_Version_Id: project_version,
  //     };
  //     const form = new FormData();
  //     Object.keys(body).forEach((key) => {
  //       form.append(key, body[key]);
  //     });

  //     axios.post(`${config.API_BASE_URL()}/api/fversions/`, form, conf).then(
  //       (res) => {
  //         setFversionslist(res.data);
  //         if (res.data.length > 0) {
  //           Object.keys(res.data).forEach((key) => {
  //             setVersionSelect(String(res.data[key]?.Migration_Name));
  //           });
  //           // setVersionSelect(String(res.data.length))
  //         }
  //         // else {
  //         //   setVersionSelect(String(1))
  //         // }
  //       },
  //       (error) => {
  //         setNotify({
  //           isOpen: true,
  //           message: "Something Went Wrong Please try Again",
  //           type: "error",
  //         });
  //       }
  //     );
  //   } else {
  //     setDetaildata();
  //   }
  // }, [menuitem]);

  useEffect(() => {
    if (menuitem) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };

      let body = {
        Object_Id: objectid,
        User_Email: sessionStorage.getItem("uemail"),
        Migration_Name: headerValue?.Migration_Name,
        // Object_Type_String: object_path,
      };
      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(
          `${config.API_BASE_URL()}/api/feature_detail/${menuitem || null}/`,
          form,
          conf
        )
        .then(
          (res) => {
            // console.log(res.data);
            // // Object.keys(res.data).forEach((val) => {
            // // if (res.data[val]?.Max_Flag === 1) {

            // setDetaildata(res.data[0]);
            // setFnname(res.data[0].Feature_Name);
            // setIsdata(true);

            setFversionslist(res.data);
            Object.keys(res.data).forEach((val) => {
              if (res.data[val]?.Max_Version === true) {
                setDetaildata(res.data[val]);
                setFnname(res.data[val]?.Feature_Name);
                setIsdata(true);
                setCheckIsEdit(res.data[val]?.Access_Flag);
                // setLatest_flag(res.data[val]?.Latest_Flag)
                setMax_flag_ver(res.data[val]?.Max_Version);
                // Max_Project_Flag
                setVersionSelect(res.data[val]?.Feature_Version_Id);
              }
            });
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    } else {
      setDetaildata();
    }
  }, [menuitem, att_update]);

  // const handleObjecttype = (v) => {
  //   setObjtype(v.Object_Type)
  // }

  useEffect(() => {
    // console.log("menus ", menuitem);
    if (detaildata) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      axios
        .get(
          `${config.API_BASE_URL()}/api/Source_Description/${
            detaildata.Feature_Id
          }/`,
          conf
        )
        .then(
          (res) => {
            setSource_att(res.data);
            if (res.data.length > 0) {
              setIssattdata(true);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [detaildata, att_update]);
  useEffect(() => {
    if (detaildata) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      axios
        .get(
          `${config.API_BASE_URL()}/api/Target_Description/${
            detaildata.Feature_Id
          }/`,
          conf
        )
        .then(
          (res) => {
            setTarget_att(res.data);
            if (res.data.length > 0) {
              setIstattdata(true);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [detaildata, att_update]);

  useEffect(() => {
    if (detaildata) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      axios
        .get(
          `${config.API_BASE_URL()}/api/Conversion_Code/${
            detaildata.Feature_Id
          }/`,
          conf
        )
        .then(
          (res) => {
            setConv_att(res.data);
            if (res.data.length > 0) {
              setIscattdata(true);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [detaildata, att_update]);

  useEffect(() => {
    if (detaildata) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      axios
        .get(
          `${config.API_BASE_URL()}/api/attachments_pattern/${
            detaildata.Feature_Id
          }/`,
          conf
        )
        .then(
          (res) => {
            setSource_codeatt(res.data);
            console.log(res.data);
            if (res.data.length > 0) {
              setIsscattdata(true);
            }
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [detaildata, att_update]);

  const handleDownload = (att_Type, att_name, fid) => {
    let body = {
      File_Name: att_name,
      // Migration_Name: migtypeid,
      // object_type: obj_type,
      Attachment_Type: att_Type,
      // id: id,
      // fname: editdata.detaildata.Feature_Name,
      Feature_Id: fid,
      responseType: "blob",
    };
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    // console.log(conf.headers)
    // axios
    //   .post(`${config.API_BASE_URL()}/api/download_att`, body, conf)
    //   .then((res) => {
    //     fileDownload(res.data, att_name);
    //     // const content = res.headers['content-type'];
    //     // download(res.data, att_name, content)
    //   })
    //   .catch((err) => { });

    axios
      .post(`${config.API_BASE_URL()}/api/attachment_download/`, body, conf, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileSaver.saveAs(blob, att_name);
      })
      .catch((err) => {});
  };

  const handleAttachment_delete = (id) => {
    // console.log(id, 'id ')
    let formData = {
      Attachment_Id: id,
    };
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/attachment_delete/`, form, conf)
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "File Deleted",
            type: "success",
          });
          setAtt_update(true);
        },
        (error) => {
          console.log(error);
          setNotify({
            isOpen: true,
            message: "Something Went Wrong! Please try Again",
            type: "error",
          });
          setAtt_update(true);
        }
      );
    setAtt_update(false);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#3f51b5",
      color: theme.palette.common.white,
    },
    root: {
      padding: "0px 16px",
    },

    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },

      height: 10,
    },
  }))(TableRow);

  const handleRequestAccess = () => {
    let body = {
      // Object_Type: objtype,
      // Project_Version_Id: project_version,
      // Migration_Name: headerValue?.Migration_Name,
      // User_Email: sessionStorage.getItem("uemail"),
      // Feature_Name: fnname,
      // Approval_Status: "Pending",
      // Access_Type: "Edit",

      Project_Version_Id: project_version,
      User_Email: sessionStorage.getItem("uemail"),
      Migration_Name: headerValue?.Migration_Name,
      // Approval_Request: fnname,
      Object_Id: objectid,
      Feature_Name: fnname,
      Approval_Status: "Pending",
      Access_Type: "Edit",
      Migration_Id: mig_id,
    };
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(
        `${config.API_BASE_URL()}/api/approval_request_create_preview/`,
        form,
        conf
      )
      .then(
        (res) => {
          if (
            res.data ===
            "Approval request already present, Please wait for admin to approve"
          ) {
            setNotify({
              isOpen: true,
              message: res.data,
              type: "error",
            });
          } else {
            setNotify({
              isOpen: true,
              message: "Request Sent Please Wiat For The Admin Approval",
              type: "success",
            });
          }
        },
        (error) => {
          console.log(error);
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please Try Again!",
            type: "error",
          });
        }
      );
  };

  const handleFeatureversion = (versionnumber) => {
    setVersionSelect(String(versionnumber));
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let body = {
      Object_Id: objectid,
      User_Email: sessionStorage.getItem("uemail"),
      Migration_Name: headerValue?.Migration_Name,
      // Object_Type_String: object_path,
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(
        `${config.API_BASE_URL()}/api/feature_detail/${menuitem}/`,
        form,
        conf
      )
      .then(
        (res) => {
          // console.log(res.data, versionnumber);

          Object.keys(res.data).forEach((val) => {
            // console.log(String(res.data[val]?.Feature_Version_Id) === versionnumber)
            if (String(res.data[val]?.Feature_Version_Id) === versionnumber) {
              console.log(res.data[val]?.Max_Version, 1);
              setDetaildata(res.data[val]);
              setFnname(res.data[val]?.Feature_Name);
              // setObjtype(res.data[val]?.serializer?.Object_Type);
              setIsdata(true);
              setCheckIsEdit(res.data[val]?.Access_Flag);
              // setLatest_flag(res.data[val]?.Latest_Flag);
              setMax_flag_ver(res.data[val]?.Max_Version);
            }
          });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  };
  // console.log(fversionslist, "targt att");
  console.log(max_flag_ver, "targt att");
  var data = null;
  let seq = null;
  if (detaildata) {
    seq = detaildata.Sequence;

    data = (
      <div className={classes.container}>
        <Grid container>
          <Grid
            container
            justifyContent="flex-end"
            style={{ paddingTop: 19 }}
            spacing={2}
          >
            <Grid item>
              <StyledAutocomplete
                size="small"
                id="grouped-demo"
                className={classes.inputRoottype}
                options={fversionslist}
                groupBy={""}
                defaultValue={{ Feature_Version_Id: String(versionSelect) }}
                value={versionSelect}
                getOptionLabel={(option) =>
                  option?.Feature_Version_Id?.toString()
                }
                style={{ width: 110 }}
                onChange={(e, v) =>
                  handleFeatureversion(v?.Feature_Version_Id?.toString())
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Versions"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                    placeholder={String(versionSelect)}
                  />
                )}
              />
            </Grid>

            {checkIsEdit === 2 || String(checkIsEdit) === "Admin" ? (
              <>
                {" "}
                {max_flag_ver ? (
                  <>
                    {prj_max_flag ? (
                      <>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{ marginRight: 60 }}
                            startIcon={<EditSharpIcon />}
                            onClick={
                              () => {
                                dispatch(
                                  ActionMenu.EditPreviewFeature({
                                    data: detaildata,
                                  })
                                );

                                history.push("/EditFeature");
                              }
                              // history.push({
                              //   pathname: `/edit/${detaildata.Feature_Id}`,
                              //   data: { detaildata },

                              // })
                            }
                          >
                            Edit
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{ marginRight: 60 }}
                            startIcon={<EditSharpIcon />}
                            disabled
                            onClick={
                              () => {
                                dispatch(
                                  ActionMenu.EditPreviewFeature({
                                    data: detaildata,
                                  })
                                );

                                history.push("/EditFeature");
                              }
                              // history.push({
                              //   pathname: `/edit/${detaildata.Feature_Id}`,
                              //   data: { detaildata },

                              // })
                            }
                          >
                            Edit
                          </Button>
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ marginRight: 60 }}
                        startIcon={<EditSharpIcon />}
                        disabled
                        onClick={
                          () => {
                            dispatch(
                              ActionMenu.EditPreviewFeature({
                                data: detaildata,
                              })
                            );

                            history.push("/EditFeature");
                          }
                          // history.push({
                          //   pathname: `/edit/${detaildata.Feature_Id}`,
                          //   data: { detaildata },

                          // })
                        }
                      >
                        Edit
                      </Button>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <>
                {" "}
                {max_flag_ver ? (
                  <>
                    {prj_max_flag ? (
                      <>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            component="span"
                            style={{ marginRight: 60 }}
                            onClick={(e) => {
                              handleRequestAccess("Edit");
                            }}
                          >
                            Request Edit Access
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            component="span"
                            style={{ marginRight: 60 }}
                            disabled
                            onClick={(e) => {
                              handleRequestAccess("Edit");
                            }}
                          >
                            Request Edit Access
                          </Button>
                        </Grid>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        style={{ marginRight: 60 }}
                        disabled
                        onClick={(e) => {
                          handleRequestAccess("Edit");
                        }}
                      >
                        Request Edit Access
                      </Button>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>

          {/* <Box py={2} px={12}> */}
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={4}
            style={{ marginTop: 10, marginLeft: 35 }}
          >
            <Grid item xs={3}>
              {/* <Typography variant="h7" component="h7"> */}
              <strong>Created By :</strong> {detaildata?.Feature_Created_by}
              {/* </Typography> */}
            </Grid>
            <Grid item xs={3}>
              {/* <Typography variant="h7"> */}
              <strong> Created Date :</strong> {detaildata?.Feature_Created_at}
              {/* </Typography> */}
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h7">
                <strong>Modified By :</strong> {detaildata?.Last_Modified_by}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h7">
                <strong>Modified Date :</strong> {detaildata?.Last_Modified_at}
              </Typography>
            </Grid>
          </Grid>

          <Table
            className={classtable.table}
            aria-label="customized table"
            style={{ marginTop: 20 }}
          >
            <TableHead className={classes.primary}>
              <TableRow>
                <StyledTableCell align="center">Feature Name</StyledTableCell>
                <StyledTableCell align="center">keywords</StyledTableCell>
                <StyledTableCell align="center">Estimation</StyledTableCell>
                <StyledTableCell align="center">Predecessor</StyledTableCell>
                <StyledTableCell align="center">% Automation</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow container>
                <StyledTableCell item xl={10} align="center">
                  <Box flexDirection="row">
                    <div>{detaildata?.Feature_Name}</div>
                  </Box>
                </StyledTableCell>
                <StyledTableCell item xl={10} align="center">
                  <Box flexDirection="row">
                    <div>{detaildata?.Keywords}</div>
                  </Box>
                </StyledTableCell>
                <StyledTableCell item xl={2} align="center">
                  <Box flexDirection="row">
                    <div>{detaildata?.Estimations}</div>
                  </Box>
                </StyledTableCell>
                <StyledTableCell item xl={2} align="center">
                  <Box flexDirection="row">
                    <div>{detaildata?.Sequence}</div>
                  </Box>
                </StyledTableCell>
                <StyledTableCell item xl={2} align="center">
                  <Box flexDirection="row">
                    <div>{detaildata?.Automation_Percentage}</div>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>

          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Source Feature Description
              </Typography>
              <div className={classes.SourceDescription}>
                <div className="App">
                  <CKEditor
                    editor={ClassicEditor}
                    data={detaildata.Source_FeatureDescription}
                    onReady={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                    disabled="true"
                  />
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Target Feature Description
              </Typography>
              <div className={classes.SourceDescription}>
                <CKEditor
                  editor={ClassicEditor}
                  data={detaildata.Target_FeatureDescription}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                  disabled="true"
                />
              </div>
            </Grid>
          </Grid>

          {/* <Grid container direction="row" justifyContent="center">
            <Grid item xs={11} sm={11} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Source Feature Description
              </Typography>
              <div>
                <Card className={classes.SourceCode_text}>
                  <pre className={classes.lineheight}>
                    <code>{detaildata.Source_FeatureDescription}</code>
                  </pre>
                </Card>
              </div>
            </Grid>
          </Grid> */}

          {/* <Grid container direction="row" justifyContent="center">
            <Grid item xs={11} sm={11} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Target Feature Description
              </Typography>
              <div>
                <Card className={classes.SourceCode_text}>
                  <pre className={classes.lineheight}>
                    <code>{detaildata.Target_FeatureDescription}</code>
                  </pre>
                </Card>
              </div>
            </Grid>
          </Grid> */}

          <Grid container direction="row" justifyContent="center">
            <Grid item xs={11} sm={11} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Source Code
              </Typography>
              <div>
                <Card className={classes.SourceCode}>
                  <pre className={classes.lineheight}>
                    <code>{detaildata.Source_Code}</code>
                  </pre>
                </Card>
              </div>
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Target Expected Code
              </Typography>
              <div>
                <Card className={classes.SourceCode}>
                  {/* <Typography component="h2"> */}
                  <pre className={classes.lineheight}>
                    <code>{detaildata.Target_Expected_Output}</code>
                  </pre>
                </Card>
              </div>
              {/* </Typography> */}
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Target Actual Code
              </Typography>
              <div>
                <Card className={classes.SourceCode}>
                  {/* <Typography component="h2"> */}
                  <pre className={classes.lineheight}>
                    <code>
                      {detaildata.Target_Actual_Output !== 'null'
                        ? detaildata?.Target_Actual_Output
                        : ""}
                    </code>
                  </pre>
                </Card>
              </div>
              {/* </Typography> */}
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={11}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.Object_Type}
              >
                Conversion Module
              </Typography>
              <div>
                <Card className={classes.SourceCode}>
                  {/* <Typography component="h2"> */}
                  <pre className={classes.lineheight}>
                    <code>{detaildata.Conversion_Code}</code>
                  </pre>
                </Card>
              </div>
              {/* </Typography> */}
            </Grid>
          </Grid>
        </Grid>

        <Box py={2} px={2}>
          <Grid container direction="row" justifyContent="center">
            <Grid container xl={12} justifyContent="space-between" spacing={1}>
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h6"
                  component="h2"
                  className={classes.Object_Type}
                >
                  SQL Code Attachemnets
                </Typography>
                <Table
                  className={classestable.table}
                  aria-label="customized table"
                >
                  <TableHead className={classes.primary}>
                    <TableRow>
                      {/* <StyledTableCell align="center">Type</StyledTableCell> */}
                      <StyledTableCell align="left">File Name</StyledTableCell>
                      <StyledTableCell align="left">
                        Source Code
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Expected Target Code
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Actual Target Code
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">Actions</StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isscattdata ? (
                      <>
                        {source_codeatt.map((row) => (
                          <StyledTableRow container>
                            {/* <StyledTableCell item xl={5}>
                                                    <div className={classes.texttablecell}>{row.AttachmentType}</div>
                                                </StyledTableCell> */}
                            <StyledTableCell item xl={10}>
                              <div className={classes.texttablecell}>
                                {row.Filename}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell item xl={10}>
                              <div className={classes.texttablecell}>
                                <Box flexDirection="row">
                                  {row.Sourcecode}
                                  {row.Sourcecode == "Y" ? (
                                    <>
                                      <IconButton
                                        onClick={() =>
                                          handleAttachment_delete(row.sid)
                                        }
                                      >
                                        <DeleteIcon style={{ color: "red" }} />
                                      </IconButton>
                                      <IconButton
                                        onClick={(e) =>
                                          handleDownload(
                                            "Sourcecode",
                                            row.Filename,
                                            detaildata.Feature_Id
                                          )
                                        }
                                      >
                                        <GetAppIcon style={{ color: "blue" }} />
                                      </IconButton>{" "}
                                    </>
                                  ) : null}
                                </Box>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell item xl={10}>
                              <div className={classes.texttablecell}>
                                <Box flexDirection="row">
                                  {row.Expectedconversion}
                                  {row.Expectedconversion == "Y" ? (
                                    <>
                                      <IconButton
                                        onClick={() => {
                                          handleAttachment_delete(row.etid);
                                        }}
                                      >
                                        <DeleteIcon style={{ color: "red" }} />
                                      </IconButton>
                                      <IconButton
                                        onClick={(e) =>
                                          handleDownload(
                                            "Expectedconversion",
                                            row.Filename,
                                            detaildata.Feature_Id
                                          )
                                        }
                                      >
                                        <GetAppIcon style={{ color: "blue" }} />
                                      </IconButton>{" "}
                                    </>
                                  ) : null}
                                </Box>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell item xl={10}>
                              <div className={classes.texttablecell}>
                                <Box flexDirection="row">
                                  {row.Actualtargetcode}
                                  {row.Actualtargetcode == "Y" ? (
                                    <>
                                      <IconButton
                                        onClick={() => {
                                          handleAttachment_delete(row.atid);
                                        }}
                                      >
                                        <DeleteIcon style={{ color: "red" }} />
                                      </IconButton>
                                      <IconButton
                                        onClick={(e) =>
                                          handleDownload(
                                            "Actualtargetcode",
                                            row.Filename,
                                            detaildata.Feature_Id
                                          )
                                        }
                                      >
                                        <GetAppIcon style={{ color: "blue" }} />
                                      </IconButton>{" "}
                                    </>
                                  ) : null}
                                </Box>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                        <StyledTableCell align="left">No Data</StyledTableCell>
                        <StyledTableCell align="center"></StyledTableCell>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box py={2} px={2}>
          <Grid container xl={12} justifyContent="space-between" spacing={1}>
            <Grid item xl={4} xs={12} sm={12} md={4}>
              <Typography
                gutterBottom
                align="center"
                variant="h6"
                component="h2"
                className={classes.Object_Type}
              >
                Source Description
              </Typography>
              <Table
                className={classestable.table}
                aria-label="customized table"
              >
                <TableHead className={classes.primary}>
                  <TableRow>
                    <StyledTableCell align="center">File</StyledTableCell>
                    <StyledTableCell align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {issattdata ? (
                    <>
                      {source_att.map((row) => (
                        <StyledTableRow container>
                          <StyledTableCell item xl={10}>
                            <div className={classes.texttablecell}>
                              {row.Filename}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell item xl={2}>
                            <Box flexDirection="row">
                              <IconButton
                                onClick={() => handleAttachment_delete(row.id)}
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </IconButton>
                              <IconButton
                                onClick={(e) =>
                                  handleDownload(
                                    row.Attachment_Type,
                                    row.Filename,
                                    detaildata.Feature_Id
                                  )
                                }
                              >
                                <GetAppIcon style={{ color: "blue" }} />
                              </IconButton>
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* <StyledTableCell align="center"></StyledTableCell> */}
                      <StyledTableCell align="right">No Data</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </>
                  )}
                </TableBody>
              </Table>
            </Grid>

            <Grid item xl={4} xs={12} sm={12} md={4}>
              <Typography
                gutterBottom
                align="center"
                variant="h6"
                component="h5"
                className={classes.Object_Type}
              >
                Target Description
              </Typography>
              <Table
                className={classestable.table}
                aria-label="customized table"
              >
                <TableHead className={classes.primary}>
                  <TableRow>
                    {/* <StyledTableCell align="center">Type</StyledTableCell> */}
                    <StyledTableCell align="center">File</StyledTableCell>
                    <StyledTableCell align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {istattdata ? (
                    <>
                      {target_att.map((row) => (
                        <StyledTableRow container>
                          {/* <StyledTableCell item xl={5}>
                                                    <div className={classes.texttablecell}>{row.AttachmentType}</div>
                                                </StyledTableCell> */}
                          <StyledTableCell item xl={10}>
                            <div className={classes.texttablecell}>
                              {row.Filename}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell item xl={2}>
                            <Box flexDirection="row">
                              <IconButton
                                onClick={(e) => handleAttachment_delete(row.id)}
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </IconButton>
                              <IconButton
                                onClick={(e) =>
                                  handleDownload(
                                    row.Attachment_Type,

                                    row.Filename,
                                    detaildata.Feature_Id
                                  )
                                }
                              >
                                <GetAppIcon style={{ color: "blue" }} />
                              </IconButton>
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* <StyledTableCell align="center"></StyledTableCell> */}
                      <StyledTableCell align="right">No Data</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </>
                  )}
                </TableBody>
              </Table>
            </Grid>

            <Grid item xl={3} xs={12} sm={12} md={4}>
              <Typography
                gutterBottom
                align="center"
                variant="h6"
                component="h2"
                className={classes.Object_Type}
              >
                Conversion Module
              </Typography>
              <Table
                className={classestable.table}
                aria-label="customized table"
              >
                <TableHead className={classes.primary}>
                  <TableRow>
                    {/* <StyledTableCell align="center">Type</StyledTableCell> */}
                    <StyledTableCell align="center">File</StyledTableCell>
                    <StyledTableCell align="center">Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {iscattdata ? (
                    <>
                      {conv_att.map((row) => (
                        <StyledTableRow container>
                          {/* <StyledTableCell item xl={5}>
                                                    <div className={classes.texttablecell}>{row.AttachmentType}</div>
                                                </StyledTableCell> */}
                          <StyledTableCell item xl={10}>
                            <div className={classes.texttablecell}>
                              {row.Filename}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell item xl={2}>
                            <div className={classes.texttablecell}>
                              <Box flexDirection="row">
                                <IconButton
                                  onClick={(e) =>
                                    handleAttachment_delete(row.id)
                                  }
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </IconButton>
                                <IconButton
                                  onClick={(e) =>
                                    handleDownload(
                                      row.Attachment_Type,

                                      row.Filename,
                                      detaildata.Feature_Id
                                    )
                                  }
                                >
                                  <GetAppIcon style={{ color: "blue" }} />
                                </IconButton>
                              </Box>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* <StyledTableCell align="center"></StyledTableCell> */}
                      <StyledTableCell align="right">No Data</StyledTableCell>
                      <StyledTableCell align="right"></StyledTableCell>
                    </>
                  )}
                </TableBody>
              </Table>
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Box>

        <Grid container justifyContent="center" spacing={1}>
          <Grid item style={{ marginTop: "10px" }}>
            <Grid item>
              {checkIsEdit === 2 || String(checkIsEdit) === "Admin" ? (
                <>
                  {max_flag_ver ? (
                    <>
                      {prj_max_flag ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<EditSharpIcon />}
                            onClick={() => {
                              dispatch(
                                ActionMenu.EditPreviewFeature({
                                  data: detaildata,
                                })
                              );

                              history.push("/EditFeature");
                            }}
                          >
                            Edit
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            startIcon={<EditSharpIcon />}
                            disabled
                            onClick={() => {
                              dispatch(
                                ActionMenu.EditPreviewFeature({
                                  data: detaildata,
                                })
                              );

                              history.push("/EditFeature");
                            }}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        startIcon={<EditSharpIcon />}
                        disabled
                        onClick={() => {
                          dispatch(
                            ActionMenu.EditPreviewFeature({
                              data: detaildata,
                            })
                          );

                          history.push("/EditFeature");
                        }}
                      >
                        Edit
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {max_flag_ver ? (
                    <>
                      {prj_max_flag ? (
                        <>
                          {" "}
                          <Button
                            variant="outlined"
                            color="primary"
                            component="span"
                            onClick={(e) => {
                              handleRequestAccess("Edit");
                            }}
                          >
                            Request Edit Access
                          </Button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <Button
                            variant="outlined"
                            color="primary"
                            component="span"
                            onClick={(e) => {
                              handleRequestAccess("Edit");
                            }}
                            disabled
                          >
                            Request Edit Access
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                        disabled
                        onClick={(e) => {
                          handleRequestAccess("Edit");
                        }}
                      >
                        Request Edit Access
                      </Button>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <Box style={{ width: "100%" }}>
      {data}
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}
