import { useHistory } from "react-router-dom";
import fileSaver from "file-saver";
import { Container, Modal, Snackbar } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import ConfirmDialog from "../../Features/Notifications/ConfirmDialog";
import { Box, Grid, TextField, Typography, styled } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Notification from "../Notifications/Notification";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { TableContainer } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import config from "../../Config/config";

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    // Default transform is "translate(14px, 20px) scale(1)""
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "black",
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5",
    },
  },
});

const useStylestable = makeStyles((theme) => ({
  table: {
    // minWidth: 650
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 300,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
  },
  root: {
    padding: "0px 16px",
  },

  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // height: 10,
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  container1: {
    border: "none",
    borderRadius: 15,
    width: 400,
    height: 280,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  Accesslistcontainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "120px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "50px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },

  texttablecell: {
    // overflowX: "hidden",
    whiteSpace: "nowrap",
    // width: "190px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // "&:hover": {
    //   overflow: "visible",
    // },
  },

  table: {
    // minWidth: 150,
    width: "90%",
    height: "10%",
    border: "1px black",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
  rootc: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function Dynamic_Rules(props) {
  const history = useHistory();
  const classes = useStyles();
  let obj_type_id = props.location?.data?.obj_id;
  const classestable = useStylestable();
  let obj = props.location.data?.obj;
  let obj_parent = props.location.data?.obj_id;
  const {
    details,
    createFeature,
    preview,
    editpreview,
    editPreviewdetails,
    headerValue,
    project_version,
    DropDownValues,
    lable,
    mig_id,
  } = useSelector((state) => state.dashboardReducer);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [DropDownList, setDropdownList] = useState([
    { title: "Comment_Identifiers" },
    // { title: "New_Line_Identifiers" },
    { title: "Object_Identifiers" },
  ]);
  const [drop, setDrop] = useState();
  const [droptitle, setDroptitle] = useState();
  const [dropDownValue, setDropDownValue] = useState();
  const [rulesyntax, setRulesyntax] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [isData, setIsData] = useState(false);
  const [ruleslist, setRuleslist] = useState([]);
  const [ruleid, setRuleid] = useState();
  const [isRulesData, setIsRulesData] = useState(false);
  const [loading_RulesData, setLoading_RulesData] = useState(false);
  const [callRuleslist, setcallRuleslist] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [deleteruleslist, setDeleteruleslist] = useState();
  const [attachementslist, setAttachementslist] = useState([]);
  const [att_table_update, setAtt_table_update] = useState(false);
  const [source_code, setSource_code] = useState();
  const [target_Code, setTarget_Code] = useState();
  const [target_Code1, setTarget_Code1] = useState();
  const [apply_rule_data, setApply_rule_data] = useState();
  const [dynamic_rulesdata, setDynamic_rulesdata] = useState();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  // useEffect(() => {
  //   if (obj === undefined) {
  //     history.push("/dashboard");
  //   }
  // }, [obj]);

  useEffect(() => {
    if (obj_type_id) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      let body = {
        Object_Id: obj_type_id,
        Migration_Name: headerValue?.Migration_Name,
      };

      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(`${config.API_BASE_URL()}/api/object_rule_list/`, form, conf)
        .then(
          (res) => {
            setRuleslist(res.data);
            if (res.data.length > 0) {
              setIsRulesData(true);
              setRuleid(res.data[0]?.Rule_Id);
            } else {
              setIsRulesData(false);
            }
            setLoading_RulesData(true);
            // setIsData(true);

            //   setNotify({
            //     isOpen: true,
            //     message: "Rule Added",
            //     type: "success",
            //   });
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
            setLoading_RulesData(false);
          }
        );
    }
  }, [callRuleslist, obj_type_id]);

  useEffect(() => {
    if (obj_type_id) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      let body = {
        Object_Id: obj_type_id,
      };

      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(`${config.API_BASE_URL()}/api/dynamic_rules_records/`, form, conf)
        .then(
          (res) => {
            setDynamic_rulesdata(res.data);
            setSource_code(res.data[0]?.Rules_Source);
            setTarget_Code(res.data[0]?.Rules_Target);
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [obj_type_id]);

  useEffect(() => {
    if (ruleid) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      let body = {};
      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });
      axios
        .post(
          `${config.API_BASE_URL()}/api/rules_attachment_pattern/${ruleid}`,
          form,
          conf
        )
        .then(
          (res) => {
            setAttachementslist(res.data);
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [ruleid, att_table_update]);

  const handleAddrules = (dropDownValue, rulesyntax) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Object_Id: obj_type_id,
      Migration_Name: headerValue?.Migration_Name,
      Rule_Expression: rulesyntax,
      Rule_Type: dropDownValue,
      Migration_Id: mig_id,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/dynamic_rules_creation/`, form, conf)
      .then(
        (res) => {
          setcallRuleslist(true);
          setNotify({
            isOpen: true,
            message: "Dynamic Rules added successfully",
            type: "success",
          });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setcallRuleslist(false);
  };

  const handleDeleteRule = (row) => {
    setConfirmDialog({
      confirmDialog,
      isOpen: false,
    });
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Object_Id: obj_type_id,
      Migration_Name: headerValue?.Migration_Name,
      Rule_Type: row.Rule_Type,
      Rule: row.Rule,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios.post(`${config.API_BASE_URL()}/api/rule_deletion/`, form, conf).then(
      (res) => {
        //   setDeleteruleslist(res.data);
        setcallRuleslist(true);
        setNotify({
          isOpen: true,
          message: res.data,
          type: "success",
        });
      },
      (error) => {
        setNotify({
          isOpen: true,
          message: "Something Went Wrong Please try Again",
          type: "error",
        });
      }
    );
    setcallRuleslist(false);
  };

  const handleDeleteAttac = (type, filename) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      Rules_Attachment_Type: type,
      Filename: filename,
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(
        `${config.API_BASE_URL()}/api/rules_attachment_delete/${ruleid}`,
        form,
        conf
      )
      .then(
        (res) => {
          setAtt_table_update(true);
          setNotify({
            isOpen: true,
            message: res.data,
            type: "success",
          });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
    setAtt_table_update(false);
  };

  const handleChangedrop = (v) => {
    setDrop(v.code);
    setDroptitle(v.title);
  };

  const handleSubmitdrpm = (e, ruleid) => {
    var filesub = [];
    if (drop === "Rules_Source_Code") {
      const { files } = e.target;
      if (files.length > 0) {
        const fileatt = e.target.files[0];
        filesub.push(fileatt);
        // setFupdate(true);
      }
    } else {
      const { files } = e.target;
      if (files.length > 0) {
        const fileatt = e.target.files[0];
        filesub.push(fileatt);
        // setFupdate(true);
      }
    }

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let formData = {
      Attachment_Type: drop,
      Attachment: filesub[0],
      File_Name: filesub[0].name,
      Migration_Name: headerValue?.Migration_Name,
      Migration_Id: mig_id,
      Object_Id: obj_type_id,
      //   Object_Id: obj_type_id,
      //   Migration_Id: mig_id,
    };

    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    axios
      .post(
        `${config.API_BASE_URL()}/api/rules_attachment_create/${ruleid}`,
        form,
        conf
      )
      .then(
        (res) => {
          setAtt_table_update(true);
          setNotify({
            isOpen: true,
            message: droptitle + " Attachment Upload Successfully",
            type: "success",
          });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please Try Again",
            type: "error",
          });
        }
      );
    setAtt_table_update(false);
  };

  const handleFileDownload = (type, filename) => {
    let body = {
      Rules_Attachment_Type: type,
      Filename: filename,
    };

    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    axios
      .post(
        `${config.API_BASE_URL()}/api/rules_attachment_download/${ruleid}`,
        body,
        conf,
        {
          responseType: "arraybuffer",
        }
      )
      .then((res) => {
        let att_name = filename;
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileSaver.saveAs(blob, att_name);
      })
      .catch((err) => {});
  };

  const handleSave = (ruleid, source, target) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let formData = {
      Rules_Source: source,
      Rules_Target: target,
    };
    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    axios
      .post(
        `${config.API_BASE_URL()}/api/dynamic_rules_update/${ruleid}`,
        form,
        conf
      )
      .then(
        (res) => {
          setNotify({
            isOpen: true,
            message: "Updated Successfully",
            type: "success",
          });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong",
            type: "error",
          });
        }
      );
  };

  const handleApplyRules = (source) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let formData = {
      Migration_Name: headerValue?.Migration_Name,
      Object_Id: obj_type_id,
      Rules_Id: ruleid,
      Source_Data: source,
    };

    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/raw_apply_rules/`, form, conf)
      .then(
        (res) => {
          if (res.data === "Please add source code to apply rules") {
            setNotify({
              isOpen: true,
              message: "Please Add Source Code to Apply Rules",
              type: "success",
            });
          } else {
            // setApply_rule_data(res.data);
            setTarget_Code(res.data[0]);
            setTarget_Code1(res.data[1]);
            setNotify({
              isOpen: true,
              message: "Rules Applied Successfully",
              type: "success",
            });
          }
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong",
            type: "error",
          });
        }
      );
  };

  const handlefileapply = (type) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let formData = {
      Migration_Name: headerValue?.Migration_Name,
      Object_Id: obj_type_id,
      Rules_Id: ruleid,
      Source_Attachment_Type: type,
    };

    const form = new FormData();
    Object.keys(formData).forEach((key) => {
      form.append(key, formData[key]);
    });
    axios
      .post(`${config.API_BASE_URL()}/api/files_apply_rules/`, form, conf)
      .then(
        (res) => {
          setAtt_table_update(true);
          setNotify({
            isOpen: true,
            message:
              "Rules Applied For File Please check the Target Attachment",
            type: "success",
          });
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong",
            type: "error",
          });
        }
      );
    setAtt_table_update(false);
  };

  return (
    <div style={{ width: "99%" }}>
      <Box>
        <Box py={1} px={1}>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <Typography variant="h6">
                {/* {prepathdata} */}
                {props.location.data?.obj} Rules
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box py={2} px={2}>
          <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item>
              <StyledAutocomplete
                size="small"
                id="grouped-demo"
                className={classes.inputRoottype}
                options={DropDownList}
                groupBy={""}
                getOptionLabel={(option) => option.title}
                style={{ width: 300, marginTop: 8 }}
                onChange={(e, v) => setDropDownValue(v?.title)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Rule Type"
                    variant="outlined"
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <TextField
                id="outlined-multiline-static"
                label="Rules Syntax"
                size="small"
                style={{ width: 400, marginTop: 8 }}
                multiline
                rows={1}
                // value={textrulesenter || ""}
                onChange={(e) => setRulesyntax(e.target.value)}
                name="Rules Syntax"
                className={classes.textField}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                component="span"
                size="small"
                style={{ marginTop: 11 }}
                onClick={() => handleAddrules(dropDownValue, rulesyntax)}
              >
                {" "}
                Add Rule
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box py={2} px={2}>
          <Grid container xl={12} justifyContent="space-between" spacing={2}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                align="center"
                variant="h6"
                component="h2"
                className={classes.Object_Type}
              >
                Object Rules List
              </Typography>
              {loading_RulesData ? (
                <>
                  <TableContainer className={classestable.table}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead className={classes.primary}>
                        <TableRow>
                          <StyledTableCell align="center">
                            Rule Type
                          </StyledTableCell>
                          <StyledTableCell align="center">Rule</StyledTableCell>
                          <StyledTableCell align="center">
                            Actions
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isRulesData ? (
                          <>
                            {ruleslist.map((row) => (
                              <>
                                <>
                                  <StyledTableRow container>
                                    <StyledTableCell
                                      item
                                      xl={10}
                                      align="center"
                                    >
                                      {/* <div className={classes.texttablecell}> */}
                                      <pre>{row.Rule_Type}</pre>
                                      {/* </div> */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      item
                                      xl={10}
                                      align="center"
                                    >
                                      <div className={classes.texttablecell}>
                                        {row.Rule}
                                      </div>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      item
                                      xl={10}
                                      align="center"
                                    >
                                      <div className={classes.texttablecell}>
                                        <Box flexDirection="row">
                                          <IconButton
                                            // onClick={() => {
                                            //   handleDeleteListRules(row.Rule_Type);
                                            //   setOpen(true);
                                            // }}
                                            onClick={(e) => {
                                              setConfirmDialog({
                                                isOpen: true,
                                                title:
                                                  "Do You Want To Remove Rule?",
                                                onConfirm: () => {
                                                  handleDeleteRule(row);
                                                },
                                              });
                                            }}
                                          >
                                            <DeleteIcon
                                              style={{ color: "red" }}
                                            />
                                          </IconButton>
                                        </Box>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </>
                              </>
                            ))}
                          </>
                        ) : (
                          <>
                            <StyledTableCell align="center"></StyledTableCell>
                            <StyledTableCell align="center">
                              No Data
                            </StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <>
                  <center>
                    <CircularProgress
                      color="primary"
                      style={{ marginTop: 15 }}
                    />
                  </center>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {isRulesData ? (
        <>
          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={1}
            style={{ marginTop: 30 }}
          >
            <Grid>
              <TextField
                id="outlined-multiline-static1"
                label="Source Code"
                size="small"
                rows={15}
                name="Input"
                multiline
                value={source_code}
                onChange={(e) => setSource_code(e.target.value)}
                className={classes.textField}
                variant="outlined"
                style={{ width: 900 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              spacing={1}
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid>
                <TextField
                  id="outlined-multiline-static2"
                  label="identified Data"
                  size="small"
                  style={{ width: 900 }}
                  multiline
                  rows={15}
                  value={target_Code}
                  name="Output"
                  onChange={(e) => setTarget_Code(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              spacing={1}
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Grid>
                <TextField
                  id="outlined-multiline-static2"
                  label="Un identified data"
                  size="small"
                  style={{ width: 900 }}
                  multiline
                  rows={15}
                  value={target_Code1}
                  name="Output"
                  onChange={(e) => setTarget_Code1(e.target.value)}
                  className={classes.textField}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            spacing={1}
            style={{ marginTop: 20 }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                component="span"
                size="small"
                style={{ marginLeft: 15 }}
                onClick={() => {
                  handleApplyRules(source_code);
                }}
              >
                Apply Rules
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                component="span"
                size="small"
                style={{ marginLeft: 15 }}
                onClick={() => {
                  handleSave(ruleid, source_code, target_Code);
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}

      {isRulesData ? (
        <>
          <Box py={4}>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <Autocomplete
                  style={{ width: 270, marginRight: 400 }}
                  fullWidth
                  id="grouped-demo"
                  options={[
                    { title: "Rules Source Code", code: "Rules_Source_Code" },
                    // { title: "Target Output File", code: "Rules_Target_Code" },
                    // { title: "Rules Python Code", code: "Rules_Python_Code" },
                  ]}
                  groupBy={""}
                  // defaultValue={{ title: "Source Description" }}
                  getOptionLabel={(option) => option.title}
                  name="Attachemnets"
                  onChange={(e, v) => handleChangedrop(v)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Attachments"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <div className={classes.rootc}>
                  <input
                    accept="file"
                    className={classes.input}
                    id="contained-button-file3"
                    onChange={(e) => handleSubmitdrpm(e, ruleid)}
                    multiple={false}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    type="file"
                  />

                  <label htmlFor="contained-button-file3">
                    <Button
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      color="primary"
                      size="small"
                      component="span"
                      style={{ marginTop: -78, marginLeft: 70 }}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  size="small"
                  style={{ marginTop: -119, marginLeft: 390 }}
                  //   value={isapplyata}
                  onClick={() => handlefileapply("Rules_Source_Code")}
                >
                  Apply Rules File
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}

      {isRulesData ? (
        <>
          <Grid
            container
            xl={12}
            justifyContent="space-between"
            spacing={1}
            style={{ marginBottom: 50 }}
          >
            <Grid item xs={12}>
              <Typography
                gutterBottom
                align="center"
                variant="h6"
                component="h2"
                className={classes.Object_Type}
              >
                Attachments List
              </Typography>
              <Table
                className={classestable.table}
                aria-label="customized table"
              >
                <TableHead className={classes.primary}>
                  <TableRow>
                    <StyledTableCell align="center">File Name</StyledTableCell>
                    <StyledTableCell align="center">
                      Source Code
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Target Code
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      Conversion Module
                    </StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attachementslist.map((row) => (
                    <StyledTableRow container>
                      <StyledTableCell item xl={10} align="center">
                        <div className={classes.texttablecell}>
                          {row.File_Name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell item xl={10} align="center">
                        <div className={classes.texttablecell}>
                          <Box flexDirection="row">
                            {row.Rules_Source_Code}
                            {row.Rules_Source_Code == "Y" ? (
                              <>
                                <IconButton
                                  onClick={() =>
                                    handleDeleteAttac(
                                      "Rules_Source_Code",
                                      row.File_Name
                                    )
                                  }
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </IconButton>
                                <IconButton
                                  onClick={(e) =>
                                    handleFileDownload(
                                      "Rules_Source_Code",
                                      row.File_Name
                                    )
                                  }
                                >
                                  <GetAppIcon style={{ color: "blue" }} />
                                </IconButton>{" "}
                              </>
                            ) : null}
                          </Box>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell item xl={10} align="center">
                        <div className={classes.texttablecell}>
                          <Box flexDirection="row">
                            {row.Rules_Target_Code}
                            {row.Rules_Target_Code == "Y" ? (
                              <>
                                <IconButton
                                  onClick={() =>
                                    handleDeleteAttac(
                                      "Rules_Target_Code",
                                      row.File_Name
                                    )
                                  }
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </IconButton>
                                <IconButton
                                  onClick={(e) =>
                                    handleFileDownload(
                                      "Rules_Target_Code",
                                      row.File_Name
                                    )
                                  }
                                >
                                  <GetAppIcon style={{ color: "blue" }} />
                                </IconButton>{" "}
                              </>
                            ) : null}
                          </Box>
                        </div>
                      </StyledTableCell>
                      {/* <StyledTableCell item xl={10} align="center">
                        <div className={classes.texttablecell}>
                          <Box flexDirection="row">
                            {row.Rules_Python_Code}{" "}
                            {row.Rules_Python_Code == "Y" ? (
                              <>
                                {row.Rules_Python_Code_Filename}
                                <IconButton
                                  onClick={() =>
                                    handleDeleteAttac(
                                      "Rules_Python_Code",
                                      row.Rules_Python_Code_Filename
                                    )
                                  }
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </IconButton>
                                <IconButton
                                  onClick={(e) =>
                                    handleFileDownload(
                                      "Rules_Python_Code",
                                      row.Rules_Python_Code_Filename
                                    )
                                  }
                                >
                                  <GetAppIcon style={{ color: "blue" }} />
                                </IconButton>{" "}
                              </>
                            ) : null}
                          </Box>
                        </div>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}
