import React, { useEffect, useReducer } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import AppsIcon from "@material-ui/icons/Apps";
import fileSaver from "file-saver";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import config from "../Config/config";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import GetAppIcon from "@material-ui/icons/GetApp";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Notification from "../Features/Notifications/Notification";
import Menuaction from "../Redux/actions/Menuaction";

import {
  Box,
  Grid,
  Menu,
  MenuItem,
  styled,
  TextField,
} from "@material-ui/core";
import GmailTreeView from "./Treeview";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ActionMenu from "../Redux/actions/Menuaction";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import qbook from "../Images/qbook1.jpg";

const drawerWidth = 375;

const useStyles = makeStyles((theme) => ({
  // ############################
  grow: {
    flexGrow: 1,

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {},
  },
  inputplaceholder: {
    "&::placeholder": {
      color: "white",
    },
  },
  drawer: {
    // position: "static",
    transition: "width .7s",
  },
  closed: {
    width: "0px",
  },
  opened: {
    position: "absolute",
    width: "240px",
    resize: "horizontal",
    // cursor: "se-resize",
    cursor: "col-resize",
    // overflow:"auto",
    // maxWidth: "fit-content",
    // paddingRight:"fit-content",

    // background: "blue",
    // justifyContent: "center",
  },
  drawer1: {
    transition: "marginLeft .7s",
  },
  closed1: {
    // marginLeft:80,
    // marginRight:0,
    width: "100%",
    flex: 1,
  },

  appIcon: {
    [theme.breakpoints.down("xs")]: {},
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  opened1: {
    // resize: "horizontal",
    // resize: "horizontal",
    // paddingLeft:340,
    // overflow:"auto",
    marginLeft: 300,
    paddingRight: 140,
    background: "#fafafa",

    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.up("md")]: {
      width: "82%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "84%",
    },
  },

  x: {
    pointerEvents: "none",
    position: "absolute",
    bottom: 0,
    right: "-20px",
    lineHeight: 0,
    color: "red",
    fontSize: "50px",
  },
  // ##########################

  sidebarbody: {
    background: "red",
    // maxWidth: 450,
    // color:"fff",
  },

  downloadbutton: {
    position: "fixed",
    bottom: 0,
  },
  title: {
    marginLeft: 18,
    marginTop: 5,
  },
  floatingLabelFocusStyle: {
    color: "white",
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#4472C4",
    boxShadow: "none",
    border: "1px solid #004280",
  },

  drawer: {
    flexShrink: 0,
    background: "#4472C4",
  },

  // style={{  }}
  navbarcom: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: "110px",
      // height:'100vh'
    },
  },

  drawerPaper: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 150,
      width: drawerWidth,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 95,
      width: drawerWidth,
      position: "relative",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 20,
      width: 40,
      background: "#4472C4",
    },
    [theme.breakpoints.up("lg")]: {
      width: 240,
      marginTop: 10,
      background: "#4472C4",
      // height:'100vh'
    },
  },
  drawerContainer: {
    overflow: "auto",
    // overflowX: "visible",
    // position: "fixed",
    // width:400,
    height: "83vh",
    // background: "red",

    [theme.breakpoints.down("xs")]: {
      marginTop: "380px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "130px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "0px",
    },
  },
  content: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
      padding: 40,
      width: drawerWidth,
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      padding: 40,
      width: drawerWidth,

      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {
      flexGrow: 1,
      marginLeft: 60,
      padding: theme.spacing(1),
      width: "78%",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "yellow !important",
  },

  logoutbtn: {
    // marginLeft:"8px",

    // marginRight: "-1px",
    [theme.breakpoints.down("xs")]: {
      right: 100,
      position: "fixed",

      // padding: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      right: 30,
      position: "fixed",
    },
    [theme.breakpoints.up("md")]: {
      right: 30,
      position: "fixed",
    },
    [theme.breakpoints.up("lg")]: {
      right: 30,
      position: "fixed",
    },
  },

  inputRoottype: {
    color: "white",
    // width: '40px',
    marginTop: 5,
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },

  inputRootversion: {
    color: "white",
    // width: '40px',
    marginTop: 5,
    marginLeft: 10,
    // border:'none',
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "white",
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      // height: '1rem'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& ::placeholder": {
      color: "#FFFFFF",
    },
  },
});

const StyledAutocompletesidebar = styled(Autocomplete)({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    transform: "translate(34px, 13px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    color: "white",
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      height: "0.3rem",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
});

export default function ClippedDrawer({ children }) {
  let isAuth = sessionStorage.getItem("isAuth");
  const history = useHistory();
  if (!isAuth) {
    history.push("/");
  }

  const classes = useStyles();
  const IsSuperAdmin = sessionStorage.getItem("isSuperAdmin");
  const isUserAdmin = sessionStorage.getItem("isUserAdmin");
  // const isMigAdmin = sessionStorage.getItem("isMigAdmin");
  const FileDownload = require("js-file-download");
  const [isOpened, setIsOpened] = useState(true);
  const {
    updatedValue,
    headerValue,
    ITEMlIST,
    DropDownValues_useradmin,
    admin,
    lable,
    project_version,
    project_header_dropdown,
    ParentDropDownValues,
    prj_version_title,
    isMigAdmin,
  } = useSelector((state) => state.dashboardReducer);
  console.log(project_header_dropdown, " 1st");

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openview = Boolean(anchorEl);
  const [menuList, setmenuList] = React.useState([]);
  const [dropdown, setdropdown] = React.useState({});
  const [dropDownList, setDropdownList] = useState([]);
  const [parentdropDownList, setParentdropDownList] = useState([]);
  const [create_flag, setcreate_flag] = useState([]);
  const [create_check_flag, setcreate_check_flag] = useState(0);
  const [showMigAdmin, setShowMigAdmin] = useState();
  const [loadMIGS, setLoadMIGS] = useState(false);

  const [admin_flag, setAdmin_flag] = useState(false);
  const [anchorEls, setAnchorEls] = useState(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const dispatch = useDispatch();

  const [select_pr_v, setSelect_pr_v] = useState();

  React.useEffect(() => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };
    let body = {
      User_Email: sessionStorage.getItem("uemail"),
    };
    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });
    axios
      .post(
        `${config.API_BASE_URL()}/api/migration_names_list_user_admin/`,
        form,
        conf
      )
      .then(
        (res) => {
          setDropdownList(res.data);
          dispatch(Menuaction.getdropdownlist(res.data));
          dispatch(Menuaction.getdropdownlist_useradmin(res.data));
          if (res.data.length > 0) {
            dispatch(Menuaction.getdropdownset(res.data));
            dispatch(
              Menuaction.is_mig_admin_select(res.data[0].is_Migration_Admin)
            );
            // setShowMigAdmin(res.data[0].is_Migration_Admin)
            // getmenus(res.data[0].Migration_Name);
            dispatch(Menuaction.mig_id_select(res.data[0].Migration_Id));
          }
        },
        (error) => {
          setNotify({
            isOpen: true,
            message: "Something Went Wrong Please try Again",
            type: "error",
          });
        }
      );
  }, []);

  React.useEffect(() => {
    if (headerValue?.Migration_Name) {
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      let body = {
        Migration_Name: headerValue?.Migration_Name,
      };
      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      axios
        .post(`${config.API_BASE_URL()}/api/project_versions_list/`, form, conf)
        .then(
          (res) => {
            setSelect_pr_v(res.data.slice(-1)[0]?.Title);

            dispatch(Menuaction.getproj_header_dropdownlist(res.data));
            dispatch(
              Menuaction.prj_version_title(res.data.slice(-1)[0]?.Title)
            );
            dispatch(Menuaction.project_version(res.data.slice(-1)[0]?.Code));
            // dispatch(Menuaction.reloadAction_prj(true));
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [headerValue?.Migration_Name]);

  React.useEffect(() => {
    if (headerValue?.Migration_Name && project_version) {
      // console.log(headerValue, " head");
      let conf = {
        headers: {
          Authorization: "Bearer " + config.ACCESS_TOKEN(),
        },
      };
      let body = {
        Migration_Name: headerValue?.Migration_Name,
        Project_Version_Id: project_version,
      };
      const form = new FormData();
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });
      axios
        .post(`${config.API_BASE_URL()}/api/parent_object_list/`, form, conf)
        .then(
          (res) => {
            setParentdropDownList(res.data);
            dispatch(Menuaction.getparentdropdownlist(res.data));
          },
          (error) => {
            setNotify({
              isOpen: true,
              message: "Something Went Wrong Please try Again",
              type: "error",
            });
          }
        );
    }
  }, [headerValue?.Migration_Name && project_version]);

  const getmenus = async (value) => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    let body = {
      Migration_Name: value,
      Project_Version_Id: project_version,
      User_Email: sessionStorage.getItem("uemail"),
    };

    const form = new FormData();
    Object.keys(body).forEach((key) => {
      form.append(key, body[key]);
    });

    axios
      .post(`${config.API_BASE_URL()}/api/menu_view_creation/`, form, conf)
      .then(
        (res) => {
          if (res.data.length > 0) {
            // const res = await axios.get(`${config.API_BASE_URL()}/api/miglevelobjects/${value}`, conf);
            setmenuList(res.data);
            // setAdmin_flag(res.data[0]?.Admin_Flag);
            dispatch(Menuaction.lableselect(res.data[0].Object_Type));
            dispatch(ActionMenu.selectedMenutlist());
            dispatch(Menuaction.reloadAction(false));
            dispatch(Menuaction.admin(res.data[0]?.Admin_Flag));
          } else if (res.data.length === 0) {
            setmenuList([]);
            dispatch(ActionMenu.selectedMenutlist());
            dispatch(Menuaction.reloadAction(false));
            // dispatch(Menuaction.admin(0))
          }
        },
        (error) => {
          setmenuList([]);
          dispatch(ActionMenu.selectedMenutlist());
          dispatch(Menuaction.reloadAction(false));
          // dispatch(Menuaction.admin(0))
        }
      );
  };

  React.useEffect(() => {
    if (headerValue?.Migration_Name && project_version) {
      if (Object.keys(headerValue).length > 0) {
        getmenus(headerValue?.Migration_Name);
      }
    }
  }, [headerValue?.Migration_Name && project_version]);

  React.useEffect(() => {
    if (updatedValue) {
      getmenus(headerValue?.Migration_Name);
      // dispatch(Menuaction.reloadAction(true));
    }
  }, [updatedValue]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleroute = () => {
    setAnchorEl(null);
    sessionStorage.clear();
    history.push("/");
  };

  const onDownload1 = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    axios
      .get(`${config.API_BASE_URL()}/api/templatedownload/`, conf, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileSaver.saveAs(blob, "template.py");
      })
      .catch((err) => {});
  };
  const onDownload2 = () => {
    let conf = {
      headers: {
        Authorization: "Bearer " + config.ACCESS_TOKEN(),
      },
    };

    // axios
    //   .get(`${config.API_BASE_URL()}/api/pdfdownload/`, conf, {
    //     responseType: "arraybuffer",
    //   })
    //   .then((res) => {
    //     var blob = new Blob([res.data], {
    //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     });
    //     fileSaver.saveAs(blob, "instructions.pdf");
    //   })
    //   .catch((err) => {});

    axios({
      url: `${config.API_BASE_URL()}/api/userdoc/`,
      headers: conf.headers,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      FileDownload(response.data, "Qbook_User_Guide.pdf");
    });
  };
  const handleAdminMenus = () => {
    history.push("/AdminAccesslist");
  };

  const handlePrompt = () => {
    history.push("/prompt");
  };

  const handleAcessreview = () => {
    history.push("/accessreview");
  };
  const handleSuperadmin = () => {
    history.push("/superadmin");
  };

  const handleUseradmin = () => {
    history.push("/useradmin");
  };
  const handleMigrationAdmin = () => {
    history.push("/migadmin");
  };

  const handlerConversionprocess = () => {
    history.push("/conversion");
  };
  const handleMenuApp = (event) => {
    // setState({ anchorEl: event.currentTarget });
    setAnchorEls(event.currentTarget);
  };

  const handlefeatureapprovals = () => {
    history.push("/FeatureApprovals");
  };
  const handleRulesCreation = () => {
    history.push("/createrules");
  };

  const handleversion = (v) => {
    getmenus(v?.Migration_Name);

    setdropdown(v);
    dispatch(ActionMenu.dropdown(v));
    dispatch(ActionMenu.mig_id_select(v?.Migration_Id));
    dispatch(Menuaction.lableselect(v?.Object_Type));
    // dispatch(Menuaction.reloadAction(true))
    // setShowMigAdmin(v?.is_Migration_Admin)
    setLoadMIGS(true);
    dispatch(Menuaction.is_mig_admin_select(v?.is_Migration_Admin));
    setLoadMIGS(false);
    // console.log("refresh");
    history.push("/dashboard");
  };
  const handlefeature = (v) => {
    // console.log(v, ' =======')
    dispatch(ActionMenu.selectedMenutlist(v));
    dispatch(Menuaction.lableselect(v?.Object_Type));
    dispatch(Menuaction.admin(v?.Admin_Flag));
    history.push("/dashboard");
  };

  const handlerequestMenus = () => {
    history.push("/Request");
  };

  const handleCloseApp = () => {
    setAnchorEls(null);
  };
  const handleProject_Version = (v) => {
    setSelect_pr_v(v?.Title);
    dispatch(Menuaction.prj_version_title(v?.Title));
    dispatch(Menuaction.project_version(v?.Code));

    history.push("/dashboard");
  };
  // console.log(ITEMlIST,'menu')
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" container className={classes.appBar}>
        <Toolbar container>
          <MenuIcon
            onClick={() => setIsOpened(!isOpened)}
            style={{ color: "black" }}
          />

          <div className={classes.grow} style={{ marginLeft: 20 }}>
            <img src={qbook} style={{ width: 135, height: 40 }} />
          </div>

          {DropDownValues_useradmin.length > 0 && (
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.grow}
              options={DropDownValues_useradmin}
              groupBy={""}
              defaultValue={{
                Migration_Name: DropDownValues_useradmin[0]?.Migration_Name,
              }}
              getOptionLabel={(option) => option?.Migration_Name}
              style={{ width: 200 }}
              onChange={(e, v) => handleversion(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="MigrationTypes"
                  variant="outlined"
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                />
              )}
            />
          )}

          {isUserAdmin === "true" ? (
            <>
              <Link
                type="button"
                size="small"
                className={classes.grow}
                onClick={handleUseradmin}
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  textDecoration: "none",
                  marginLeft: 10,
                }}
              >
                User Admin
              </Link>
            </>
          ) : (
            <>
              <Link
                type="button"
                size="small"
                className={classes.grow}
                // onClick={handleUseradmin}
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  textDecoration: "none",
                  marginLeft: 10,
                }}
              ></Link>
            </>
          )}

          {isMigAdmin === true ? (
            <>
              <Link
                type="button"
                size="small"
                className={classes.grow}
                onClick={handleMigrationAdmin}
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  textDecoration: "none",
                }}
              >
                Migration Admin
              </Link>
            </>
          ) : (
            <>
              <Link
                type="button"
                size="small"
                className={classes.grow}
                // onClick={handleMigrationAdmin}
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  textDecoration: "none",
                }}
              ></Link>
            </>
          )}

          {IsSuperAdmin == "true" ? (
            <>
              <Link
                type="button"
                size="small"
                onClick={handleSuperadmin}
                className={classes.grow}
                style={{
                  color: "white",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  textDecoration: "none",
                }}
              >
                Super Admin
              </Link>
            </>
          ) : (
            <Link
              type="button"
              size="small"
              // onClick={handleSuperadmin}
              className={classes.grow}
              style={{
                color: "white",
                textTransform: "capitalize",
                fontSize: "16px",
                textDecoration: "none",
              }}
            ></Link>
          )}
          {project_header_dropdown.length > 0 && (
            <StyledAutocomplete
              size="small"
              id="grouped-demo"
              className={classes.grow}
              options={project_header_dropdown}
              groupBy={""}
              value={prj_version_title}
              // defaultValue={{
              //   Title: project_header_dropdown.slice(-1)[0]?.Title,
              // }}
              getOptionLabel={(option) => option.Title}
              style={{ width: 30 }}
              onChange={(e, v) => handleProject_Version(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Versions"
                  variant="outlined"
                  InputLabelProps={{
                    className: [
                      classes.floatingLabelFocusStyle,
                      classes.inputplaceholder,
                    ],
                    shrink: true,
                  }}
                  placeholder={String(select_pr_v)}
                />
              )}
            />
          )}
          {/* </Grid> */}

          {auth && (
            <>
              <div>
                <IconButton
                  aria-owns={Boolean(anchorEls) ? "menu-appbars" : undefined}
                  aria-haspopup="true"
                  className={classes.appIcon}
                  onClick={(e) => handleMenuApp(e)}
                  color="inherit"
                >
                  <AppsIcon />
                </IconButton>

                <Menu
                  id="menu-appbars"
                  anchorEl={anchorEls}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEls)}
                  onClose={handleCloseApp}
                >
                  <MenuItem onClick={handleUseradmin}> User Admin</MenuItem>
                  <MenuItem onClick={handleMigrationAdmin}>
                    {" "}
                    Migration Admin
                  </MenuItem>
                  <MenuItem onClick={handleSuperadmin}> Super Admin</MenuItem>
                  {/* <MenuItem onClick={handleCloseApp}> Version</MenuItem> */}
                </Menu>
              </div>

              <div style={{ justifyContent: "flex-end" }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <div>
                    {/* {sessionStorage.getItem("quser")} */}
                    <AccountCircleIcon />
                  </div>
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openview}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleroute}>Logout</MenuItem>
                </Menu>
              </div>
            </>
          )}
          {/* </Grid> */}
        </Toolbar>
      </AppBar>

      {/* Side bar */}

      <Grid container className={classes.sidebarbody}>
        <Grid item>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(classes.drawer, {
                [classes.closed]: !isOpened,
                [classes.opened]: isOpened,
              }),
            }}
          >
            <Toolbar />

            <div className={classes.drawerContainer}>
              <List>
                <ListItem
                  button
                  onClick={handlerequestMenus}
                  style={{ color: "white", height: "40px" }}
                >
                  <ListItemIcon>
                    <MenuBookIcon
                      style={{ color: "white", fontSize: "22px" }}
                    />
                  </ListItemIcon>

                  <ListItemText>
                    <span style={{ fontSize: "0.90rem" }}>
                      {"Feature Catalog"}
                    </span>
                  </ListItemText>
                </ListItem>
                {admin ? (
                  <>
                    {headerValue.Migration_Name === "Oracle_Postgres14" ? (
                      <ListItem
                        button
                        onClick={handlePrompt}
                        style={{ color: "white", height: "40px" }}
                      >
                        <ListItemIcon>
                          <MenuBookIcon
                            style={{ color: "white", fontSize: "22px" }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <span style={{ fontSize: "0.90rem" }}>
                            {"Oraconvert Prompt"}
                          </span>
                        </ListItemText>
                      </ListItem>
                    ) : null}

                    <ListItem
                      button
                      onClick={handleAdminMenus}
                      style={{ color: "white", height: "40px" }}
                    >
                      <ListItemIcon>
                        <MenuBookIcon
                          style={{ color: "white", fontSize: "22px" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <span style={{ fontSize: "0.90rem" }}>
                          {"Admin Approvals"}
                        </span>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => handlefeatureapprovals()}
                      style={{ color: "white", height: "40px" }}
                    >
                      <ListItemIcon>
                        <MenuBookIcon
                          style={{ color: "white", fontSize: "22px" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <span style={{ fontSize: "0.90rem" }}>
                          {"Feature Approvals"}
                        </span>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      style={{ color: "white", height: "40px" }}
                      button
                      onClick={(e) => {
                        history.push("/conversion");
                      }}
                    >
                      <ListItemIcon>
                        <MenuBookIcon
                          style={{ color: "white", fontSize: "22px" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <span style={{ fontSize: "0.90rem" }}>
                          {"Conversion Process"}
                        </span>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      onClick={handleAcessreview}
                      style={{ color: "white", height: "40px" }}
                    >
                      <ListItemIcon>
                        <MenuBookIcon
                          style={{ color: "white", fontSize: "22px" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <span style={{ fontSize: "0.90rem" }}>
                          {"Access Review"}
                        </span>
                      </ListItemText>
                    </ListItem>
                  </>
                ) : (
                  <></>
                )}
              </List>

              <Box py={1}>
                <Grid container direction="column" spacing={0}>
                  <Grid item>
                    {menuList.length > 0 && (
                      <StyledAutocompletesidebar
                        size="medium"
                        id="grouped-demo"
                        className={classes.inputRoottype}
                        options={menuList}
                        groupBy={""}
                        defaultValue={{ Object_Type: menuList[0].Object_Type }}
                        getOptionLabel={(option) => option.Object_Type}
                        style={{ width: 230, height: 50 }}
                        onChange={(e, v) => handlefeature(v)}
                        blurOnSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Migration Objects"
                            variant="outlined"
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                              shrink: true,
                            }}
                          />
                        )}
                      />
                    )}
                  </Grid>

                  <Grid item spacing={1}>
                    <GmailTreeView
                      menuList={ITEMlIST}
                      dropdown={dropdown}
                      admin={admin}
                      createflag={create_check_flag}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box py={1}>
                <Button
                  style={{
                    color: "white",
                    marginLeft: "10px",
                    textTransform: "unset",
                  }}
                  startIcon={<GetAppIcon />}
                  onClick={onDownload1}
                  className={classes.downloadbutton}
                >
                  Template
                </Button>
                <Button
                  style={{
                    color: "white",
                    marginLeft: "110px",
                    textTransform: "unset",
                  }}
                  startIcon={<GetAppIcon />}
                  onClick={onDownload2}
                  className={classes.downloadbutton}
                >
                  User Guide
                </Button>
              </Box>
            </div>
          </Drawer>
        </Grid>

        <Grid item xs={12}>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx(classes.drawer1, {
                [classes.closed1]: !isOpened,
                [classes.opened1]: isOpened,
              }),
            }}
          >
            <Toolbar />
            {children}
          </Drawer>
        </Grid>
      </Grid>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
